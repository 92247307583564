<template>
  <iColumn width="hug">
    <iHeading>
      Create New Topic
    </iHeading>
    <iTextInput
      v-model="topic"
      name="topic"
      label="Topic "
    />
    <iTextInput
      v-model="description"
      name="description"
      label="Topic Description "
    />
    <iButton :disabled="!hasValidTopic" @click="submitNewTopic">
      Save Topic
    </iButton>
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCommunityStore } from "@/stores/community-store";
import { useGeneralStore } from "@/stores/general-store";
import {
  SECTION_COMMUNITY,
  PAGE_CREATE_POST,
} from "@/constants/nav-constants";

export default {
  name: "CreateTopic",
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_CREATE_POST,
      topic: "",
      message: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["getOrgId", "getUserId"]),
    hasValidTopic() {
      return this.topic.length > 0 && this.description.length > 0;
    },
  },
  methods: {
    ...mapActions(useCommunityStore, {
      createTopic: "createTopic",
    }),
    submitNewTopic() {
      const topicData = {
        context: "discussion",
        description: this.description,
        topic: this.topic,
      };
      this.createTopic(topicData);
    },
  },
};
</script>

  <style scoped>
  </style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_DEPLOYMENT_HISTORY"
    title="Deployment History"
    :breadcrumbs="breadcrumbs"
    page-name="History"
    align="left"
    width="full"
  >
    <iColumn :width="700" gap="large">
      <PublicationVersionCard
        v-for="(version, index) in deployments"
        :key="index"
        :version="version.version"
        :memo="version.memo"
        :date-created="version.date_created"
        :publication-version-id="version.publication_version_id"
        :staging="false"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";

import PageWrapper from "@/components/PageWrapper";
import PublicationVersionCard from "@/components/PublicationVersionCard.vue";
import { usePublicationStore } from "@/stores/publication-store";

import {
  PAGE_PUBLICATION_DEPLOYMENT_HISTORY,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationDeploymentHistory",
  components: {
    PageWrapper,
    PublicationVersionCard,
  },
  props: {
    deployments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_DEPLOYMENT_HISTORY,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publicationId", "orgId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/deployment`, label: "Deployment" },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :title="publication.name"
    :breadcrumbs="breadcrumbs"
    :page-name="publication.name"
    sub-title=""
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <ApplyToCollaborate :org-id="publication.org_id" :publication-id="publication.publication_id" />
      </iRow>
      <iColumn gap="small">
        <iText variant="standard" font-size="extraLarge">
          Domain: {{ publication.domain }}
        </iText>
        <iText variant="standard" font-size="small">
          Status: {{ mappedPublicationStatus }}
        </iText>
        <iText variant="standard" font-size="small">
          URL Slug: {{ publication.url_slug }}
        </iText>
      </iColumn>
      <iColumn gap="standard">
        <iSubHeading variant="subtle">
          Collaborators
        </iSubHeading>
        <iRow wrap="wrap" gap="large">
          <PublicationCollaboratorCard
            v-for="collaborator in collaborators"
            :key="collaborator.applicant_id"
            :collaborator="formatCollaborator(collaborator)"
          />
        </iRow>
      </iColumn>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";

export default {
  name: "PublicationProfile",
  components: {
    PublicationCollaboratorCard,
    ApplyToCollaborate,
    PageWrapper,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      PAGE_COMMUNITY_COMMUNITY,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(usePublicationStore, [
      "publication",
    ]),
    ...mapState(useCollaborationStore, [
      "collaborators",
    ]),
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Under Construction";
        default:
          return "Offline";
      }
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "openApplyToCollaborateModel",
    ]),
    formatCollaborator(collaborator) {
      return {
        id: collaborator.applicant_id,
        name: collaborator.name,
        role: collaborator.role || "",
        status: collaborator.status,
        email: collaborator.email,
        avatar: collaborator.avatar || "",
        orgId: collaborator.org_id,
      };
    },
  },
};
</script>

<style scoped lang="scss">
</style>

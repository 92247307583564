<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_FEATURE_SHOP">
    <iHeading>
      Publication Shop
    </iHeading>
    <iToggleSwitch v-model="config.enabled" label="Activate Shop" />
    <iButton @click="savePublicationConfig">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_SHOP, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationShop",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_SHOP,
      config: {
        enabled: false,
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["configuration"]),
  },
  created() {
    this.config = { ...this.config, ...this.configuration.shop };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("shop", this.config);
    },
  },
};
</script>

<style scoped>

</style>

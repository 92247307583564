export const injectUrlVars = (item, urlKeys, query, suffixNameWithKey = false) => {
  let updatedHref = item.actionValue;
  let updatedLabel = item.label;
  let updatedName = item.name;
  let keys = Object.keys(urlKeys);
  keys.forEach(key => {
    if (updatedLabel.includes(key)) {
      updatedLabel = updatedLabel.replace(key, urlKeys[key]);
    }
    if (updatedName.includes(key)) {
      updatedName = updatedName.replace(key, urlKeys[key]);
    }
    if (updatedHref.includes(key)) {
      updatedHref = updatedHref.replace(key, urlKeys[key]);
      if (suffixNameWithKey) {
        updatedName = `${updatedName}_${urlKeys[key]}`;
      }
    }
  });
  if (item.subItems?.length > 0) {
    item.subItems = item.subItems.map(subItem => injectUrlVars(subItem, urlKeys, query, suffixNameWithKey));
  }
  let url = new URL(updatedHref, location.origin);
  let newQueryParams = new URLSearchParams(query);
  for (const param of newQueryParams.entries() ) {
    url.searchParams.set(param[0], param[1]);
  }
  return { ...item, name: updatedName, actionValue: url.toString(), label: updatedLabel };
};

export const parseUrlVars = (items, urlKeys, query, suffixNameWithKey = false) => {
  if (!items) {
    throw new Error("[items] not defined in scope");
  }
  if (!Array.isArray(items)) {
    throw new Error("[items] must be an array and cannot be empty");
  }
  if (!urlKeys) {
    return items;
  }
  return items.map(item => injectUrlVars(item, urlKeys, query, suffixNameWithKey));
};

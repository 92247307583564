<template>
  <PageWrapper :active-section="SECTION_SETTINGS" :active-page="PAGE_INFLUENCER_PLATFORM_SETTINGS">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_SETTINGS, PAGE_INFLUENCER_PLATFORM_SETTINGS } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "InfluencerPlatforms",
  components: {
    ComingSoon,
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_INFLUENCER_PLATFORM_SETTINGS,
    };
  },
};
</script>

import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";

export const useSocialNetworksStore = defineStore("social-networks-store", {
  state: () => ({
    socialNetworks: [],
  }),
  getters: {},
  actions: {
    setSocialNetworks(socialNetworks) {
      this.socialNetworks = socialNetworks;
    },
    connect(socialNetworkName, orgId) {
      $axios.post("/social-network/connect", {
        social_network_name: socialNetworkName,
        redirect_uri: `${window.blclient.data.links.web}/social-network/connect?org_id=${orgId}&social_network=${socialNetworkName}`,
      }).then(response => {
        window.open(`${response.data.uri}`, "_self");
      });
    },
    manualConnect(socialNetworkName, orgId, accountHandle) {
      $axios.post("/social-network/manual-connect", {
        org_id: orgId,
        social_network_name: socialNetworkName,
        social_network_account_handle: accountHandle,
      }).then(response => {
        this.socialNetworks = response.data.social_networks;
      });
    },
    disconnect(socialNetworkId, socialNetwork, orgId) {
      $axios.post("/social-network/disconnect", {
        social_network_account_id: parseInt(socialNetworkId),
        social_network_name: socialNetwork,
        org_id: orgId,
      }).then(response => {
        this.socialNetworks = response.data.social_networks;
      });
    },
    manualDisconnect(socialNetworkId, socialNetwork, orgId) {
      $axios.post("/social-network/manual-disconnect", {
        social_network_account_id: parseInt(socialNetworkId),
        social_network_name: socialNetwork,
        org_id: orgId,
      }).then(response => {
        this.socialNetworks = response.data.social_networks;
      });
    },
    postMessage(socialNetworkId, socialNetwork, message, file) {
      const formData = new FormData();

      formData.append("social_network_account_id", parseInt(socialNetworkId));
      formData.append("social_network_name", socialNetwork);
      formData.append("message", message);
      formData.append("file", file);

      $axios.post("/social-network/post",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    },
  },
});

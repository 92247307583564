<template>
  <iframe :src="iframeSrc" :style="{width, height}" frameborder="0" />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useTipaltiStore } from "@/stores/tipalti-store";

export default {
  name: "Tipalti",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "1200px",
    },
  },
  computed: {
    ...mapState(useTipaltiStore, ["iframeSrc"]),
  },
  created() {
    this.generateIframeSrc();
  },
  methods: {
    ...mapActions(useTipaltiStore, ["generateIframeSrc"]),
  },
};
</script>

<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <iColumn :width="600" overflow="visible">
      <input
        id="title"
        v-model="title"
        class="native-post-title"
        type="text"
        name="title"
        placeholder="Title"
        :readonly="postViewType === 'view'"
      >

      <iColumn class="native-post-content-container">
        <input
          v-if="postViewType === 'edit'"
          id="file"
          ref="file"
          type="file"
          hidden
          @change="uploadFunction"
        >

        <selection-bubble
          v-if="editor && postViewType === 'edit'"
          :editor="editor"
          :should-show="shouldShow"
          :upload-post-image-loading="uploadPostImageLoading"
          @select-file="selectFile"
          @toggle-bold="toggleBold"
          @toggle-italic="toggleItalic"
          @toggle-link="toggleLink"
        />
        <editor-content class="editor-container" :editor="editor" />
      </iColumn>
      <iRow>
        <iTitle>
          Hashtags
        </iTitle>
        <template v-for="hashtag in hashtags" :key="hashtag.hashtag_id">
          <iChip :show-icon="postViewType === 'edit'" :is-loading="loadingRemoveHashtagId == hashtag.hashtag_id" @click:icon="removeHashtag(hashtag.hashtag_id)">
            {{ hashtag.hashtag }}
          </iChip>
        </template>
      </iRow>
      <iRow v-if="postViewType === 'edit'">
        <!-- <iTextEdit
          value=""
          label="Hashtags"
          :width="400"
          clear-value-on-submit
          clear-value-on-cancel
          :is-loading="loadingAddHashtagToPost"
          @submit="addHashtag"
          @enter="addHashtag"
          @input="checkHasDuplicateHashTags"
        /> -->
        <iColumn width="hug">
          <iTextInput
            v-model="hashtagString"
            placeholder="#clothing"
            @enter="addHashtag"
            @change="validateHashtag"
          />
        </iColumn>
        <iColumn v-if="hashtagString" width="hug">
          <iButton
            :disabled="!!errorValidateHashtag"
            variant="secondary"
            :is-loading="loadingAddHashtagToPost"
            @click="addHashtag"
          >
            Add
          </iButton>
          <iText v-if="errorValidateHashtag" variant="error">
            {{ errorValidateHashtag }}
          </iText>
        </iColumn>
        <iText v-if="errorAddHashtagToPost" variant="error">
          {{ errorAddHashtagToPost }}
        </iText>
      </iRow>
      <iColumn v-if="postViewType === 'edit'">
        <iSubHeading>
          Configuration
        </iSubHeading>
        <iRow vertical-align="middle">
          <iCheckbox
            v-model="exclusive"
            :is-loading="updatePostExclusivityIsLoading"
            :disabled="!canMarkContentAsExclusive"
            label="Mark content as exclusive"
            @change="updatePostExclusivityFlag"
          />
          <iColumn v-tooltip="exclusiveCheckboxTooltip" width="hug">
            <iIcon icon="info-circle" />
          </iColumn>
        </iRow>
      </iColumn>
      <iSpace :height="20" />
      <iRow v-if="postViewType === 'edit'">
        <iColumn width="hug">
          <iButton variant="secondary" @click="cancelPost">
            Cancel
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            variant="primary"
            :disabled="updatePostIsLoading"
            :is-loading="updatePostIsLoading"
            @click="submitPost"
          >
            Update
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { Editor, isTextSelection, EditorContent } from "@tiptap/vue-3";
import { MoveNodeUp } from "@/extensions/post-editor/move-up";
import { MoveNodeDown } from "@/extensions/post-editor/move-down";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import PageWrapper from "@/components/PageWrapper.vue";
import SelectionBubble from "@/components/post-editor/SelectionBubble.vue";
import CustomImage from "@/extensions/post-editor/custom-image-extension";

export default {
  name: "ViewPublicationPost",
  components: {
    PageWrapper,
    EditorContent,
    SelectionBubble,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      title: "",
      errorValidateHashtag: "",
      hashtagString: "",
      loadingRemoveHashtagId: 0,
      // tiptap
      editor: null,
      model: "",
      exclusive: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "post",
      "publicationId",
      "postViewType",
      "updatePostIsLoading",
      "uploadPostImageLoading",
      "loadingAddHashtagToPost",
      "errorAddHashtagToPost",
      "canMarkContentAsExclusive",
      "updatePostExclusivityIsLoading",
    ]),
    exclusiveCheckboxTooltip() {
      if (this.canMarkContentAsExclusive) {
        return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
      }
      return "Subscriptions are disabled for this publication";
    },
    hashtags() {
      return this.post.hashtags;
    },
  },
  mounted() {
    this.title = this.post.post_title;
    this.exclusive = !!this.post.exclusive;
    this.editor = new Editor({
      content: this.post.html,
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: "Write something amazing..." }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: "https",
        }),
        CustomImage.configure({ inline: true, isolating: true }),
        MoveNodeUp,
        MoveNodeDown,
      ],
    });

    if (this.postViewType === "view") {
      this.editor.setEditable(false);
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    ...mapActions(usePublicationStore, [
      "uploadPostImage",
      "updatePublicationPost",
      "addHashtagsToPost",
      "removeHashtagFromPost",
      "updatePostExclusiveContentFlag",
    ]),
    validateHashtag() {
      const HASHTAG_PATTERN = /^(#)?[a-zA-Z0-9_]+$/; // allow whitespace to split?
      let valid = HASHTAG_PATTERN.test(this.hashtagString);
      if (valid) {
        this.checkHasDuplicateHashTags(this.hashtagString);
      } else {
        this.errorValidateHashtag = "Hashtags may only contain alphanumeric characters, underscores and may begin with #";
      }
    },
    // bubble menu
    shouldShow() {
      return ({ view, state, from, to }) => {
        // only show the bubble menu for images and links
        const { doc, selection } = state;
        const { empty } = selection;

        // Sometime check for `empty` is not enough.
        // Doubleclick an empty paragraph returns a node size of 2.
        // So we check also for an empty text size.
        const isEmptyTextBlock =
            !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        return true;
      };
    },
    addHashtag() {
      let hashtags = this.hashtagString.trim().replaceAll(/([^\w\s])+/g, "").split(" ");
      let hasDiff = false;
      const newTags = [];
      hashtags.forEach(hashtag => {
        if (!this.hashtags.find(h => h.hashtag === hashtag)) {
          hasDiff = true;
          newTags.push(hashtag);
        }
      });
      if (hasDiff) {
        this.addHashtagsToPost(newTags).finally(() => {
          this.hashtagString = "";
        });
      }
    },
    checkHasDuplicateHashTags(value) {
      const hashtags = value?.trim().replaceAll(/([^\w\s])+/g, "").split(" ");
      let hasDuplicate = false;
      hashtags.forEach(hashtag => {
        if (this.hashtags.find(h => h.hashtag === hashtag)) {
          hasDuplicate = true;
        }
      });
      this.errorValidateHashtag = hasDuplicate ? "Some hashtags already exists on the post. Only unique hashtags will be added." : "";
    },
    removeHashtag(hashtagId) {
      if (this.postViewType === "edit") {
        this.loadingRemoveHashtagId = hashtagId;
        this.removeHashtagFromPost(hashtagId).finally(() => {
          this.loadingRemoveHashtagId = 0;
        });
      }
    },
    // text commands
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      let url;
      if (!previousUrl) {
        url = window.prompt("URL", previousUrl);
      }

      // cancelled
      if (url === null) {
        return;
      }

      this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .toggleLink({ href: url, target: "_blank" })
          .run();
    },

    // image upload
    selectFile() {
      document.getElementById("file").click();
    },
    async uploadFunction() {
      this.selectedFile = this.$refs.file.files[0];

      const imageUrl = await this.uploadPostImage(this.selectedFile);

      // insert the image
      this.editor.chain().focus().setImage({ src: imageUrl }).run();

      // reset the file input
      this.$refs.file.value = "";
    },

    cancelPost() {
      window.location.href = `/publication/${this.publicationId}/posts`;
    },
    submitPost() {
      this.updatePublicationPost(this.post.post_id, this.title, this.editor.getHTML());
    },
    updatePostExclusivityFlag() {
      this.updatePostExclusiveContentFlag(this.post.post_id, this.exclusive);
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>

import { Extension } from "@tiptap/core";

export const MoveNodeUp = Extension.create({
  name: "moveNodeUp",

  addCommands() {
    return {
      moveNodeUp: (editor, node, nodePos) => ({ tr, dispatch }) => {
        const $pos = tr.doc.resolve(nodePos);

        // If we're at the start of the document, we can't move up
        if (nodePos === 0) {
          return false;
        }

        let targetNode = node;
        let targetPos = nodePos;
        let targetDepth = $pos.depth;

        // If the node is nested, we need to move its parent
        if (targetDepth > 0) {
          targetNode = $pos.node(targetDepth);
          targetPos = $pos.before(targetDepth);
        }

        // Find the position of the previous sibling or parent
        let beforePos = targetPos - 1;
        let end = targetPos + targetNode.nodeSize;

        const { node: beforeNode, offset } = tr.doc.childBefore(beforePos);

        // No previous node found
        if (!beforeNode) {
          return false;
        }

        // Set the position to the start of the previous node
        beforePos = offset;

        // ProseMirror transaction to move the node to the new position
        if (dispatch) {
          editor
              .chain()
              .command(({ tr }) => {
                // Remove the node from its original position
                tr.delete(targetPos, end);
                return tr;
              })
              .insertContentAt(beforePos, targetNode.toJSON())
              .focus()
              .run();

          dispatch(tr);
        }

        return true;
      },
    };
  },
});

<template>
  <PageWrapper
    :active-page="$options.name"
    active-section=""
    title="Waiting List Survey"
    sub-title="Help us get to know you better"
    :breadcrumbs="[{url: '/splash', label: 'Home'}]"
    page-name="Waiting List Survey"
    align="center"
    width="fixed"
  >
    <iColumn>
      <iRow>
        <iParagraph>
          Thanks for filling out the survey, this will help us get to know you and your business so that we can
          incorporate you into our launch strategy.
        </iParagraph>
        <iParagraph>
          Our launch partners receive incredible perks discounts and bonuses,
          so be as thoughtful and descriptive as possible with your responses to maximize your chance of being selected.
        </iParagraph>
      </iRow>

      <iRow>
        <iForm :questions="surveyQuestions" number-questions>
          <iButton :is-loading="isLoading" @click="submitWaitlistSurvey">
            Submit
          </iButton>
        </iForm>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapWritableState, mapState } from "pinia";
import { useWaitlistStore } from "@/stores/waitlist-store";
import { useGeneralStore } from "@/stores/general-store";
import PageWrapper from "@/components/PageWrapper.vue";

export default {
  name: "WaitlistSurvey",
  components: {
    PageWrapper,
  },
  props: {
    waitlistSurveyResponses: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapWritableState(useWaitlistStore, { isLoading: "isLoading", storedWaitlistSurveyResponses: "waitlistSurveyResponses" }),
    ...mapState(useGeneralStore, ["userData", "orgId"]),
    surveyQuestions() {
      return [
        {
          name: "grow_business1",
          label: "How can we help you to grow your business over the next year?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.grow_business",
          input: "textarea",
        },
        {
          name: "collaborated",
          label: "Have you ever collaborated with other creators to create content? Tell us about your experience if you have, and let us know if you'd like to explore this if you haven't yet.",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.collaborated",
          input: "textarea",
        },
        {
          name: "website",
          label: "Do you have a website? What is the URL?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.website",
          input: "textarea",
        },
        {
          name: "newsletter",
          label: "Do you have an email newsletter? If you do, how many subscribers do you have and how often do you send newsletters out?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.newsletter",
          input: "textarea",
        },
        {
          name: "affiliate_link_platforms",
          label: "Are you using affiliate links to earn revenue promoting products you love? If yes, what platforms do you use to get your links?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.affiliate_link_platforms",
          input: "textarea",
        },
        {
          name: "influencer_marketing_platforms",
          label: "Are you using any influencer marketing platforms for sponsored content deals? If yes, which platforms?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.influencer_marketing_platforms",
          input: "textarea",
        },
        {
          name: "digital_products",
          label: "Do you sell digital products like how-to guides or e-books? Do you ever host live events with your fans?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.digital_products",
          input: "textarea",
        },
        {
          name: "sell_subscriptions",
          label: "Do you want to sell subscriptions to your fans so they can access exclusive content?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.sell_subscriptions",
          input: "textarea",
        },
        {
          name: "publishing_tool",
          label: "Do you use a tool to help you post on all the various social platforms? If yes, which one?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.publishing_tool",
          input: "textarea",
        },
        {
          name: "ai_content_tools",
          label: "Are you using AI to help generate content? If yes, what tools?",
          store: useWaitlistStore,
          storeVar: "waitlistSurveyResponses.ai_content_tools",
          input: "textarea",
        },
      ];
    },
  },
  created() {
    this.storedWaitlistSurveyResponses = this.waitlistSurveyResponses;
  },
  methods: {
    ...mapActions(useWaitlistStore, ["submitWaitlistSurvey"]),
  },
};
</script>

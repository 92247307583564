<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN"
    title="Configure Sign Up & Login"
    :breadcrumbs="breadcrumbs"
    page-name="Sign Up & Login"
    align="left"
    width="full"
  >
    <iRow gap="huge" wrap="wrap">
      <PublicationSettingsCard
        v-for="(settings, key) in publicationSettingsMap"
        :key="key"
        v-bind="settings"
        :focused="isSettingEnabled(key)"
        @select="selectSetting(key)"
      />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import PublicationSettingsCard from "@/components/publication/PublicationLoginSettingsCard.vue";
import {
  PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { cloneDeep, merge } from "lodash";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationSignupAndLogin",
  components: {
    PublicationSettingsCard,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN,
      SECTION_PUBLICATIONS,
      signupAndLoginConfig: {
        enabled: false,
        read_only_site: { enabled: false },
        signup_optional: { enabled: false },
        signup_required: { enabled: false },
        invite_only: { enabled: false },
        apply_to_join: { enabled: false },
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "configuration",
      "orgId",
      "publicationId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        {
          url: `/publication/${this.publicationId}/features`,
          label: "Features",
        },
      ];
    },
    publicationSettingsMap() {
      return {
        read_only_site: {
          title: "Read Only Site",
          description: "No signup no users everything visible to the public",
          features: [
            { name: "Private Publication", enabled: false },
            { name: "Allow Signups", enabled: false },
            { name: "Require Approval", enabled: false },
          ],
        },
        signup_optional: {
          title: "Signup Optional",
          description:
            "All content visible to the public some features, like commenting, require signup",
          features: [
            { name: "Private Publication", enabled: false },
            { name: "Allow Signups", enabled: true },
            { name: "Require Approval", enabled: false },
          ],
        },
        signup_required: {
          title: "Signup Required",
          description:
            "Anyone can signup but all content is hidden from the public",
          features: [
            { name: "Private Publication", enabled: true },
            { name: "Allow Signups", enabled: true },
            { name: "Require Approval", enabled: false },
          ],
        },
        invite_only: {
          title: "Invite Only",
          description:
            "Random signup not allowed, users must be invited then they signup and all content is hidden from the public",
          features: [
            { name: "Private Publication", enabled: true },
            { name: "Allow Signups", enabled: false },
            { name: "Require Approval", enabled: true },
          ],
        },
        apply_to_join: {
          title: "Apply to Join",
          description:
            "Strangers are allowed to signup, but admin approval required and all content is hidden from the public",
          features: [
            { name: "Private Publication", enabled: true },
            { name: "Allow Signups", enabled: true },
            { name: "Require Approval", enabled: true },
          ],
        },
      };
    },
  },
  created() {
    const mergedConfig = merge(
      {},
      this.signupAndLoginConfig,
      this.configuration.signup_and_login,
    );
    this.signupAndLoginConfig = cloneDeep(mergedConfig);
    this.ensureDefaultSelection();
  },
  methods: {
    ...mapActions(usePublicationStore, {
      saveConfigurationValue: "saveConfigurationValue",
    }),
    isSettingEnabled(key) {
      return this.signupAndLoginConfig[key]?.enabled === true;
    },
    async selectSetting(key) {
      if (!this.signupAndLoginConfig.enabled) {
        await this.saveConfigurationValue("signup_and_login.enabled", true);
        this.signupAndLoginConfig.enabled = true;
      }

      for (const settingKey of Object.keys(this.signupAndLoginConfig)) {
        if (
          settingKey !== "enabled" &&
          settingKey in this.publicationSettingsMap
        ) {
          const newValue = settingKey === key;
          if (this.signupAndLoginConfig[settingKey].enabled !== newValue) {
            await this.saveConfigurationValue(
              `signup_and_login.${settingKey}.enabled`,
              newValue,
            );
            this.signupAndLoginConfig[settingKey].enabled = newValue;
          }
        }
      }
    },
    async ensureDefaultSelection() {
      const hasSelection = Object.keys(this.publicationSettingsMap).some(
        key => this.signupAndLoginConfig[key]?.enabled === true,
      );

      if (!hasSelection) {
        await this.selectSetting("read_only_site");
      }
    },
  },
};
</script>

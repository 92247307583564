<template>
  <iRow class="splash-join-waitlist-container" align="center">
    <iColumn align="center" gap="huge">
      <iRow class="heading-container" align="center">
        <iHeading
          align="center"
          font-size="extraExtraLarge"
        >
          Join the waitlist for Inspiration today!
        </iHeading>
      </iRow>

      <iRow align="center">
        <iColumn class="text-container">
          <iParagraph max-width="fill" text-align="center">
            If you are accepted into our exclusive early adopter program, you will receive a ton of free benefits and
            free promotion to over 10 million potential new fans.
          </iParagraph>
        </iColumn>
      </iRow>

      <iRow class="input-container" align="center" vertical-align="middle">
        <iTextInput
          v-model="email"
          placeholder="Your email address"
          :width="480"
          class="email-input"
          @enter="joinWaitlist"
        />

        <iButton
          :variant="variant === 'default' ? 'primary' : 'striking'"
          class="join-waitlist-button"
          @click="joinWaitlist"
        >
          Join Waitlist
        </iButton>
      </iRow>
    </iColumn>
  </iRow>
</template>

<script>
import { Enum, validateEmail } from "@bloglovin/vue-component-library";
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";

export default {
  name: "SplashJoinWaitingListContainer",
  props: {
    variant: {
      type: Enum,
      required: false,
      default: "default",
      options: ["default", "alternative"],
    },
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    myBackgroundColor() {
      switch (this.variant) {
        case "alternative":
          return this.$getStyleValuePropertiesFromTheme("color.background")["informational"];
        default:
          return this.$getStyleValuePropertiesFromTheme("color.background")["striking"];
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "verifyUserExists",
    ]),
    joinWaitlist() {
      if (!validateEmail(this.email)) {
        return;
      }

      this.verifyUserExists(this.email).then(({ email, exists }) => {
        window.location.href = `/login?email=${email}&exists=${exists}`;
      });
    },
  },
  styleGuide: () => ({
    headingFontSize: { "size.font": "extraExtraLarge" },
    tabletHeadingFontSize: { "size.font": "extraExtraLarge" },
    mobileHeadingFontSize: { "size.font": "extraLarge" },

    paragraphFontSize: { "size.font": "extraLarge" },
    tabletParagraphFontSize: { "size.font": "large" },
    mobileParagraphFontSize: { "size.font": "standard" },
  }),
};
</script>

<style scoped lang="scss">
.splash-join-waitlist-container {
  padding: 40px;
  background-color: v-bind(myBackgroundColor);

  .heading-container {
    .i-heading {
      font-size: v-bind("$getStyles.headingFontSize");

      @media (max-width: 768px) {
        font-size: v-bind("$getStyles.mobileHeadingFontSize");
      }

      @media (max-width: 992px) {
        font-size: v-bind("$getStyles.tabletHeadingFontSize");
      }
    }
  }

  .input-container {
    .email-input {
      @include phone {
        max-width: unset;
        min-width: unset;
        width: 100%;
      }
    }
    .join-waitlist-button {
      width: fit-content;
    }
  }

  .text-container {
    max-width: 1100px;

    .i-paragraph {
      font-size: v-bind("$getStyles.paragraphFontSize");

      @include tablet {
        font-size: v-bind("$getStyles.tabletParagraphFontSize");
      }

      @include phone {
        font-size: v-bind("$getStyles.mobileParagraphFontSize");
      }
    }
  }
}
</style>

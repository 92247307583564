<template>
  <iFooter>
    <iRow
      class="footer"
      width="fill"
      align="between"
      vertical-align="top"
    >
      <iColumn class="cc-container" width="hug">
        <iText>
          © {{ currentYear }} Inspiration Starts Here, Inc. All rights reserved.
        </iText>
      </iColumn>
      <iColumn class="link-container" width="hug" align="right">
        <iRow gap="small" width="hug">
          <iColumn width="hug">
            <iText>
              Privacy Policy
            </iText>
          </iColumn>
          <iColumn width="hug">
            <iText>
              Terms of Service
            </iText>
          </iColumn>
          <iColumn width="hug">
            <iText>
              Cookie Policy
            </iText>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
  </iFooter>
</template>

<script>
export default {
  name: "SplashFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.i-footer {
  width: 100%;

  .footer {
    padding: 30px 60px;

    @include tablet {
      flex-direction: column;
    }

    @include phone {
      padding: 30px 30px;
    }
  }
}
.cc-container {
  @include tablet {
    width: 100%;
  }
}
.link-container {
  @include tablet {
    align-items: flex-start;
  }
}

</style>

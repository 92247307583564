<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_DIRECTORY"
    title="Directory"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Directory"
    align="center"
    width="full"
  >
    <iInfiniteScroll
      :has-more="hasMorePublicOrgs"
      :is-loading="loadingPublicOrgs"
      @load-more="loadMore"
    >
      <iRow wrap="wrap">
        <OrgCard v-for="org in publicOrgs" :key="org.org_id" :org="org" />
      </iRow>
    </iInfiniteScroll>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { useOrganizationStore } from "@/stores/organization-store";
import { SECTION_COMMUNITY, PAGE_DIRECTORY } from "@/constants/nav-constants";
import OrgCard from "@/components/directory/OrgCard";

export default {
  name: "Directory",
  components: {
    OrgCard,
    PageWrapper,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_DIRECTORY,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useOrganizationStore, [
      "publicOrgs",
      "loadingPublicOrgs",
      "hasMorePublicOrgs",
    ]),
  },
  methods: {
    ...mapActions(useOrganizationStore, [
      "fetchPublicOrgs",
    ]),
    loadMore() {
      this.fetchPublicOrgs();
    },
  },
};
</script>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-bubble[data-v-149edeec] {
  aspect-ratio: 1;
  background-color: lightgrey;
  border-radius: 50%;
  font-size: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/PublicationVersionCard.vue","webpack://./PublicationVersionCard.vue"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;ACFJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.version{\n  &-bubble{\n    aspect-ratio: 1;\n    background-color: lightgrey;\n    border-radius: 50%;\n    font-size: 8px;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n",".version-bubble {\n  aspect-ratio: 1;\n  background-color: lightgrey;\n  border-radius: 50%;\n  font-size: 8px;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <SplashWrapper
    :hide-buttons="true"
    title="Join our waitlist"
    :narrow-page="true"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iText variant="subtle">
          Start by entering your email address
        </iText>
      </iRow>

      <iRow>
        <iColumn gap="large">
          <iTextInput
            ref="email"
            v-model="userAuthEmail"
            :focus="true"
            class="email-input"
            :required="true"
            placeholder="Email address*"
            label-position="above"
            name="email"
            type="email"
            width="fill"
            autocomplete
            @enter="goToNextStep"
          />
          <iButton
            :disabled="!hasValidUserAuthEmail"
            :is-loading="isLoading"
            loading-spinner-position="inside"
            width="fill"
            variant="primary"
            @click="goToNextStep"
          >
            Continue
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </SplashWrapper>
</template>

<script>
import { mapWritableState, mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "EmailForm",
  components: { SplashWrapper },
  emits: ["update:stepIsComplete"],
  computed: {
    ...mapWritableState(useUserStore, [
      "userAuthEmail",
    ]),
    ...mapState(useUserStore, [
      "isLoading",
      "hasValidUserAuthEmail",
    ]),
  },
  methods: {
    ...mapActions(useUserStore, [
      "verifyUserExists",
    ]),
    goToNextStep() {
      if (!this.hasValidUserAuthEmail) {
        return;
      }
      this.verifyUserExists(this.userAuthEmail);
    },
  },
};
</script>

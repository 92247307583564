<template>
  <iColumn gap="small">
    <iText variant="standard" font-size="extraLarge">
      Team
    </iText>
    <iRow wrap="wrap" gap="large">
      <OrgTeamCard
        v-for="user in activeUsers"
        :key="user.user_id"
        :name="`${user.first_name} ${user.last_name}`"
        :avatar-url="user.avatar_url ?? ''"
        :role-id="Number(user.role_id)"
      />
    </iRow>
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import OrgTeamCard from "@/components/directory/OrgTeamCard";

export default {
  name: "OrgTeam",
  components: { OrgTeamCard },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(useOrganizationStore, [
      "activeUsers",
    ]),
  },
};
</script>

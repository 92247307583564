<template>
  <NewCommentMessage
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
  <CommentThread
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
  <iLoadingSpinner v-if="isMessageLoading.newMessage" />
</template>

<script>
import { mapState, mapActions } from "pinia";
import NewCommentMessage from "@/components/conversations/NewCommentMessage";
import CommentThread from "@/components/conversations/CommentThread";
import { useConversationStore } from "@/stores/conversation-store";

export default {
  name: "Comments",
  components: {
    CommentThread,
    NewCommentMessage,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(useConversationStore, ["conversation", "isMessageLoading"]),
  },
  async created() {
    this.fetchConversation(this.conversationId, this.context, this.entityId);
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchConversation: "fetchConversation",
    }),
  },
};
</script>

<style scoped lang="scss"></style>

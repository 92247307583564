import dayjs from "dayjs";

const dateUtilsMixin = {
  data() {
    return {
      DD_MMMM_YYYY_HH_MM_A: "DD MMMM YYYY @ hh:mm a",
    };
  },
  methods: {
    formatUnixTo(date, format = this.DD_MMMM_YYYY_HH_MM_A) {
      return dayjs.unix(date).format(format);
    },
  },
};

export default dateUtilsMixin;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection-card[data-v-0c79bdb7] {
  cursor: pointer;
}
.collection-card-description .i-text[data-v-0c79bdb7] {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.collection-card-cover-image[data-v-0c79bdb7] {
  border-radius: var(--0c79bdb7-\\\$getStyles\\.coverImageBorderRadius);
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/AffiliateLinkCollectionCard.vue","webpack://./AffiliateLinkCollectionCard.vue"],"names":[],"mappings":"AAEE;EACE,eAAA;ACDJ;ADGM;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,uBAAA;EACA,gBAAA;ACDR;ADII;EACE,kEAAA;EACA,gBAAA;ACFN","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .collection-card {\n    cursor: pointer;\n    &-description{\n      .i-text{\n        display: -webkit-box;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        text-overflow: ellipsis;\n        overflow: hidden;\n      }\n    }\n    &-cover-image{\n      border-radius: v-bind(\"$getStyles.coverImageBorderRadius\");\n      overflow: hidden;\n    }\n  }\n",".collection-card {\n  cursor: pointer;\n}\n.collection-card-description .i-text {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.collection-card-cover-image {\n  border-radius: v-bind(\"$getStyles.coverImageBorderRadius\");\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

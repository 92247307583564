import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const usePartnershipStore = defineStore("partnership-store", {
  state: () => ({
    inspirationNetworkPartnerships: [],
    directNetworkPartnerships: [],
    isLoading: false,
    loadingInspirationNetworkPartnerships: false,
    lastInspirationNetworkPartnershipId: 0,
    lastDirectNetworkPartnershipId: 0,
    advertiserDetails: {},
    advertiserDomains: [],
    loadingAdvertiserDetails: false,
  }),
  getters: {
    hasMoreInspirationNetworkPartnerships() {
      return this.lastInspirationNetworkPartnershipId !== -1;
    },
    hasMoreDirectNetworkPartnerships() {
      return this.lastDirectNetworkPartnershipId !== -1;
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    fetchAdvertiserDetails(orgId) {
      this.loadingAdvertiserDetails = true;

      return $axios.get(`${this.affiliateApiV1Url}/advertiser/${orgId}`, {
        params: { last_partnership_id: this.lastInspirationNetworkPartnershipId },
      }).then(response => {
        this.advertiserDetails = response.data.advertiser;
      }).catch(() => {
        this.errorFetchAdvertiserDetails = "There was a problem fetching the advertiser details";
      }).finally(() => {
        return this.loadingAdvertiserDetails = false;
      });
    },
    fetchInspirationNetworkPartnerships() {
      if (this.lastInspirationNetworkPartnershipId === -1 || this.loadingInspirationNetworkPartnerships) {
        return;
      }

      this.loadingInspirationNetworkPartnerships = true;
      $axios.get(`${this.affiliateApiV1Url}/partnership/inspiration`, {
        params: { last_partnership_id: this.lastInspirationNetworkPartnershipId, publisher_org_id: this.orgId },
      }).then(response => {
        const newInspirationNetworkPartnerships = response.data.partnerships;
        let partnershipsLength = newInspirationNetworkPartnerships.length;
        this.inspirationNetworkPartnerships = [...this.inspirationNetworkPartnerships, ...newInspirationNetworkPartnerships];
        if (partnershipsLength === 0) {
          this.lastInspirationNetworkPartnershipId = -1;
        } else {
          this.lastInspirationNetworkPartnershipId = newInspirationNetworkPartnerships.at(-1).partnership_id ?? -1;
        }
      }).finally(() => {
        this.loadingInspirationNetworkPartnerships = false;
      });
    },
    fetchDirectNetworkPartnerships() {
      if (this.lastDirectNetworkPartnershipId === -1 || this.isLoading) {
        return;
      }

      this.isLoading = true;
      $axios.get(`${this.affiliateApiV1Url}/partnership/direct`, {
        params: { last_partnership_id: this.lastDirectNetworkPartnershipId, publisher_org_id: this.orgId },
      }).then(response => {
        const newDirectPartnerships = response.data.partnerships;
        this.directNetworkPartnerships = [...this.directNetworkPartnerships, ...newDirectPartnerships];
        if (newDirectPartnerships.length === 0) {
          this.lastDirectNetworkPartnershipId = -1;
        } else {
          this.lastDirectNetworkPartnershipId = newDirectPartnerships.at(-1).partnership_id ?? -1;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
});

<template>
  <PageWrapper :active-section="SECTION_DIGITAL_WALLET" :active-page="PAGE_INVOICES">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_DIGITAL_WALLET, PAGE_INVOICES } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "Invoices",
  components: {
    ComingSoon,
    PageWrapper,
  },
  props: {
  },
  data() {
    return {
      SECTION_DIGITAL_WALLET,
      PAGE_INVOICES,
    };
  },
};
</script>

<template>
  <iStyledCard
    :splash-image="entity.publicationSplashImage"
    :badge="entity.status"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/community/publications/${entity.publicationId}/profile`"
    :metrics="entity.metrics"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          object-fit="cover"
          :height="35"
          :width="35"
          shape="circle"
          :src="entity.publicationLogo"
        />
        <iSubHeading font-weight="dark">
          {{ entity.name }}
        </iSubHeading>
      </iRow>
    </template>
    <template #subtitle>
      <iLink
        hover-underline
        color="subtle"
        target="_blank"
        :href="subTitleHref"
      >
        {{ subTitle }}
      </iLink>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";

export default {
  name: "CommunityPublicationCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_ORG_LOGO,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    subTitle() {
      return this.entity.domain ?? `${window.location.origin}/my-publication/${this.entity.publicationId}`;
    },
    subTitleHref() {
      return `${window.location.origin}/my-publication/${this.entity.publicationId}`;
    },
    descriptionHref() {
      return `${window.location.origin}/my-publication/staging/${this.entity.publicationId}`;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}
</style>

<template>
  <iStyledCard
    :title="name"
    :default-href="`/monetization/org/${orgId}/links/collection/${linkCollectionId}`"
    :metrics="metrics"
    :width="395"
    :badge="category"
    :splash-image="coverImage || DEFAULT_COLLECTION_COVER_IMAGE"
  >
    <template #footer>
      <iButton width="fill" variant="secondary" @click.stop="openEditCollectionModal">
        Edit Collection
      </iButton>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_COLLECTION_COVER_IMAGE } from "@/constants/affiliate-constants";
import { mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";

export default {
  name: "AffiliateLinkCollectionCard",
  props: {
    linkCollectionId: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: "unsorted",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    coverImage: {
      type: String,
      required: false,
      default: "",
    },
    visibility: {
      type: String,
      required: false,
      default: "public",
    },
    linkCount: {
      type: Number,
      required: false,
      default: 0,
    },
    sales: {
      type: Number,
      required: false,
      default: 0,
    },
    clicks: {
      type: Number,
      required: false,
      default: -1,
    },
    pendingEarnings: {
      type: Number,
      required: false,
      default: 0,
    },
    lockedEarnings: {
      type: Number,
      required: false,
      default: 0,
    },
    conversions: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["edit-collection"],
  data() {
    return {
      isDropdownOpen: false,
      isEditModalOpen: false,
      DEFAULT_COLLECTION_COVER_IMAGE,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    metrics() {
      return [
        { name: "Clicks", value: this.clicks },
        { name: "Conversions", value: this.conversions },
        { name: "Sales", value: this.sales },
        { name: "Pending Earnings", value: this.pendingEarnings },
        { name: "Locked Earnings", value: this.lockedEarnings },
      ];
    },
  },
  methods: {
    openEditCollectionModal() {
      const collection = {
        name: this.name,
        description: this.description,
        coverImage: this.coverImage,
        category: this.category,
        visibility: this.visibility,
        affiliateLinkCollectionId: this.linkCollectionId,
      };
      this.$emit("edit-collection", collection);
      this.closeDropdown();
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
  },
  styleGuide: () => ({
    coverImageBorderRadius: { "size.borderRadius": "large" },
  }),
};
</script>
<style lang="scss" scoped>
  .collection-card {
    cursor: pointer;
    &-description{
      .i-text{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &-cover-image{
      border-radius: v-bind("$getStyles.coverImageBorderRadius");
      overflow: hidden;
    }
  }
</style>

<template>
  <iRow vertical-align="middle" align="between">
    <iRow width="hug">
      <iRow width="hug" gap="extraSmall" wrap="nowrap">
        <template v-if="conversationSettings.allowsUpvoteAndDownvote">
          <iColumn width="hug">
            <iIcon
              icon="arrow-up"
              :variant="userUpVoted ? 'error' : 'primary'"
              :disabled="!isActive"
              :is-loading="loadingUpVotedMessage"
              @click="handleUpVote"
            />
          </iColumn>
          <iText :variant="isActive? 'standard' : 'subtle'">
            {{ getVotes }}
          </iText>
          <iColumn width="hug">
            <iIcon
              icon="arrow-down"
              :variant="userDownVoted? 'error' : 'primary'"
              :disabled="!isActive"
              :is-loading="loadingDownVotedMessage"
              @click="handleDownVote"
            />
          </iColumn>
        </template>
      </iRow>
      <iRow v-if="conversationSettings.allowsLikes" width="hug" gap="extraSmall">
        <iIcon
          icon="heart"
          :variant="userLiked ? 'error' : 'primary'"
          :disabled="!isActive"
          :is-loading="loadingLikedMessage"
          @click="handleLike"
        />
        <iText :variant="isActive? 'standard' : 'subtle'">
          {{ numLikes }}
        </iText>
      </iRow>
      <iColumn width="hug">
        <iRow v-if="showReplyButton" variant="tertiary" gap="extraSmall">
          <iIcon icon="comment" :disabled="!isActive" @click="reply" />
          <iText :variant="isActive? 'standard' : 'subtle'">
            {{ getReplies }}
          </iText>
        </iRow>
      </iColumn>
    </iRow>
    <ConversationMenu
      :message-id="messageId"
      :is-author="isAuthor"
      :is-loading="isConversationMenuLoading"
      :is-moderator="isModerator"
      :moderation-status="moderationStatus"
      :status="status"
      :moderation-enabled="moderationEnabled"
      :settings="conversationSettings"
      @report="report"
      @edit="turnOnIsEditing"
      @block="blockComment"
      @delete="deleteComment"
      @undelete="unDeleteComment"
    />
  </iRow>
  <iRow v-if="showModerationButtons" align="between">
    <iColumn width="hug">
      <iButton
        variant="secondary"
        :is-loading="loadingApprovedMessage"
        @click="approveComment"
      >
        Approve
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton
        variant="secondary"
        :is-loading="loadingBlockedMessage"
        @click="blockComment"
      >
        Block
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton variant="secondary" :is-loading="loadingEscalatedMessage" @click="escalateComment">
        Escalate
      </iButton>
    </iColumn>
  </iRow>
</template>

<script>
import { mapState } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import ConversationMenu from "@/components/conversations/ConversationMenu";
import { OFFENSE_OPTIONS } from "@/constants/conversations-constants";

export default {
  name: "ConversationFooter",
  components: {
    ConversationMenu,
  },
  props: {
    currentMessage: {
      type: String,
      required: true,
    },
    voteScore: {
      type: Number,
      required: false,
      default: "0",
    },
    numReplies: {
      type: Number,
      required: false,
      default: "0",
    },
    numLikes: {
      type: Number,
      required: false,
      default: "0",
    },
    authorId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    showReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    userLiked: {
      type: Boolean,
      required: true,
    },
    userUpVoted: {
      type: Boolean,
      required: true,
    },
    userDownVoted: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "upvote",
    "revokeupvote",
    "revokedownvote",
    "downvote",
    "like",
    "unlike",
    "delete",
    "undelete",
    "block",
    "reply",
    "approve",
    "report",
    "startEditing",
    "escalate",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      editMessage: "",
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "isLoading",
      "isModerator",
      "moderationEnabled",
      "conversationSettings",
      "isMessageLoading",
    ]),
    ...mapState(useGeneralStore, ["userData"]),
    isConversationMenuLoading() {
      if (!this.isMessageLoading[this.messageId]) {
        return false;
      }
      if (this.isMessageLoading[this.messageId].delete) {
        return true;
      }
      if (this.isMessageLoading[this.messageId].undelete) {
        return true;
      }
      return false;
    },
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationStatus === "approved" && this.moderationEnabled;
    },
    showModerationButtons() {
      return this.moderationStatus !== "approved" && this.isModerator && this.moderationEnabled;
    },
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    showReplyButton() {
      return this.conversationSettings.allowsReply && this.showReplying && this.allowReplying;
    },
    getReplies() {
      if (this.numReplies === 0) {
        return "Reply";
      }
      return this.numReplies;
    },
    getVotes() {
      if (this.voteScore === 0) {
        return "Vote";
      }
      return this.voteScore;
    },
    isActive() {
      return this.status === "active";
    },
    loadingUpVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["upvote"] || this.isMessageLoading[this.messageId]["revoke-upvote"]);
    },
    loadingDownVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["downvote"] || this.isMessageLoading[this.messageId]["revoke-downvote"]);
    },
    loadingLikedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["like"] || this.isMessageLoading[this.messageId]["revoke-like"]);
    },
    loadingApprovedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["approve"];
    },
    loadingEscalatedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["escalate"];
    },
    loadingBlockedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["block"];
    },
  },
  created() {
    this.editMessage = this.message;
  },
  methods: {
    handleUpVote() {
      if (this.userUpVoted) {
        return this.revokeUpVote();
      }
      this.upVote();
    },
    handleDownVote() {
      if (this.userDownVoted) {
        return this.revokeDownVote();
      }
      this.downVote();
    },
    handleLike() {
      if (this.userLiked) {
        return this.unlike();
      }
      this.like();
    },
    turnOnIsEditing() {
      this.showMenuOptions = false;
      if (!this.isAuthor) {
        return;
      }
      this.$emit("startEditing");
    },
    upVote() {
      this.$emit("upvote");
    },
    revokeUpVote() {
      this.$emit("revokeupvote");
    },
    downVote() {
      this.$emit("downvote");
    },
    revokeDownVote() {
      this.$emit("revokedownvote");
    },
    like() {
      this.$emit("like");
    },
    unlike() {
      this.$emit("unlike");
    },
    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.$emit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.$emit("undelete");
    },
    report(reason) {
      this.$emit("report", reason);
    },
    reply() {
      this.showMenuOptions = false;
      this.$emit("reply");
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block", { reason: this.OFFENSE_OPTIONS[0].value, id: this.messageId });
    },
    approveComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("approve");
    },
    escalateComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("escalate");
    },
  },
};
</script>
<style scoped lang="scss"></style>

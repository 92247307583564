// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide-cursor[data-v-acd61208] .i-text-input {
  caret-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/settings/UserAccount.vue","webpack://./UserAccount.vue"],"names":[],"mappings":"AAGE;EACE,wBAAA;ACFJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.hide-cursor {\n  :deep(.i-text-input) {\n    caret-color: transparent;\n  }\n}\n",".hide-cursor :deep(.i-text-input) {\n  caret-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_PUBLICATIONS"
    title="Publications"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Publications"
    align="center"
    width="full"
  >
    <iInfiniteScroll
      :is-loading="communityPublicationsLoading"
      :has-more="hasMorePublications"
      :scroll-threshold="400"
      @load-more="loadMorePublications"
    >
      <iRow wrap="wrap">
        <CommunityPublicationCard
          v-for="publication in communityPublications"
          :key="publication.publication_id"
          :publication="publication"
        />
      </iRow>
    </iInfiniteScroll>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { SECTION_COMMUNITY, PAGE_COMMUNITY_PUBLICATIONS } from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import CommunityPublicationCard from "@/components/publication/CommunityPublicationCard.vue";

export default {
  name: "CommunityPublications",
  components: {
    PageWrapper,
    CommunityPublicationCard,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_PUBLICATIONS,
      hasMorePublications: true,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(usePublicationStore, [
      "communityPublications",
      "communityPublicationsLoading",
      "lastCommunityPublicationId",
      "hasMoreCommunityPublications",
    ]),
  },

  methods: {
    ...mapActions(usePublicationStore, [
      "getCommunityPublications",
    ]),
    loadMorePublications() {
      this.getCommunityPublications();
    },
  },
};
</script>

import { ToastBuilder } from "@bloglovin/vue-component-library";
import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const usePaymentStore = defineStore("payment-store", {
  state: () => ({
    loadingWithdrawal: false,
    digitalWallet: {},
  }),
  getters: {
    showDigitalWalletBalance() {
      return this.digitalWalletTipaltiStatus !== "not_setup";
    },
    digitalWalletTipaltiStatus() {
      return this.digitalWallet.tipaltiStatus;
    },
    digitalWalletBalanceValue() {
      return Number(this.digitalWallet.balance);
    },
    digitalWalletCurrency() {
      return this.digitalWallet.currency;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    digitalWalletBalanceString() {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: this.digitalWalletCurrency,
      }).format(this.digitalWalletBalanceValue);
    },
    canWithdrawFromDigitalWallet() {
      return this.digitalWalletBalanceValue > 100 && this.digitalWalletTipaltiStatus === "payable";
    },
    paymentsV1ApiUrl() {
      return useGeneralStore().getLink(links.paymentsApiV1);
    },
  },
  actions: {
    setDigitalWalletData(data) {
      this.digitalWallet = data;
    },
    createWithdrawal() {
      this.loadingWithdrawal = true;
      $axios.post(`${this.paymentsV1ApiUrl}/digital-wallet/${this.orgId}/create-withdrawal`, { amount: this.digitalWalletBalanceValue })
          .then(() => {
            new ToastBuilder().setTitle("Withdrawal In Progress")
                .setText("Withdrawal request has been created successfully.")
                .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
                .setTimeout(5000)
                .setClosable(true)
                .build();
          })
          .catch(error => {
            new ToastBuilder().setTitle("Withdrawal Error")
                .setText(error.response.data.message)
                .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
                .setTimeout(5000)
                .setClosable(true)
                .build();
          })
          .finally(() => {
            this.loadingWithdrawal = false;
          });
    },
  },
});

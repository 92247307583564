import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const useActionStore = defineStore("action-store", {
  state: () => ({
    actions: [],
    loadingActions: false,
    lastActionId: 0,
  }),
  getters: {
    hasMoreActions() {
      return this.lastActionId !== -1;
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    fetchActions() {
      if (this.loadingActions) {
        return;
      }
      this.loadingActions = true;
      $axios.get(`${this.affiliateApiV1Url}/action`, {
        params: {
          last_action_id: this.lastActionId,
          publisher_org_id: this.orgId,
        },
      })
          .then(response => {
            if (response.data.success) {
              this.actions = response.data.actions;
              if (response.data.actions.length === 0) {
                this.lastActionId = -1;
              } else {
                this.lastActionId = response.data.actions.at(-1).internal_action_id;
              }
            }
            this.loadingActions = false;
          });
    },
  },
});

import { defineStore } from "pinia";
import { links } from "@/enums/link-enums";
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";

export const useGeneralStore = defineStore("general-store", {
  state: () => ({
    appId: 1,
    userId: 0,
    orgId: 0,
    userData: {},
    orgData: {},
    links: [],
    baseDomain: "",
  }),
  getters: {
    settingsBreadcrumbs() {
      return [
        { url: "/splash", label: "Home" },
        { url: `/settings/org/${this.orgId}`, label: "Settings" },
      ];
    },
    getLink: state => linkName => {
      if (!Object.values(links).includes(linkName)) {
        throw new Error(`links enum does not contain a link '${linkName}'`);
      }
      return state.links[linkName];
    },
    userAvatar() {
      if (this.userData.avatar_url) {
        return this.userData.avatar_url;
      }
      return DEFAULT_USER_AVATAR;
    },
    orgSplash() {
      if (this.orgData.splash_url) {
        return this.orgData.splash_url;
      }
      return DEFAULT_SPLASH_IMAGE;
    },
    orgLogo() {
      if (this.orgData.logo_url) {
        return this.orgData.logo_url;
      }
      return DEFAULT_ORG_LOGO;
    },
    orgCapabilities() {
      return this.orgData.capabilities;
    },

  },
  actions: {
    setAppId(id) {
      this.appId = id;
    },
    setLinks(links) {
      this.links = links;
    },
    setOrgData(data) {
      this.orgData = data;
      this.orgId = parseInt(data.org_id);
    },
    setUserData(data) {
      this.userData = data;
      this.userId = parseInt(data.user_id);
    },
    setOrgName(orgName) {
      this.orgData.name = orgName;
    },
    setBaseDomain(baseDomain) {
      this.baseDomain = baseDomain;
    },
  },
});

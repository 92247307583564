<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_AFFILIATE">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE,
  SECTION_MONETIZATION,
} from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "Affiliate",
  components: {
    ComingSoon,
    PageWrapper,
  },
  props: {
  },
  data() {
    return {
      PAGE_AFFILIATE,
      SECTION_MONETIZATION,
    };
  },
};
</script>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USER_POSTING"
    title="User Posting"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="User Posting"
    align="center"
    width="full"
  >
    <iColumn>
      <iRow vertical-align="middle">
        <iSubHeading>Enable Post Creation</iSubHeading>
        <iToggleSwitch v-model="postCreationEnabled" label="" @change="savePublicationConfig" />
      </iRow>
      <iText>
        Allow users to like your posts
      </iText>
      <iSelect
        v-model="postCreationType"
        return-value
        :items="postTypes"
        label="Post Creation Type"
        value-field="value"
        @change="savePublicationConfig"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import {
  PAGE_PUBLICATION_USER_POSTING,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationUserPosting",
  components: { PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USER_POSTING,
      SECTION_PUBLICATIONS,
      config: {
        enabled: false,
        post_creation_type: "quick_post",
      },
      postTypes: [
        { name: "Quick Post", value: "quick_post" },
      ],
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publication", "posts", "isLoading", "publicationId"]),
    ...mapState(usePublicationStore, [
      "configuration",
      "isLoadingConfig",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    postCreationType: {
      get() {
        return this.config.post_creation.post_creation_type;
      },
      set(value) {
        this.config = {
          ...this.config,
          post_creation: {
            ...this.config.post_creation,
            post_creation_type: value,
          },
        };
      },
    },
    postCreationEnabled: {
      get() {
        return this.config.post_creation.enabled;
      },
      set(value) {
        this.config = {
          ...this.config,
          post_creation: {
            ...this.config.post_creation,
            enabled: value,
          },
        };
      },
    },
  },
  created() {
    this.config = {
      ...this.configuration,
      post_creation: {
        ...this.config,
        ...this.configuration.post_creation || {},
      },
    };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("post_creation", this.config.post_creation);
    },
  },
};
</script>

<style scoped lang="scss">

</style>

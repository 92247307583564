<template>
  <PageWrapper :active-section="SECTION_DIGITAL_WALLET" :active-page="PAGE_WALLET_SETTINGS">
    <Tipalti />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_DIGITAL_WALLET, PAGE_WALLET_SETTINGS } from "@/constants/nav-constants";
import Tipalti from "@/components/Tipalti";

export default {
  name: "WithdrawalSettings",
  components: {
    Tipalti,
    PageWrapper,
  },
  props: {
  },
  data() {
    return {
      SECTION_DIGITAL_WALLET,
      PAGE_WALLET_SETTINGS,
    };
  },
};
</script>

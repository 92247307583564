<template>
  <SplashWrapper
    :hide-buttons="true"
    :title="'Complete Your Registration'"
  >
    <iRow class="auth-form" align="center">
      <iColumn class="registration-form" align="center">
        <iRow :width="400">
          <iColumn>
            <iForm :questions="[]" @submit.prevent="submitRegistration">
              <iRow>
                <iColumn gap="extraLarge">
                  <iRow>
                    <iHeading font-size="extraExtraLarge">
                      Complete Your Registration
                    </iHeading>
                  </iRow>

                  <iSpace :height="10" />

                  <iRow>
                    <iText variant="subtle">
                      Please provide your details to complete the registration process and start collaborating.
                    </iText>
                  </iRow>

                  <iTextInput
                    v-model="orgName"
                    placeholder="Organization Name*"
                    name="orgName"
                    type="text"
                  />
                  <iTextInput
                    v-model="firstName"
                    placeholder="First Name*"
                    name="firstName"
                    type="text"
                  />
                  <iTextInput
                    v-model="lastName"
                    placeholder="Last Name*"
                    name="lastName"
                    type="text"
                  />
                  <iTextInput
                    v-model="email"
                    placeholder="Email*"
                    name="email"
                    type="email"
                  />
                  <iTextInput
                    v-model="password"
                    placeholder="Password*"
                    name="password"
                    type="password"
                  />

                  <iCheckbox
                    v-model="acceptedTerms"
                    required
                    label="I agree to the terms and conditions and privacy policy"
                    name="terms"
                  />

                  <PasswordComplexity
                    v-if="password"
                    :password="password"
                    @update:password="validatePasswordComplexity"
                  />

                  <iText v-if="errorMessage" variant="error">
                    {{ errorMessage }}
                  </iText>
                  <iRow>
                    <iButton
                      type="submit"
                      variant="primary"
                      width="fill"
                      :disabled="!isValidForm || isLoading"
                      :is-loading="isLoading"
                      loading-spinner-position="inside"
                      @click="submitRegistration"
                    >
                      Complete Registration
                    </iButton>
                  </iRow>
                </iColumn>
              </iRow>
            </iForm>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
  </SplashWrapper>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import SplashWrapper from "@/components/splash/SplashWrapper";
import PasswordComplexity from "@/components/PasswordComplexity";

export default {
  name: "InvitedOffPlatform",
  components: {
    PasswordComplexity,
    SplashWrapper,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      acceptedTerms: false,
      orgName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      validPassword: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "isLoading",
      "signupErrorMessage",
    ]),
    isValidForm() {
      return this.orgName && this.firstName && this.lastName && this.email && this.acceptedTerms && this.validPassword;
    },
    errorMessage() {
      return this.signupErrorMessage;
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "completeOffPlatformInviteRegistration",
    ]),
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
    submitRegistration() {
      if (!this.isValidForm) {
        return;
      }

      this.completeOffPlatformInviteRegistration(
        this.orgId,
        this.userId,
        this.firstName,
        this.lastName,
        this.orgName,
        this.email,
        this.password,
        this.token,
      );
    },
  },
};
</script>

<style scoped>
.auth-form {
  margin: 0 auto;
  padding: 0 10px;
}
</style>

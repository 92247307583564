<template>
  <PageWrapper
    :active-page="$options.name"
    active-section=""
    title="Invite Friends"
    sub-title="Earn points when people register"
    :breadcrumbs="[{url: '/splash', label: 'Home'}]"
    page-name="Invite Friends"
    align="center"
    width="fixed"
  >
    <iColumn>
      <iRow>
        <iParagraph>
          Share this link with your creator friends and invite them to join inspiration.co,
          then you can collaborate and build a community or start a publication together.
        </iParagraph>
        <iParagraph>
          We track who joins the inspiration network using your personal invite link,
          earn points by inviting more friends and win prizes.
        </iParagraph>
      </iRow>
      <iRow>
        <iTextInput
          :right-icon="ctaIcon"
          disabled
          :model-value="formattedInvitationLink"
          @click-icon-right="copyInvitationLink"
        />
      </iRow>
      <iRow>
        <iCard width="fill">
          <template #body>
            <iColumn gap="standard">
              <iSubHeading>Your Referral Stats</iSubHeading>
              <iRow align="evenly">
                <iColumn align="center">
                  <iTitle>{{ referralCount }}</iTitle>
                  <iText>Friends Referred</iText>
                </iColumn>
                <iColumn align="center">
                  <iTitle>{{ pointsEarned }}</iTitle>
                  <iText>Points Earned</iText>
                </iColumn>
              </iRow>
            </iColumn>
          </template>
        </iCard>
      </iRow>
      <iRow>
        <iButton variant="primary" width="fill" @click="copyInvitationLink">
          Share Your Referral Link
        </iButton>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";

export default {
  name: "WaitlistInvite",
  components: {
    PageWrapper,
  },
  props: {
    invitationLink: {
      type: String,
      required: true,
    },
    referralCount: {
      type: Number,
      required: true,
    },
    pointsEarned: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/splash", label: "home" },
      ];
    },
    formattedInvitationLink() {
      return this.invitationLink.replace("\\/\\", "/").replaceAll("\\/", "/");
    },
    showShare() {
      return navigator.share;
    },
    ctaIcon() {
      if (this.showShare) {
        return "arrow-curved-right";
      }
      return "copy";
    },
  },
  methods: {
    copyInvitationLink() {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          text: "Join me on inspiration.co...",
          url: this.formattedInvitationLink,
        });
      } else {
        navigator.clipboard.writeText(this.formattedInvitationLink);
      }
    },
  },
};
</script>

<template>
  <PageWrapper
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_COLLABORATOR_INVITATIONS_SETTINGS"
    title="Manage Collaborations"
    :breadcrumbs="settingsBreadcrumbs"
    page-name="Manage Collaborations"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iColumn gap="standard">
        <iSubHeading variant="subtle">
          Your applications
        </iSubHeading>
        <PublicationCollaboratorCard
          v-for="application in orgCollaboratorApplications"
          :key="application.publication_applicant_id"
          show-actions
          :collaborator="mapApplicationToCollaborator(application)"
          @revoke="handleRevokeOrgApplication(application.publication_applicant_id)"
          @accept="handleAcceptOrgCollaboratorApplication(application.publication_applicant_id)"
          @deny="handleDeclineOrgCollaboratorApplication(application.publication_applicant_id)"
        />
      </iColumn>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import {
  PAGE_COLLABORATOR_INVITATIONS_SETTINGS,
  SECTION_SETTINGS,
} from "@/constants/nav-constants";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useGeneralStore } from "@/stores/general-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "CollaboratorInvitations",
  components: {
    PublicationCollaboratorCard,
    PageWrapper,
  },
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_COLLABORATOR_INVITATIONS_SETTINGS,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["settingsBreadcrumbs"]),
    ...mapState(useCollaborationStore, ["orgCollaboratorApplications"]),
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "acceptOrgCollaboratorApplication",
      "declineOrgCollaboratorApplication",
      "revokeOrgCollaboratorApplication",
    ]),
    async handleAcceptOrgCollaboratorApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.acceptOrgCollaboratorApplication(
        application.publication_id,
        application.org_id,
        applicantId,
      );
    },
    async handleDeclineOrgCollaboratorApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.declineOrgCollaboratorApplication(
        application.publication_id,
        application.org_id,
        applicantId,
      );
    },
    async handleRevokeOrgApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.revokeOrgCollaboratorApplication(
        application.publication_id,
        application.org_id,
        applicantId,
      );
    },
    getCollaboratorApplication(applicantId) {
      return (
        Object.values(this.orgCollaboratorApplications).find(
          application => application.publication_applicant_id === applicantId,
        ) || null
      );
    },
    mapApplicationToCollaborator(application) {
      return {
        id: application.publication_applicant_id,
        name: application.publication.name,
        role: "Applicant",
        status: application.status,
        email: application.email,
        avatar: application.avatar || "",
        orgId: application.org_id,
      };
    },
  },
};
</script>

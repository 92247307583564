<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_ACTIONS_REPORT"
    title="Affiliate Sales"
    :breadcrumbs="breadcrumbs"
    page-name="Affiliate Sales"
    align="left"
    width="full"
  >
    <iDataTable
      :has-more="hasMoreActions"
      :is-loading="loadingActions"
      :items="actions"
      :fields="fields"
      @load-more="fetchActions"
    >
      <template #td(sale_amount)="{data}">
        {{ getFormattedCurrencyValue(data.sale_currency, data.sale_amount) }}
      </template>
      <template #td(commission_amount)="{data}">
        {{ getFormattedCurrencyValue(data.commission_currency, data.publisher_commission_amount) }}
      </template>
      <template #td(event_date)="{data}">
        {{ formattedDate(data.event_timestamp) }}
      </template>
      <template #td(locking_date)="{data}">
        {{ formattedDate(data.locking_timestamp) }}
      </template>
      <template #td(status)="{data}">
        {{ data.action_state }}
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { useActionStore } from "@/stores/action-store";
import { mapActions, mapState } from "pinia";
import dayjs from "dayjs";
import {
  SECTION_MONETIZATION, PAGE_ACTIONS_REPORT,
} from "@/constants/nav-constants";

export default {
  name: "ActionReport",
  components: {
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_ACTIONS_REPORT,
      fields: [
        {
          key: "sale_amount",
          label: "Sale Amount",
        },
        {
          key: "commission_amount",
          label: "Commission Amount",
        },
        {
          key: "event_date",
          label: "Event Date",
        },
        {
          key: "locking_date",
          label: "Locking Date",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
    };
  },
  computed: {
    ...mapState(useActionStore, [
      "loadingActions",
      "actions",
      "hasMoreActions",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  created() {
    this.fetchActions();
  },
  methods: {
    ...mapActions(useActionStore, [
      "fetchActions",
    ]),
    formattedDate(date) {
      return dayjs.unix(date).format("MM-DD-YY hh:mm");
    },
    getFormattedCurrencyValue(currency, value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(value);
    },
  },
};
</script>

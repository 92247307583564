// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-card[data-v-794a2cf8] {
  border: var(--794a2cf8-\\\$getStyles\\.borderSize) solid var(--794a2cf8-\\\$getStyles\\.borderColor);
  border-radius: var(--794a2cf8-\\\$getStyles\\.borderRadius);
}
.publication-details[data-v-794a2cf8] {
  padding-top: var(--794a2cf8-\\\$getStyles\\.paddingTop);
  padding-left: var(--794a2cf8-\\\$getStyles\\.paddingLeft);
  padding-right: var(--794a2cf8-\\\$getStyles\\.paddingRight);
  padding-bottom: var(--794a2cf8-\\\$getStyles\\.paddingBottom);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/CommunityPublicationCard.vue","webpack://./CommunityPublicationCard.vue"],"names":[],"mappings":"AAEA;EACE,8FAAA;EACA,wDAAA;ACDF;ADIA;EACE,oDAAA;EACA,sDAAA;EACA,wDAAA;EACA,0DAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.publication-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.publication-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}\n",".publication-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.publication-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

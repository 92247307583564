<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_COLLABORATING_PUBLICATIONS"
    title="Collaborating Publications"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Collaborating Publications"
    align="left"
    width="full"
  >
    <iRow class="collab-pubs-wrapper">
      <PublicationCard v-for="(publication, index) in publications" :key="index" :publication="publication" />
    </iRow>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { PAGE_COLLABORATING_PUBLICATIONS, PAGE_MY_PUBLICATIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import PublicationCard from "@/components/PublicationCard";
import PageWrapper from "@/components/PageWrapper";

import { usePublicationStore } from "@/stores/publication-store";

export default {
  name: "CollaboratingPublications",
  components: { PublicationCard, PageWrapper },
  data() {
    return {
      PAGE_MY_PUBLICATIONS,
      SECTION_PUBLICATIONS,
      PAGE_COLLABORATING_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId"]),
    ...mapState(usePublicationStore, { publications: "collaboratingPublications" }),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
      ];
    },
  },
};
</script>

<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_COLLABORATORS()"
    :active-section="SECTION_PUBLICATIONS()"
    title="Publication Collaborators"
    sub-title="Manage your publication's team"
    :breadcrumbs="[
      {url: `/publication/org/${getOrgData.org_id}`, label: 'Publications'},
    ]"
    page-name="Publication Collaborators"
    align="center"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iCard :style-overrides="noBorder">
        <template #body>
          <iRow vertical-align="middle" gap="large">
            <iToggleSwitch
              :model-value="isApplicationsEnabled"
              :true-value="true"
              :false-value="false"
              label="Enable Applications"
              @update:model-value="toggleApplications"
            />
          </iRow>
        </template>
      </iCard>
      <iRow wrap="nowrap">
        <iColumn width="hug">
          <iButton @click="openInviteToPublicationModel">
            Invite New Collaborator
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton @click="showAdvertisementModal = true">
            Advertise for Collaborators
          </iButton>
        </iColumn>
      </iRow>

      <iColumn gap="standard">
        <iSubHeading variant="subtle">
          Collaborators
        </iSubHeading>
        <iRow wrap="wrap" gap="large">
          <iText v-if="collaborators.length === 0">
            No collaborators
          </iText>
          <PublicationCollaboratorCard
            v-for="collaborator in collaborators"
            :key="collaborator.applicant_id"
            :collaborator="formatCollaborator(collaborator)"
            :show-actions="false"
          />
        </iRow>
      </iColumn>

      <iColumn gap="standard">
        <iSubHeading variant="subtle">
          Applications
        </iSubHeading>
        <iRow wrap="wrap" gap="large">
          <iText v-if="activeCollaboratorApplications.length === 0">
            No applications
          </iText>
          <PublicationCollaboratorCard
            v-for="application in activeCollaboratorApplications"
            :key="application.publication_applicant_id"
            :collaborator="mapApplicationToCollaborator(application)"
            :show-actions="true"
            is-publication-owner
            @accept="handleAcceptApplication(application.publication_applicant_id)"
            @deny="handleDenyApplication(application.publication_applicant_id)"
            @revoke="handleRevokeApplication(application.publication_applicant_id)"
          />
        </iRow>
      </iColumn>

      <iColumn gap="standard">
        <iSubHeading variant="subtle">
          Invitations
        </iSubHeading>
        <iRow wrap="wrap" gap="large">
          <iText v-if="collaboratorInvitations.length === 0">
            No invitations
          </iText>
          <PublicationCollaboratorCard
            v-for="invitation in collaboratorInvitations"
            :key="invitation.publication_applicant_id"
            :collaborator="mapInvitationToCollaborator(invitation)"
            :show-actions="true"
            :is-invitation="true"
            is-publication-owner
            @accept="handleAcceptInvitation(invitation.publication_id, invitation.org_id)"
            @deny="handleDenyInvitation(invitation.publication_id, invitation.org_id)"
            @revoke="handleRevokeInvitation(invitation.publication_id, invitation.org_id)"
          />
        </iRow>
      </iColumn>

      <InviteToPublication :org-name="getOrgData.name" :org-id="getOrgData.org_id" />
      <iModal
        :visible="showAdvertisementModal"
        :width="600"
        title="Create Advertisement"
        @close="closeAdvertisementModal"
        @click:primary="createAdvertisement"
        @click:secondary="closeAdvertisementModal"
      >
        <template #body>
          <iSelect
            v-model="advertiseRole"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
          />
          <iTextArea
            v-model="advertiseDescription"
            label="Description"
            placeholder="Enter role description"
            :rows="5"
            width="fill"
          />
        </template>
      </iModal>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_PUBLICATION_COLLABORATORS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { usePublicationStore } from "@/stores/publication-store";
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";

export default {
  name: "PublicationCollaborators",
  components: {
    PublicationCollaboratorCard,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showAdvertisementModal: false,
      inviteEmail: "",
      advertiseRole: "",
      advertiseDescription: "",
      ROLE_OPTIONS,
    };
  },
  computed: {
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(usePublicationStore, [
      "publication",
    ]),
    ...mapState(useCollaborationStore, [
      "collaboratorApplications",
      "collaborators",
      "collaboratorInvitations",
      "publicationApplicationsLoadingState",
      "lastPublicationApplicationId",
      "activeCollaboratorApplications",
    ]),
    noBorder() {
      return {
        borderWidth: "none",
        paddingLeft: "none",
      };
    },
    isApplicationsEnabled() {
      return this.publication.enable_applications === "enabled";
    },
  },
  methods: {
    PAGE_PUBLICATION_COLLABORATORS() {
      return PAGE_PUBLICATION_COLLABORATORS;
    },
    SECTION_PUBLICATIONS() {
      return SECTION_PUBLICATIONS;
    },
    ...mapActions(usePublicationStore, [
      "fetchApplicationsForPublication",
      "enableApplications",
      "disableApplications",
    ]),
    ...mapActions(useCollaborationStore, [
      "acceptCollaboratorApplication",
      "declineCollaboratorApplication",
      "revokeCollaboratorApplication",
      "acceptCollaboratorInvitation",
      "declineCollaboratorInvitation",
      "revokeCollaboratorInvitation",
      "openInviteToPublicationModel",
    ]),
    closeAdvertisementModal() {
      this.showAdvertisementModal = false;
      this.advertiseRole = "";
      this.advertiseDescription = "";
    },
    createAdvertisement() {
      // TODO add this functionality
    },
    mapApplicationToCollaborator(application) {
      return {
        id: application.publication_applicant_id,
        name: application.name,
        role: "Applicant",
        status: application.status,
        email: application.email,
        avatar: application.avatar || "",
        orgId: application.org_id,
      };
    },
    mapInvitationToCollaborator(invitation) {
      return {
        id: invitation.publication_applicant_id,
        name: invitation.name,
        role: invitation.role,
        status: invitation.status,
        email: invitation.email,
        avatar: invitation.avatar || "",
        orgId: invitation.org_id,
      };
    },
    formatCollaborator(collaborator) {
      return {
        id: collaborator.applicant_id,
        name: collaborator.name,
        role: collaborator.role,
        status: collaborator.status,
        email: collaborator.email,
        avatar: collaborator.avatar || "",
        orgId: collaborator.org_id,
      };
    },
    async handleAcceptApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.acceptCollaboratorApplication(this.publicationId, application.org_id, application.publication_applicant_id);
    },
    async handleDenyApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.declineCollaboratorApplication(this.publicationId, application.org_id, application.publication_applicant_id);
    },
    async handleRevokeApplication(applicantId) {
      const application = this.getCollaboratorApplication(applicantId);
      await this.revokeCollaboratorApplication(this.publicationId, application.org_id, application.publication_applicant_id);
    },
    async handleAcceptInvitation(publicationId, orgId) {
      await this.acceptCollaboratorInvitation(publicationId, orgId);
    },
    async handleDenyInvitation(publicationId, orgId) {
      await this.declineCollaboratorInvitation(publicationId, orgId);
    },
    async handleRevokeInvitation(publicationId, orgId) {
      await this.revokeCollaboratorInvitation(publicationId, orgId);
    },
    toggleApplications(value) {
      if (value) {
        this.enableApplications(this.publicationId);
      } else {
        this.disableApplications(this.publicationId);
      }
    },
    getCollaboratorApplication(applicantId) {
      return this.collaboratorApplications.find(application => application.publication_applicant_id === applicantId);
    },
  },
};
</script>

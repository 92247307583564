// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-49e113ec] .modal-body {
  height: 50vh;
  overflow-y: auto;
}
.chip-container[data-v-49e113ec] {
  border-radius: var(--49e113ec-\\\$getStyles\\.chipBorderRadius);
  border: 1px solid var(--49e113ec-\\\$getStyles\\.chipBorderColor);
  padding-inline: var(--49e113ec-\\\$getStyles\\.chipPaddingHorizontal);
  padding-block: var(--49e113ec-\\\$getStyles\\.chipPaddingVertical);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/TemplateDetails.vue","webpack://./TemplateDetails.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,gBAAA;ACDF;ADIA;EACE,4DAAA;EACA,8DAAA;EACA,kEAAA;EACA,+DAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.modal-body) {\n  height: 50vh;\n  overflow-y: auto;\n}\n\n.chip-container {\n  border-radius: v-bind('$getStyles.chipBorderRadius');\n  border: 1px solid v-bind('$getStyles.chipBorderColor');\n  padding-inline: v-bind('$getStyles.chipPaddingHorizontal');\n  padding-block: v-bind('$getStyles.chipPaddingVertical');\n}\n",":deep(.modal-body) {\n  height: 50vh;\n  overflow-y: auto;\n}\n\n.chip-container {\n  border-radius: v-bind(\"$getStyles.chipBorderRadius\");\n  border: 1px solid v-bind(\"$getStyles.chipBorderColor\");\n  padding-inline: v-bind(\"$getStyles.chipPaddingHorizontal\");\n  padding-block: v-bind(\"$getStyles.chipPaddingVertical\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

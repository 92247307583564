import AffiliateLinkCardLarge from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardLarge";
import AffiliateLinkCardSmall from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardSmall";
import AffiliateLinkListItem from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkListItem";
import AffiliateLinkTableRow from "@/components/smart-feed/affiliate-link/layouts/AffiliateLinkTableRow";
import OrgCardSmall from "@/components/smart-feed/org/layouts/OrgCardSmall";
import UserTableRow from "@/components/smart-feed/user/layouts/UserTableRow";
import { useAffiliateLinkEntityStore } from "@/stores/smart-feed/entity/affiliate-link-entity-store";
import { usePublicationEntityStore } from "@/stores/smart-feed/entity/publication-entity-store";
import { useCommunityPublicationEntityStore } from "@/stores/smart-feed/entity/community-publication-entity-store";
import PublicationCardSmall from "@/components/smart-feed/publication/layouts/PublicationCardSmall";
import CommunityPublicationCardSmall from "@/components/smart-feed/publication/layouts/CommunityPublicationCardSmall";
import { useOrgEntityStore } from "@/stores/smart-feed/entity/org-entity-store";
import { useUserEntityStore } from "@/stores/smart-feed/entity/user-entity-store";
import { BaseSmartFeedEngine } from "@bloglovin/vue-framework";
import {useMyPostEntityStore} from "@/stores/smart-feed/entity/my-post-entity-store";
import MyPostTableRow from "@/components/smart-feed/my-post/layouts/MyPostTableRow";

const CreatorSmartFeedEngine = class extends BaseSmartFeedEngine {
  static AFFILIATE_LINK = "affiliateLink";
  static PUBLICATION = "publication";
  static COMMUNITY_PUBLICATION = "community_publication";
  static ORG = "org";
  static USER = "user";
  static MY_POST = 'myPost';

  config = {
    [CreatorSmartFeedEngine.AFFILIATE_LINK]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useAffiliateLinkEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: AffiliateLinkCardSmall,
        [CreatorSmartFeedEngine.LAYOUT_CARD_LARGE]: AffiliateLinkCardLarge,
        [CreatorSmartFeedEngine.LAYOUT_LIST]: AffiliateLinkListItem,
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: AffiliateLinkTableRow,
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: usePublicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: PublicationCardSmall,
      },
    },
    [CreatorSmartFeedEngine.COMMUNITY_PUBLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useCommunityPublicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: CommunityPublicationCardSmall,
      },
    },
    [CreatorSmartFeedEngine.ORG]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useOrgEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: OrgCardSmall,
      },
    },
    [CreatorSmartFeedEngine.USER]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useUserEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: UserTableRow,
      },
    },
    [CreatorSmartFeedEngine.MY_POST]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useMyPostEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: MyPostTableRow,
      },
    },
  };
};

export default CreatorSmartFeedEngine;

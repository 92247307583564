<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_COMMENTS"
    title="Configure Comments"
    :breadcrumbs="breadcrumbs"
    page-name="Comments"
    align="left"
    width="full"
  >
    <iText v-if="!isSignupAndLoginEnabled" variant="subtle">
      Login and Signup Must be enabled to enable commenting
    </iText>
    <iColumn v-else>
      <iText>
        Your publications' posts can have comments, and those can have different interactions.
        Choose which ones of the interactions you want your fans and collaborators to use on the posts.
      </iText>
      <iRow vertical-align="middle">
        <iToggleSwitch v-model="config.allowsComments" label="Allow commenting on posts" />
      </iRow>
      <iColumn v-if="config.enabled">
        <iRow vertical-align="middle">
          <iToggleSwitch v-model="config.allowsReply" label="Allow replies on posts" />
        </iRow>
        <iRow vertical-align="middle">
          <iToggleSwitch v-model="config.allowsLikes" label="Allow likes on posts" />
        </iRow>
        <iRow vertical-align="middle">
          <iToggleSwitch v-model="config.allowsUpvoteAndDownvote" label="Allow upvotes & downvotes on posts" />
        </iRow>
        <iRow vertical-align="middle">
          <iToggleSwitch v-model="config.allowsEditing" label="Allow editing on posts" />
        </iRow>
      </iColumn>
    </iColumn>
    <iButton :is-loading="isLoadingConfig" loading-spinner-position="inside" @click="savePublicationConfig">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_COMMENTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { PUBLICATION_COMMENTS_DEFAULT_CONFIG } from "@/constants/publication-constants";
const SORTING_ORDERS = [
  "Upvotes Descending",
  "Upvotes Ascending",
  "Hearts Ascending",
  "Hearts Descending",
];
import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationComments",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_COMMENTS,
      PUBLICATION_COMMENTS_DEFAULT_CONFIG,

    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId", "configuration", "isLoadingConfig", "isSignupAndLoginEnabled"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
    sortingOrders() {
      return SORTING_ORDERS.map(order => ({ name: order }));
    },
  },
  created() {
    this.config = { ...PUBLICATION_COMMENTS_DEFAULT_CONFIG, ...this.configuration.community };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("community", this.config);
    },
  },
};
</script>

<style scoped>

</style>

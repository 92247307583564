<template>
  <iRow wrap="nowrap" class="version-card" vertical-align="middle">
    <iColumn class="version-bubble" :height="50" :width="50">
      <iText variant="accent">
        {{ version }}
      </iText>
    </iColumn>
    <iColumn>
      <iRow>
        <iTitle>{{ memo }}</iTitle>
      </iRow>
      <iRow>
        <iText variant="subtle">
          {{ getRelativeTimeString(dateCreated) }}
        </iText>
      </iRow>
    </iColumn>
    <iColumn width="hug">
      <iDropdown :show-dropdown="isDropdownOpen" position="right" @close="closeDropdown">
        <template #trigger>
          <iIcon icon="dots-vertical" @click="openDropdown" />
        </template>
        <template #overlay>
          <iLink v-if="staging" :href="`/my-publication/staging/${publicationId}/`" target="_blank">
            <iButton variant="secondary">
              View Staging
            </iButton>
          </iLink>
          <iLink v-else :href="`/my-publication/${publicationId}/`" target="_blank">
            <iButton variant="secondary">
              View Live
            </iButton>
          </iLink>
        </template>
      </iDropdown>
    </iColumn>
  </iRow>
</template>

<script>
import { getRelativeTimeString } from "@bloglovin/vue-component-library";
import { usePublicationStore } from "@/stores/publication-store";
import { mapState } from "pinia";

export default {
  name: "PublicationVersionCard",
  props: {
    version: {
      type: String,
      required: true,
    },
    dateCreated: {
      type: Number,
      required: true,
    },
    memo: {
      type: String,
      required: true,
    },
    publicationVersionId: {
      type: Number,
      required: true,
    },
    staging: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close-dropdown", "open-dropdown"],
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publicationId"]),
    urlToVersionConfig() {
      return `/publication/${this.publicationId}/features?publication_version_id=${this.publicationVersionId}`;
    },
  },
  methods: {
    getRelativeTimeString,
    openDropdown() {
      this.isDropdownOpen = true;
      this.$emit("open-dropdown", this.publicationVersionId);
    },
    closeDropdown() {
      this.isDropdownOpen = false;
      this.$emit("close-dropdown", this.publicationVersionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.version{
  &-bubble{
    aspect-ratio: 1;
    background-color: lightgrey;
    border-radius: 50%;
    font-size: 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i-card[data-v-18b6aee2] {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/PublicationCollaboratorsCard.vue","webpack://./PublicationCollaboratorsCard.vue"],"names":[],"mappings":"AAEA;EACE,iBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.i-card {\n  overflow: visible;\n}\n",".i-card {\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <PageWrapper :active-page="PAGE_PENDING_EARNINGS" :active-section="SECTION_DIGITAL_WALLET">
    <iInfiniteScroll :has-more="hasMorePendingEarnings" :is-loading="pendingEarningsIsLoading" @load-more="loadMore">
      <iDataTable :fields="tableFields" :items="pendingEarnings">
        <template #emptyState>
          No pending earnings data available.
        </template>
      </iDataTable>
      <template #loading>
        <iLoadingSpinner />
      </template>
    </iInfiniteScroll>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { PAGE_PENDING_EARNINGS, SECTION_DIGITAL_WALLET } from "@/constants/nav-constants";
import { usePendingEarningsStore } from "@/stores/pending-earnings-store";

export default {
  name: "PendingEarnings",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_DIGITAL_WALLET,
      PAGE_PENDING_EARNINGS,
      tableFields: [
        { key: "brand_id", label: "Brand ID" },
        { key: "name", label: "Brand Name" },
        { key: "pending_earnings", label: "Pending Earnings" },
      ],
    };
  },
  computed: {
    ...mapState(usePendingEarningsStore, [
      "pendingEarnings",
      "pendingEarningsIsLoading",
      "hasMorePendingEarnings",
    ]),
  },
  created() {
    this.resetPendingEarnings();
  },
  methods: {
    ...mapActions(usePendingEarningsStore, [
      "fetchMorePendingEarnings",
      "resetPendingEarnings",
    ]),
    loadMore() {
      if (!this.pendingEarningsIsLoading && this.hasMorePendingEarnings) {
        this.fetchMorePendingEarnings();
      }
    },
  },
};
</script>

<template>
  <iSpace :width="30" />
  <iCard class="card">
    <template #body>
      <NewReply
        context="reply"
        :entity-id="messageId"
        :conversation-id="conversationId"
      />
      <iRow
        v-for="(reply, index) in replies"
        :key="index"
        :class="{deleted: reply.status === 'inactive'}"
        width="fill"
      >
        <ConversationHeader
          :date="reply.timestampCreated"
          :author-name="reply.authorName"
          :author-avatar="message.avatarUrl"
          :author-id="reply.userId"
        />
        <MessageFlags
          :status="reply.status"
          :moderation-status="reply.moderationStatus"
          :timestamp-updated="reply.timestampUpdated"
        />
        <iRow>
          <iColumn v-if="isEditing && editingId === reply.messageId">
            <iTextInput
              v-model="newCommentReply"
              name="editComment"
            />
            <iRow align="between">
              <iColumn width="hug">
                <iButton @click="saveEdit">
                  Save
                </iButton>
              </iColumn>
              <iColumn width="hug">
                <iButton @click="cancelEditing">
                  Cancel
                </iButton>
              </iColumn>
            </iRow>
          </iColumn>
          <iColumn v-else>
            <iText>
              {{ reply.message }}
            </iText>
          </iColumn>
        </iRow>
        <iColumn>
          <iText v-if="reply.userId == userId && reply.moderationStatus == 'unknown'" variant="subtle">
            Not visible, pending moderation.
          </iText>
          <iText v-if="reply.userId == userId && reply.moderationStatus == 'blocked'" variant="subtle">
            This message has been blocked.
          </iText>
          <iText v-if="errorMessage" variant="subtle">
            {{ errorMessage }}
          </iText>
          <iRow v-if="isModerator && moderationEnabled" align="between">
            <iText variant="accent">
              Report Count: {{ reply.numReports }}
            </iText>
            <iText variant="accent">
              moderation score: {{ reply.moderationScore }}
            </iText>
          </iRow>
        </iColumn>
        <ConversationFooter
          :current-message="reply.message"
          :author-id="reply.userId"
          :message-id="reply.messageId"
          :vote-score="reply.voteScore"
          :num-replies="reply.numApprovedReplies"
          :num-likes="reply.numLikes"
          :moderation-status="reply.moderationStatus"
          :status="reply.status"
          :user-liked="reply.userLiked"
          :user-up-voted="reply.userUpVoted"
          :user-down-voted="reply.userDownVoted"
          :allow-replying="false"
          @start-editing="turnOnIsEditing"
          @report="report"
          @approve="approve"
          @escalate="escalate"
          @block="block"
          @delete="deleteComment"
          @undelete="unDeleteComment"
          @like="like"
          @unlike="unlike"
          @upvote="upVote"
          @revokeupvote="revokeUpVote"
          @downvote="downVote"
          @revokedownvote="revokeDownVote"
        />
      </iRow>
    </template>
  </iCard>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import ConversationFooter from "@/components/conversations/ConversationFooter";
import ConversationHeader from "@/components/conversations/ConversationHeader";
import MessageFlags from "@/components/conversations/MessageFlags";
import NewReply from "@/components/conversations/NewReply";

export default {
  name: "ReplyWidget",
  components: {
    ConversationHeader,
    ConversationFooter,
    MessageFlags,
    NewReply,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editingId: 0,
      isReplying: false,
      newCommentReply: "",
      showMenuOptions: false,
      showReportOptions: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userId"]),
    ...mapState(useConversationStore, ["errorMessage", "isModerator", "moderationEnabled"]),
    isAuthor() {
      return this.userId === this.userId;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      submitSorting: "submitSorting",
      upVoteMessage: "upVoteMessage",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      downVoteMessage: "downVoteMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      unLikeMessage: "unLikeMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
    }),
    turnOnIsEditing(messageId, replyMessage) {
      this.newCommentReply = replyMessage;
      this.editingId = messageId;
      this.isEditing = true;
    },
    turnOnReply() {
      this.fetchRepliesToMessage(this.messageId);
      this.isReplying = true;
    },
    toggleCommentMenu() {
      this.showMenuOptions = true;
    },
    showReportsMenu() {
      this.showMenuOptions = false;
      this.showReportOptions = true;
    },
    saveEdit() {
      this.isEditing = false;
      this.editMessage(this.editingId, this.newCommentReply);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment(id) {
      this.deleteMessage(id);
      this.showMenuOptions = false;
    },
    unDeleteComment(id) {
      this.undeleteMessage(id);
      this.showMenuOptions = false;
    },
    report(reportData) {
      this.showReportOptions = false;
      this.reportMessage(reportData.id, reportData.reason);
    },
    messageReply(newMessage) {
      this.replyToMessage(this.messageId, newMessage);
    },
    like(id) {
      this.likeMessage(id);
    },
    downVote(id) {
      this.downVoteMessage(id);
    },
    upVote(id) {
      this.upVoteMessage(id);
    },
    block(blockData) {
      this.blockMessage(blockData.id, blockData.reason);
    },
    approve(id) {
      this.approveMessage(id);
    },
    escalate(id) {
      this.escalateMessage(id);
    },
    revokeUpVote() {
      this.revokeUpVoteMessage(this.messageId);
    },
    unlike() {
      this.unLikeMessage(this.messageId);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  overflow: visible;
}
.deleted {
  background-color: #bdbdbd;
}
</style>

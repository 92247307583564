// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-32430a60] {
  margin: 0 5px;
  width: 100%;
}
.chatroom-thread-container[data-v-32430a60] {
  border-top: var(--32430a60-\\\$getStyles\\.borderTopWidth) solid var(--32430a60-\\\$getStyles\\.borderTopColor);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/Chatroom.vue","webpack://./Chatroom.vue"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,WAAA;ACDJ;ADGE;EACE,yGAAA;ACAJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .chatroom {\n    margin: 0 5px;\n    width: 100%;\n  }\n  .chatroom-thread-container {\n    border-top: v-bind('$getStyles.borderTopWidth') solid v-bind('$getStyles.borderTopColor');\n\n  }\n",".chatroom {\n  margin: 0 5px;\n  width: 100%;\n}\n\n.chatroom-thread-container {\n  border-top: v-bind(\"$getStyles.borderTopWidth\") solid v-bind(\"$getStyles.borderTopColor\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

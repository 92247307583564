<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_THEME_PALETTES" title="Theme Palettes">
    <iColumn width="fill" wrap="nowrap">
      <iButton @click="goToCreateThemePalette">
        Create Theme Palette
      </iButton>
      <iSpace :height="10" />
      <iInfiniteScroll
        :height-offset="290"
        :has-more="hasMorePalettes"
        :is-loading="isLoadingThemePalettes"
        @load-more="loadMore"
      >
        <iRow>
          <iStyledCard
            v-for="(palette, index) in themePalettes"
            :key="index"
            :width="300"
            :title="palette.name"
            :subtitle="getChangesStatus(palette)"
            :default-href="`/publication/org/${orgId}/theme-palettes/${palette.theme_palette_id}`"
            :tags="[
              {
                name: camelToTitleCase(palette.visibility || 'private'),
              },
              {
                name: getIsCloneText(palette),
              },
            ]"
          >
            <template #splash-container>
              <iRow gap="none">
                <div
                  v-for="(color, idx) in Object.keys(palette.configuration.colors)
                    .filter(colorKey => colorKey !== 'transparent')"
                  :key="idx"
                  :style="{
                    backgroundColor: palette.configuration.colors[color]['400'],
                    flex: 1,
                    height: '150px',
                    overflow: 'hidden'
                  }"
                />
              </iRow>
            </template>
            <template #footer>
              <iRow wrap="nowrap" vertical-align="middle" align="between">
                <iLink width="fill" :href="`/publication/org/${orgId}/theme-palettes/${palette.theme_palette_id}/clone`">
                  <iButton width="fill" variant="secondary">
                    Clone
                  </iButton>
                </iLink>
                <iLink width="fill" :href="`/publication/org/${orgId}/theme-palettes/${palette.theme_palette_id}`">
                  <iButton width="fill" variant="secondary">
                    Edit
                  </iButton>
                </iLink>
              </iRow>
            </template>
          </iStyledCard>
        </iRow>
      </iInfiniteScroll>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_PUBLICATIONS, PAGE_THEME_PALETTES } from "@/constants/nav-constants";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";
import { camelToTitleCase } from "@bloglovin/vue-framework";
import { StatusEnum } from "@bloglovin/vue-component-library";

export default {
  name: "ThemePalettes",
  components: { PageWrapper },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEME_PALETTES,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapWritableState(useThemesStore, [
      "themePalettes",
      "isLoadingThemePalettes",
      "hasMorePalettes",
    ]),
  },
  methods: {
    camelToTitleCase,
    ...mapActions(useThemesStore, [
      "fetchThemePalettes",
    ]),
    loadMore() {
      if (this.hasMoreThemePalettes && !this.isLoadingThemePalettes) {
        this.fetchThemePalettes();
      }
    },
    goToCreateThemePalette() {
      document.location.href = `/publication/org/${this.orgId}/theme-palettes/create`;
    },
    getChangesText(palette) {
      let content = "Has staged changes";
      if (palette.production_version_id === palette.staging_version_id) {
        content = "Up to date with Production";
      }
      return content;
    },
    getIsCloneText(palette) {
      let content = "Clone";
      if (palette.original_theme_palette_id === 0) {
        content = "Original";
      }
      return content;
    },
    getChangesStatus(palette) {
      let status = StatusEnum.ACTIVE;
      if (palette.production_version_id !== palette.staging_version_id) {
        status = StatusEnum.PENDING;
      }
      return status;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.infinite-scroll-container) {
  width: 100%;
}

:deep(.i-card-header.flex-box){
  padding-inline: unset;
}

.tag-style {
  border-radius: 5px;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
}
</style>

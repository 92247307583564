<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <iColumn :width="600" overflow="visible">
      <input
        id="title"
        v-model="title"
        class="native-post-title"
        type="text"
        name="title"
        placeholder="Title"
      >

      <iColumn class="native-post-content-container">
        <input
          id="file"
          ref="file"
          type="file"
          hidden
          @change="uploadFunction"
        >

        <selection-bubble
          v-if="editor"
          :editor="editor"
          :should-show="shouldShow"
          :upload-post-image-loading="uploadPostImageLoading"
          @select-file="selectFile"
          @toggle-bold="toggleBold"
          @toggle-italic="toggleItalic"
          @toggle-link="toggleLink"
        />
        <editor-content class="editor-container" :editor="editor" />
      </iColumn>
      <iRow>
        <iTitle variant="subtle">
          Hashtags
        </iTitle>
        <!-- <template v-for="(hashtag, index) in hashtags" :key="hashtag">
          <iChip @click:icon="removeHashtag(index)">
            {{ hashtag }}
          </iChip>
        </template> -->
      </iRow>
      <iColumn>
        <iRow>
          <iColumn width="hug">
            <iTextInput
              v-model="hashtagString"
              :disabled="true"
              placeholder="#clothing"
            />
          </iColumn>
          <iColumn width="hug">
            <iButton :disabled="true">
              Add
            </iButton>
          </iColumn>
        </iRow>
        <iRow>
          <iText variant="subtle">
            You must first save the post before you can add tags.
          </iText>
        </iRow>
        <!-- <iTextEdit
          value=""
          label="Hashtags"
          :width="400"
          clear-value-on-submit
          clear-value-on-cancel
          @submit="addHashtag"
          @enter="addHashtag"
          @input="checkHasDuplicateHashTags"
        />
        <iText v-if="hasDuplicateHashtags" variant="error">
          Some hashtags already exists on the post. Only unique hashtags will be added.
        </iText> -->
      </iColumn>
      <iColumn>
        <iSubHeading>
          Configuration
        </iSubHeading>
        <iRow vertical-align="middle">
          <iCheckbox
            v-model="exclusive"
            :disabled="!canMarkContentAsExclusive"
            label="Mark content as exclusive"
          />
          <iColumn v-tooltip="exclusiveCheckboxTooltip" width="hug">
            <iIcon icon="info-circle" />
          </iColumn>
        </iRow>
      </iColumn>
      <iSpace :height="20" />
      <iRow>
        <iColumn width="hug">
          <iButton variant="secondary" @click="cancelPost">
            Cancel
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            variant="primary"
            :disabled="updatePostIsLoading"
            :is-loading="updatePostIsLoading"
            @click="submitPost"
          >
            Save Draft
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { Editor, isTextSelection, EditorContent } from "@tiptap/vue-3";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { usePublicationStore } from "@/stores/publication-store";
import { mapActions, mapState } from "pinia";
import SelectionBubble from "@/components/post-editor/SelectionBubble.vue";
import CustomImage from "@/extensions/post-editor/custom-image-extension";
import { MoveNodeUp } from "@/extensions/post-editor/move-up";
import { MoveNodeDown } from "@/extensions/post-editor/move-down";

export default {
  name: "CreatePublicationPost",
  components: {
    PageWrapper,
    EditorContent,
    SelectionBubble,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      title: "",
      hasDuplicateHashtags: false,
      hashtagString: "",
      // tiptap
      editor: null,
      model: "",
      hashtags: [],
      exclusive: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publicationId",
      "updatePostIsLoading",
      "uploadPostImageLoading",
      "canMarkContentAsExclusive",
    ]),
    exclusiveCheckboxTooltip() {
      if (this.canMarkContentAsExclusive) {
        return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
      }
      return "Subscriptions are disabled for this publication";
    },
  },
  mounted() {
    this.editor = new Editor({
      content: "",
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: "Write something amazing..." }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: "https",
        }),
        CustomImage,
        MoveNodeUp,
        MoveNodeDown,
      ],
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    ...mapActions(usePublicationStore, [
      "uploadPostImage",
      "createPublicationPost",
    ]),
    addHashtag() {
      let hashtags = this.hashtagString.trim().replaceAll(/([^\w\s])+/g, "").split(" ");
      this.hashtags = [...new Set([...this.hashtags, ...hashtags])];
    },
    removeHashtag(hashtagIndex) {
      this.hashtags.splice(hashtagIndex, 1);
    },
    checkHasDuplicateHashTags(value) {
      const hashtags = value?.trim().replaceAll(/([^\w\s])+/g, "").split(" ");
      let hasDuplicate = false;
      hashtags.forEach(hashtag => {
        if (this.hashtags.find(h => h === hashtag)) {
          hasDuplicate = true;
        }
      });
      this.hasDuplicateHashtags = hasDuplicate;
    },
    // bubble menu
    shouldShow() {
      return ({ view, state, from, to }) => {
        // only show the bubble menu for images and links
        const { doc, selection } = state;
        const { empty } = selection;

        // Sometime check for `empty` is not enough.
        // Doubleclick an empty paragraph returns a node size of 2.
        // So we check also for an empty text size.
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (!view.hasFocus() || empty || isEmptyTextBlock) {
          return false;
        }

        return true;
      };
    },

    // text commands
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      let url;
      if (!previousUrl) {
        url = window.prompt("URL", previousUrl);
      }

      // cancelled
      if (url === null) {
        return;
      }

      this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .toggleLink({ href: url, target: "_blank" })
          .run();
    },

    // image upload
    selectFile() {
      document.getElementById("file").click();
    },
    async uploadFunction() {
      this.selectedFile = this.$refs.file.files[0];

      const imageUrl = await this.uploadPostImage(this.selectedFile);

      // insert the image
      this.editor.chain().focus().setImage({ src: imageUrl }).run();

      // reset the file input
      this.$refs.file.value = "";
    },

    cancelPost() {
      window.location.href = `/publication/${this.publicationId}/posts`;
    },
    submitPost() {
      this.createPublicationPost(this.title, this.editor.getHTML(), this.hashtags, this.exclusive);
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-valid[data-v-e7e3ac72] {
  color: green;
}
.form-invalid[data-v-e7e3ac72] {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/PasswordComplexity.vue","webpack://./PasswordComplexity.vue"],"names":[],"mappings":"AAEE;EACE,YAAA;ACDJ;ADGE;EACE,UAAA;ACAJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .form-valid {\n    color: green;\n  }\n  .form-invalid {\n    color: red;\n  }\n",".form-valid {\n  color: green;\n}\n\n.form-invalid {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

export const ROLE_CONTRIBUTOR = "contributor";
export const ROLE_MARKETER = "marketer";
export const ROLE_EDITOR = "editor";
export const ROLE_PRODUCER = "producer";

export const ROLE_OPTIONS = [
  { name: "Contributor", value: "contributor" },
  { name: "Editor", value: "editor" },
  { name: "Marketer", value: "marketer" },
  { name: "Producer", value: "producer" },
];

export const STATUS_MAP = {
  applied: "Applied",
  invited: "Invited",
  accepted: "Accepted",
  invitation_accepted: "Invitation Accepted",
  invitation_revoked: "Invitation Revoked",
  invitation_declined: "Invitation Declined",
  application_accepted: "Application Accepted",
  application_revoked: "Application Revoked",
  application_declined: "Application Declined",
  denied: "Denied",
  removed: "Removed",
  active: "Active",
};

export const HIDE_ACTION_STATUSES = [
  "accepted",
  "application_revoked",
  "application_declined",
  "invitation_accepted",
  "invitation_declined",
  "application_accepted",
  "invitation_revoked",
];

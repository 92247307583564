<template>
  <iModal
    v-if="inviteToPublicationModalState"
    :title="onPlatform ? orgName : 'Invite to Publication'"
    :width="600"
    @close="closeInvitationModalAndReset"
  >
    <template #body>
      <iColumn gap="large">
        <template v-if="onPlatform">
          <iSelect
            v-model="inviteToPublicationFormData.onPlatform.selectedRole"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
          />
        </template>
        <template v-else>
          <iTextInput
            v-model="inviteToPublicationFormData.offPlatform.inviteName"
            label="Name"
            placeholder="Enter invitee's name"
            width="fill"
          />
          <iTextInput
            v-model="inviteToPublicationFormData.offPlatform.inviteEmail"
            label="Email"
            placeholder="Enter invitee's email"
            width="fill"
          />
          <iText v-if="inviteToPublicationFormErrors.offPlatform.inviteEmailError" variant="error">
            {{ inviteToPublicationFormErrors.offPlatform.inviteEmailError }}
          </iText>
          <iSelect
            v-model="inviteToPublicationFormData.offPlatform.selectedRole"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
          />
        </template>
        <iSelect
          v-if="publicationId === 0"
          v-model="currentFormData.selectedPublication"
          :items="publicationOptions"
          label="Publication"
          width="fill"
        />
        <iTextArea
          v-model="currentFormData.inviteMessage"
          label="Invitation Message"
          placeholder="Enter your invitation message here..."
          :rows="5"
          width="fill"
        />
        <iText v-if="invitationError" variant="error">
          {{ invitationError }}
        </iText>
      </iColumn>
    </template>
    <template #footer>
      <iRow align="between" gap="standard" width="fill">
        <iColumn width="hug">
          <iButton variant="secondary" @click="closeInvitationModalAndReset">
            Cancel
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            variant="primary"
            loading-spinner-position="inside"
            :disabled="isDisabled"
            :is-loading="inviteToCollaborateLoadingState"
            @click="sendInvitation"
          >
            Send Invitation
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { validateEmail } from "@bloglovin/vue-component-library";

export default {
  name: "InviteToPublication",
  props: {
    onPlatform: {
      type: Boolean,
      default: false,
    },
    orgName: {
      type: String,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ROLE_OPTIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publicationId",
      "myPublications",
    ]),
    ...mapState(useCollaborationStore, [
      "inviteToPublicationModalState",
      "inviteToCollaborateLoadingState",
      "errors",
      "inviteToPublicationFormData",
      "inviteToPublicationFormErrors",
    ]),
    ...mapState(useOrganizationStore, [
      "orgProfileData",
      "assertOrgExists",
      "getOrgData",
    ]),
    currentFormData() {
      return this.onPlatform
        ? this.inviteToPublicationFormData.onPlatform
        : this.inviteToPublicationFormData.offPlatform;
    },
    isFormValid() {
      const formData = this.currentFormData;
      if (this.onPlatform) {
        return formData.selectedRole && formData.inviteMessage && (this.publicationId !== 0 || formData.selectedPublication);
      } else {
        return formData.inviteName &&
            formData.inviteEmail &&
            formData.selectedRole &&
            formData.inviteMessage &&
            (this.publicationId !== 0 || formData.selectedPublication);
      }
    },
    publicationOptions() {
      return this.myPublications.map(pub => ({
        name: pub.name,
        value: pub.publication_id,
      }));
    },
    invitationError() {
      return this.errors.invitationActionError;
    },
    isDisabled() {
      return !this.isFormValid || this.inviteToCollaborateLoadingState;
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "closeInvitationModalAndReset",
      "inviteCollaboratorToPublication",
      "inviteOffPlatformCollaboratorToPublication",
    ]),
    async sendInvitation() {
      if (!this.onPlatform && !this.validateEmail()) {
        return;
      }
      if (!this.isFormValid) {
        return;
      }
      const formData = this.currentFormData;
      const targetPublicationId = this.publicationId !== 0 ? this.publicationId : formData.selectedPublication.value;
      if (this.onPlatform) {
        await this.inviteCollaboratorToPublication(
          targetPublicationId,
          this.orgName,
          this.orgId,
          formData.selectedRole.value,
        );
      } else {
        await this.inviteOffPlatformCollaboratorToPublication(
          targetPublicationId,
          formData.inviteEmail,
          formData.inviteName,
          formData.selectedRole.value,
          this.orgId,
        );
      }
    },
    validateEmail() {
      const email = this.inviteToPublicationFormData.offPlatform.inviteEmail;
      if (!email) {
        this.inviteToPublicationFormErrors.offPlatform.inviteEmailError = "Email is required";
        return false;
      } else if (!validateEmail(email)) {
        this.inviteToPublicationFormErrors.offPlatform.inviteEmailError = "Please enter a valid email address";
        return false;
      } else {
        this.inviteToPublicationFormErrors.offPlatform.inviteEmailError = "";
        return true;
      }
    },
  },
};
</script>

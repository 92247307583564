import { Extension } from "@tiptap/core";

export const MoveNodeDown = Extension.create({
  name: "moveNodeDown",

  addCommands() {
    return {
      moveNodeDown: (editor, node, nodePos) => ({ tr, dispatch }) => {
        const $pos = tr.doc.resolve(nodePos);

        let targetNode = node;
        let targetPos = nodePos;
        let targetDepth = $pos.depth;

        // If the node is nested, we need to move its parent
        if (targetDepth > 0) {
          targetNode = $pos.node(targetDepth);
          targetPos = $pos.before(targetDepth);
        }

        let end = targetPos + targetNode.nodeSize;

        // If we're at the end of the document, we can't move down
        if (end >= tr.doc.content.size - 1) {
          return false;
        }

        // Find the position after the next sibling or parent
        let afterPos = end;
        const { node: afterNode, offset } = tr.doc.childAfter(afterPos);

        // No next node found
        if (!afterNode) {
          return false;
        }

        // Set the position to the end of the next node
        afterPos = offset + afterNode.nodeSize;

        // ProseMirror transaction to move the node to the new position
        if (dispatch) {
          editor
              .chain()
              .command(({ tr }) => {
                // Remove the node from its original position
                tr.delete(targetPos, end);
                return tr;
              })
              .insertContentAt(afterPos - targetNode.nodeSize, targetNode.toJSON())
              .focus()
              .run();

          dispatch(tr);
        }

        return true;
      },
    };
  },
});

<template>
  <iColumn>
    <iSubHeading :style-overrides="{marginTop:'large'}">
      Password complexity requirements
    </iSubHeading>
    <ul>
      <li>
        <iText v-if="hasValidPasswordLength" class="form-valid">
          √
        </iText>
        <iText v-else class="form-invalid">
          x
        </iText>
        <iText :style-overrides="{marginLeft:'standard'}">
          between 6 and 128 characters.
        </iText>
      </li>
      <li>
        <iText v-if="hasSpecialCharacter" class="form-valid">
          √
        </iText>
        <iText v-else class="form-invalid">
          x
        </iText>
        <iText :style-overrides="{marginLeft:'standard'}">
          at least one special character.
        </iText>
      </li>
      <li>
        <iText v-if="hasUpperAndLowerCase" class="form-valid">
          √
        </iText>
        <iText v-else class="form-invalid">
          x
        </iText>
        <iText :style-overrides="{marginLeft:'standard'}">
          at least one uppercase & one lowercase letter.
        </iText>
      </li>
    </ul>
  </iColumn>
</template>

<script>

export default {
  name: "PasswordComplexity",
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  emits: ["update:password", "update:passwordValidity"],
  computed: {
    hasValidPasswordLength() {
      if (!this.password) {
        return false;
      }
      return !(this.password.length < 6 || this.password.length > 128);
    },
    hasSpecialCharacter() {
      if (!this.password) {
        return false;
      }
      return /[!@#$%^&*]/.test(this.password);
    },
    hasUpperAndLowerCase() {
      return !!(this.password && /[A-Z]/.test(this.password) && /[a-z]/.test(this.password));
    },
  },
  watch: {
    password() {
      this.updatePassword();
    },
  },
  methods: {
    updatePassword() {
      this.$emit("update:password", this.hasUpperAndLowerCase && this.hasValidPasswordLength && this.hasSpecialCharacter);
    },
  },
};
</script>

<style scoped lang="scss">
  .form-valid {
    color: green;
  }
  .form-invalid {
    color: red;
  }
</style>

<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_DIRECT_NETWORK"
    title="Direct Network"
    :breadcrumbs="breadcrumbs"
    page-name="Direct Network"
    align="left"
    width="fixed"
  >
    <iDataTable
      v-if="hasConnectedAffiliatePlatform"
      :items="directNetworkPartnerships"
      :fields="fields"
      primary-key="partnership_id"
      hide-actions
      :has-more="hasMoreDirectNetworkPartnerships"
      :is-loading="isLoading"
      :scroll-threshold="400"
      @load-more="loadMore"
    >
      <template #empty>
        <iRow align="center">
          <iText>
            It looks like there are no direct partnerships to display at the moment.
          </iText>
        </iRow>
        <iRow align="center">
          <iText>
            If you've recently connected an affiliate platform, please allow up to 15 minutes for data to appear.
          </iText>
        </iRow>
      </template>
      <template #td(advertiser_name)="{data}">
        <iLink :href="`/community/org/${data.advertiser_org_id}/profile`">
          {{ data.advertiser_name }}
        </iLink>
      </template>
      <template #td(domains)="{data}">
        <iColumn>
          <iRow v-for="domain in data.domains" :key="domain.advertiser_domain_id">
            <iLink :href="getNonRelativeDomain(domain.domain)" target="_blank">
              {{ domain.domain }}
            </iLink>
          </iRow>
        </iColumn>
      </template>
      <template #td(payout)="{data}">
        {{ getPayoutCellString(data) }}
      </template>
    </iDataTable>
    <iRow v-else :width="1200">
      <iText>
        Connect your affiliate platforms accounts to access direct deals from your partnered brands and access in-depth
        analytics.
      </iText>
      <iText>
        Maximize your earnings with higher commissions and create trackable links to your favorite products.
      </iText>
      <iRow>
        <iSubHeading>Affiliate Platforms</iSubHeading>
      </iRow>
      <iRow>
        <SocialNetworkCard
          v-for="affiliatePlatform in affiliatePlatforms"
          :key="affiliatePlatform['publisher_affiliate_platform_account_id']"
          :social-network="affiliatePlatform['display_name']"
          :social-network-id="affiliatePlatform['publisher_affiliate_platform_account_id']"
          :status="affiliatePlatform['status']"
          @connect="openModal(affiliatePlatform['affiliate_platform'])"
        />
      </iRow>
    </iRow>
    <template
      v-for="platform in affiliatePlatforms"
      :key="platform.affiliate_platform"
    >
      <PlatformDetailsModal
        v-if="currentAffiliatePlatform === platform.affiliate_platform"
        :platform="platform.affiliate_platform"
        @close="closeAndResetAddConnectionModal"
        @submit="connectAffiliatePlatform"
      />
    </template>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePartnershipStore } from "@/stores/partnership-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_DIRECT_NETWORK,
  SECTION_MONETIZATION,
} from "@/constants/nav-constants";
import SocialNetworkCard from "@/components/social-networks/SocialNetworkCard";
import PlatformDetailsModal from "@/components/affiliate-platform/PlatformDetailsModal.vue";
import { useAffiliatePlatformsStore } from "@/stores/affiliate-platforms-store";
import { useGeneralStore } from "@/stores/general-store";

export default {
  name: "DirectNetwork",
  components: {
    PlatformDetailsModal,
    SocialNetworkCard,
    PageWrapper,
  },
  data() {
    return {
      PAGE_DIRECT_NETWORK,
      SECTION_MONETIZATION,
      fields: [
        {
          key: "advertiser_name",
          label: "Name",
        },
        {
          key: "payout",
          label: "Payout",
        },
        {
          key: "domains",
          label: "Domains",
        },
      ],
      currentAffiliatePlatform: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    ...mapState(usePartnershipStore, [
      "directNetworkPartnerships",
      "isLoading",
      "hasMoreDirectNetworkPartnerships",
    ]),
    ...mapState(useAffiliatePlatformsStore, [
      "affiliatePlatforms",
      "loadingPlatforms",
      "hasConnectedAffiliatePlatform",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  created() {
    this.fetchDirectNetworkPartnerships();
  },
  methods: {
    ...mapActions(usePartnershipStore, [
      "fetchDirectNetworkPartnerships",
    ]),
    ...mapActions(useAffiliatePlatformsStore, [
      "connect",
      "disconnect",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    loadMore() {
      this.fetchDirectNetworkPartnerships();
    },
    getPayoutCellString(data) {
      if (data.payout_type === "percentage") {
        if (data.payout_value_min === data.payout_value_max) {
          return `${data.payout_value_max}%`;
        } else {
          return `${data.payout_value_min}-${data.payout_value_max}%`;
        }
      }

      if (data.payout_type === "amount") {
        if (data.payout_value_min === data.payout_value_max) {
          return `$${data.payout_value_max}`;
        } else {
          return `$${data.payout_value_min}-${data.payout_value_max}`;
        }
      }
    },
    openModal(affiliatePlatform) {
      this.currentAffiliatePlatform = affiliatePlatform;
    },
    closeAndResetAddConnectionModal() {
      this.currentAffiliatePlatform = "";
    },
    connectAffiliatePlatform(details) {
      this.connect(this.currentAffiliatePlatform, details);
      this.closeAndResetAddConnectionModal();
    },
  },
};
</script>

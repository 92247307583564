// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-32430a60] {
  margin: 10px 5px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/Chatroom.vue","webpack://./Chatroom.vue"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,WAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .chatroom {\n    margin: 10px 5px;\n    width: 100%;\n  }\n",".chatroom {\n  margin: 10px 5px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <iRow>
    <iColumn v-if="showDeleted" class="tooltip">
      <iRow vertical-align="middle">
        <span class="tooltiptext"> {{ deletedText }}</span>
        <div class="flags flags-deleted-status" />
        <iText>
          Deleted
        </iText>
      </iRow>
    </iColumn>
    <iColumn v-if="showPendingModeration">
      <iRow vertical-align="middle">
        <div class="flags flags-pending-status" />
        <iText variant="subtle">
          pending review
        </iText>
      </iRow>
    </iColumn>
    <iColumn v-if="showBlocked">
      <iRow vertical-align="middle">
        <div class="flags flags-blocked-status" />
        <iText variant="subtle">
          Blocked
        </iText>
      </iRow>
    </iColumn>
    <iColumn v-if="showEscalated">
      <iRow vertical-align="middle">
        <div class="flags flags-warning-status" />
        <iText variant="subtle">
          Escalated
        </iText>
      </iRow>
    </iColumn>
  </iRow>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "MessageFlags",
  props: {
    status: {
      type: String,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    timestampUpdated: {
      type: Number,
      required: true,
    },
  },
  computed: {
    deletedText () {
      let dateAndTime = dayjs.unix(this.timestampUpdated).format("DD MMMM YYYY THH:mm");
      return `You deleted this message on ${dateAndTime}`;
    },
    showEscalated() {
      return this.moderationStatus === "escalated";
    },
    showBlocked() {
      return this.moderationStatus === "blocked";
    },
    showPendingModeration() {
      return this.moderationStatus === "unknown";
    },
    showDeleted() {
      return this.status === "inactive";
    },
  },
};
</script>

<style scoped lang="scss">
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    bottom: 20px;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .flags {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &-approved-status {
      background-color: green;
    }

    &-deleted-status{
      background-color: red;
    }
    &-blocked-status{
      background-color: red;
    }

    &-pending-status {
      background-color: yellow;
    }

    &-warning-status {
      background-color: orange;
    }
  }
</style>

export const USER_ROLE_VALUE_OWNER = 0;
export const USER_ROLE_VALUE_ADMIN = 1;
export const USER_ROLE_VALUE_USER = 2;

export const USER_ROLE_LABEL_OWNER = "Owner";
export const USER_ROLE_LABEL_ADMIN = "Admin";
export const USER_ROLE_LABEL_USER = "User";

export const ALL_USER_ROLES = {
  [USER_ROLE_VALUE_OWNER]: USER_ROLE_LABEL_OWNER,
  [USER_ROLE_VALUE_ADMIN]: USER_ROLE_LABEL_ADMIN,
  [USER_ROLE_VALUE_USER]: USER_ROLE_LABEL_USER,
};

export const ALLOWED_ADDITIONAL_USER_ROLES = {
  [USER_ROLE_VALUE_ADMIN]: USER_ROLE_LABEL_ADMIN,
  [USER_ROLE_VALUE_USER]: USER_ROLE_LABEL_USER,
};

export const USER_CHANGE_STATUS_VALUE_ENABLE = "Enable";
export const USER_CHANGE_STATUS_VALUE_DISABLE = "Disable";

export const USER_CHANGE_STATUSES = [
  USER_CHANGE_STATUS_VALUE_ENABLE,
  USER_CHANGE_STATUS_VALUE_DISABLE,
];

export const DEFAULT_USER_AVATAR = "https://cdn-alpha.inspiration.co/images/avatar-default.png";

import { toRaw } from "vue";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const useConversationStore = defineStore("conversation-store", {
  state: () => ({
    // standard payload
    conversation: {},
    allMessages: {},
    orderedMessages: [],
    lastMessageId: 0,
    lastSortId: 0,

    // other stuff
    isLoading: false,
    sendingMessage: false,
    draftMessage: "",
    errorMessage: "",
    pageLimit: 10,
    sortStrategy: "by_date_sent",
    sortDirection: "desc",
    moderationStrategy: "not_required",
    moderationEnabled: false,
    loadingMessages: false,
    hasMoreMessages: true,
    conversationSettings: {
      allowsLike: true,
      allowsReply: true,
      allowsUpvoteAndDownvote: true,
      allowsEditing: true,
      allowsSharing: false,
    },
    isMessageLoading: {},
    messageError: {},
  }),

  getters: {
    messages() {
      return this.orderedMessages.map(messageId => this.allMessages[messageId]);
    },
    isModerator() {
      // @todo make this actually work
      return false;
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
  },

  actions: {
    fetchPublicationChatroom(publicationId) {
      let uri = `/conversation/publication-chatroom/${publicationId}`;
      if (this.loadingMessages) {
        return;
      }

      this.loadingMessages = true;
      this.apiGet(
        uri,
        {
          limit: this.pageLimit,
          sort_direction: this.sortDirection,
          sort_strategy: this.sortStrategy,
        },
        data => {
          this.updateConversation(data.conversationData, data.messages, data.metadata);
          if (data.messages.length > 0) {
            this.hasMoreMessages = true;
          }
        },
        null,
        () => {
          this.loadingMessages = false;
        },
      );
    },
    sendPublicationChatroomMessage(publicationId) {
      let uri = `/conversation/publication-chatroom/${publicationId}/message`;

      if (!this.isMessageLoading.newMessage) {
        this.isMessageLoading.newMessage = {};
      }
      this.isMessageLoading.newMessage = true;
      this.apiPost(
        uri,
        {
          message: this.draftMessage,
          moderation_strategy: this.moderationStrategy,
        },
        response => {
          this.updateConversation(response.conversationData, response.messages, response.metadata);
        },
        null,
        () => {
          this.isMessageLoading.newMessage = false;
        },
      );
    },
    fetchConversation(conversationId, context, entityId) {
      let uri = `/conversation/context/${context}/${entityId}`;
      if (conversationId > 0) {
        uri = `/conversation/${conversationId}`;
      }

      if (this.loadingMessages || this.hasMoreMessage === false) {
        return;
      }

      this.loadingMessages = true;
      this.getFromApi(
        uri,
        {
          limit: this.pageLimit,
          last_message_id: this.lastMessageId || undefined,
          last_sort_id: this.lastSortId || undefined,
          sort_direction: this.sortDirection,
          sort_strategy: this.sortStrategy,
        },
        data => {
          this.updateConversation(data.conversationData, data.messages, data.metadata);
          if (data.messages.length !== this.pageLimit) {
            this.hasMoreMessages = false;
          }
        },
        null,
        () => {
          this.loadingMessages = false;
        },
      );
    },

    submitSorting(conversationId, context, entityId, sortingStrategy, sortDirection) {
      this.sortStrategy = sortingStrategy;
      this.sortDirection = sortDirection;
      this.fetchConversation(conversationId, context, entityId);
    },

    /*
    createConversation(conversationData) {
      this.apiPost(
        `${this.contentApiV1}/conversation/create`,
        { ...conversationData, user_id: this.getUserId, entity_id: 1 },
        () => {
        },
      );
    },

    fetchComments(conversationId) {
      this.apiPost(
        `${this.contentApiV1}/message/${conversationId}`,
        {},
        () => {},
      );
    },
    */

    sendMessage(conversationId, context, entityId) {
      let uri = `/conversation/${conversationId}/message`;
      if (conversationId === 0) {
        uri = `/conversation/context/${context}/${entityId}/message`;
      }
      this.isMessageLoading.newMessage = true;

      this.postToApi(
        uri,
        {
          message: this.draftMessage,
          moderation_strategy: this.moderationStrategy,
        },
        null,
        null,
        () => {
          this.isMessageLoading.newMessage = false;
        },
      );
    },

    deleteMessage(messageId) {
      this.postToMessageEndpoint("delete", messageId, {});
    },

    undeleteMessage(messageId) {
      this.postToMessageEndpoint("undelete", messageId, {});
    },

    editMessage(messageId, newMessage) {
      this.postToMessageEndpoint("edit", messageId, { new_message: newMessage });
    },

    replyToMessage(messageId, newMessage) {
      this.postToMessageEndpoint("reply", messageId, { message: newMessage });
    },

    reportMessage(messageId, reason) {
      this.postToMessageEndpoint("report", messageId, { reason });
    },

    likeMessage(messageId) {
      this.postToMessageEndpoint("like", messageId);
    },

    unLikeMessage(messageId) {
      this.postToMessageEndpoint("revoke-like", messageId);
    },

    upVoteMessage(messageId) {
      this.postToMessageEndpoint("upvote", messageId);
    },

    revokeUpVoteMessage(messageId) {
      this.postToMessageEndpoint("revoke-upvote", messageId);
    },

    downVoteMessage(messageId) {
      this.postToMessageEndpoint("downvote", messageId);
    },

    revokeDownVoteMessage(messageId) {
      this.postToMessageEndpoint("revoke-downvote", messageId);
    },

    approveMessage(messageId) {
      this.postToMessageEndpoint("approve", messageId);
    },

    blockMessage(messageId, reason) {
      this.postToMessageEndpoint("block", messageId, { reason });
    },

    escalateMessage(messageId, reason) {
      this.postToMessageEndpoint("escalate", messageId, { reason });
    },

    /*
    // needs to use messsage specific pagination offsets
    fetchMoreRepliesToMessage(messageId) {
      this.apiGet(
        `${this.contentApiV1}/message/${messageId}/replies`,
        {
          last_sort_id: 0,
          last_message_id: 0,
          limit: this.pageLimit,
          sort_direction: this.sortDirection,
          sort_strategy: this.sortStrategy,
        },
        data => {
          this.messages = data.messages;
          this.lastMessageId = data.metadata.lastMessageId;
          this.lastSortId = data.metadata.lastSortId;
        },
        error => {
          this.errorMessage = error;
        },
      );
    },
    */

    // ////////////////////
    // Helper Functions //
    // ////////////////////

    getFromApi(uri, data, successHandler, errorHandler, finallyHandler) {
      return this.makeApiCall("GET", uri, data, successHandler, errorHandler, finallyHandler);
    },

    postToApi(uri, data, successHandler, errorHandler, finallyHandler) {
      return this.makeApiCall("POST", uri, data, successHandler, errorHandler, finallyHandler);
    },

    makeApiCall(method, uri, data, successHandler, errorHandler, finallyHandler) {
      if (!successHandler) {
        successHandler = response => {
          this.updateConversation(response.conversationData, response.messages, response.metadata);
        };
      }

      const url = `${this.contentApiV1}${uri}`;
      if (method === "POST") {
        return this.apiPost(url, data, successHandler, errorHandler, finallyHandler);
      }
      if (method === "GET") {
        return this.apiGet(url, data, successHandler, errorHandler, finallyHandler);
      }
      throw new Error("unknown method");
    },

    postToMessageEndpoint(endpoint, messageId, data) {
      if (!this.isMessageLoading[messageId]) {
        this.isMessageLoading[messageId] = {};
      }
      this.isMessageLoading[messageId][endpoint] = true;
      this.postToApi(`/message/${messageId}/${endpoint}`,
        data,
        null,
        error => {
          this.isMessageLoading[messageId].error = error;
        },
        () => {
          this.isMessageLoading[messageId][endpoint] = false;
        });
    },

    saveSettings(settings) {
      this.conversationSettings = settings;
    },

    addOrReplaceMessage(message) {
      if (!this.allMessages[message.messageId]) {
        this.allMessages[message.messageId] = message;
        // @todo put messge in correct spot based on sorting and if it's a reply
        // you're funny this is hard enough without that
        if (message.parentId) {
          // todo we should have the parent since we just sent a reply to it... right?
          // probably
          this.allMessages[message.parentId].replies.push(this.allMessages[message.messageId]);
        } else {
          if (this.sortDirection === "desc") {
            this.orderedMessages.unshift(message.messageId);
          } else {
            this.orderedMessages.push(message.messageId);
          }
        }
      } else {
        const originalReplies = structuredClone(toRaw(this.allMessages[message.messageId].replies));
        this.allMessages[message.messageId] = message;
        // did we just blow away some replies that we wanted to keep?
        // i don't think so
        this.allMessages[message.messageId].replies = originalReplies;
      }

      /*
      const index = this.getMessageIndexById(message.messageId);
      if (index > -1) {
        this.all_messages[index] = message;
      } else {
        // @todo handle sorting
        this.all_messages.push(message)
      }
      */
    },

    /*
    getMessageIndexById(id) {
      return this.messages.findIndex(item => item.messageId === id);
    },

    getReplyIndexById(messageId, replyId) {
      let messageIndex = this.messages.findIndex(item => item.messageId === messageId);
      return this.messages[messageIndex].replies.findIndex(item => item.messageId === replyId);
    },
    */

    getMessageFromError(error) {
      if (error.message && error.message === "something specific happened") {
        return "something specific happened";
      }
      return "something went wrong";
    },

    /*
    getMessageById(id) {
      return this.messages[this.getMessageIndexById(id)];
    },
    */

    resetConversation() {
      this.allMessages = {};
      this.orderedMessages = [];
      this.conversation = {};
      this.lastMessageId = null;
      this.lastSortId = null;
    },

    updateConversation(conversationSummary, messages, metadata) {
      this.conversation = conversationSummary;
      messages.forEach(message => {
        this.addOrReplaceMessage(message);
      });
      if (metadata && metadata.lastMessageId && metadata.lastSortId) {
        this.lastMessageId = metadata.lastMessageId;
        this.lastSortId = metadata.lastSortId;
      }
    },
  },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot[data-v-57b4a8e9] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.dot.--color-success[data-v-57b4a8e9] {
  background-color: var(--57b4a8e9-\\\$getStyleValuePropertiesFromTheme\\(\\"color\\.font\\"\\)\\[\\"success\\"\\]);
}
.dot.--color-warning[data-v-57b4a8e9] {
  background-color: var(--57b4a8e9-\\\$getStyleValuePropertiesFromTheme\\(\\"color\\.font\\"\\)\\[\\"accent\\"\\]);
}
.dot.--color-error[data-v-57b4a8e9] {
  background-color: var(--57b4a8e9-\\\$getStyleValuePropertiesFromTheme\\(\\"color\\.font\\"\\)\\[\\"error\\"\\]);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/settings/AffiliatePlatforms.vue","webpack://./AffiliatePlatforms.vue"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,WAAA;EACA,kBAAA;ACDF;ADII;EACE,sGAAA;ACFN;ADKI;EACE,qGAAA;ACHN;ADMI;EACE,oGAAA;ACJN","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.dot {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n\n  &.--color {\n    &-success {\n      background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"success\"]');\n    }\n\n    &-warning {\n      background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"accent\"]');\n    }\n\n    &-error {\n      background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"error\"]');\n    }\n  }\n}\n",".dot {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n}\n.dot.--color-success {\n  background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"success\"]');\n}\n.dot.--color-warning {\n  background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"accent\"]');\n}\n.dot.--color-error {\n  background-color: v-bind('$getStyleValuePropertiesFromTheme(\"color.font\")[\"error\"]');\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

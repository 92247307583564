<template>
  <SmartFeedTableRow :entity="entity" :fields="fields">
    <template #td(emailAddress)>
      {{ entity.emailAddress }}
    </template>
    <template #td(name)>
      {{ entity.name }}
    </template>
    <template #td(roleId)>
      <iDropdownMenu
        :show-dropdown="isDropdownVisible(entity.userId, 'role')"
        position="left"
        :items="userRoleChangeTableOptions"
        @click="
          (value) => submitChangeRole(entity.userId, value, entity.roleId)
        "
        @close="closeDropdown(entity.userId, 'role')"
      >
        <template #trigger>
          <iButton
            variant="tertiary"
            :style-overrides="{paddingLeft: 'none', paddingRight: 'none'}"
            :disabled="entity.roleId === USER_ROLE_VALUE_OWNER"
            @click="toggleDropdown(entity.userId, 'role')"
          >
            {{ ALL_USER_ROLES[entity.roleId] }}
          </iButton>
        </template>
      </iDropdownMenu>
    </template>
    <template #td(status)>
      <iStatus :status="entity.status" />
    </template>
    <template #td(actions)>
      <iRow align="right">
        <iDropdownMenu
          v-if="entity.roleId !== USER_ROLE_VALUE_OWNER"
          :show-dropdown="isDropdownVisible(entity.userId, 'actions')"
          position="right"
          :items="userStatusChangeTableOptions(entity)"
          @click="(value) => submitChangeStatus(entity.userId, value)"
          @close="closeDropdown(entity.userId, 'actions')"
        >
          <template #trigger>
            <iIcon
              icon="ellipsis"
              @click="toggleDropdown(entity.userId, 'actions')"
            />
          </template>
        </iDropdownMenu>
      </iRow>
    </template>
  </SmartFeedTableRow>
</template>

<script>
import SmartFeedTableRow from "@/components/smart-feed/SmartFeedTableRow";
import {
  ALL_USER_ROLES,
  USER_CHANGE_STATUSES,
  USER_ROLE_VALUE_OWNER,
  USER_CHANGE_STATUS_VALUE_DISABLE,
  USER_CHANGE_STATUS_VALUE_ENABLE, ALLOWED_ADDITIONAL_USER_ROLES,
} from "@/constants/user-constants";
import { useOrganizationStore } from "@/stores/organization-store";

export default {
  name: "UserTableRow",
  components: { SmartFeedTableRow },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ALL_USER_ROLES,
      USER_ROLE_VALUE_OWNER,
      dropdownStates: {},
    };
  },
  computed: {
    userRoleChangeTableOptions() {
      return Object.entries(ALLOWED_ADDITIONAL_USER_ROLES).map(userRole => {
        return {
          name: userRole[1],
          label: userRole[1],
          actionType: "action_type_button_click",
          actionValue: userRole[0],
        };
      });
    },
  },
  methods: {
    toggleDropdown(id, type) {
      this.dropdownStates = {
        ...this.dropdownStates,
        [`${id}-${type}`]: !this.dropdownStates[`${id}-${type}`],
      };
    },
    closeDropdown(id, type) {
      this.dropdownStates = {
        ...this.dropdownStates,
        [`${id}-${type}`]: false,
      };
    },
    isDropdownVisible(id, type) {
      return !!this.dropdownStates[`${id}-${type}`];
    },
    userStatusChangeTableOptions(actionData) {
      let { status } = actionData;
      return USER_CHANGE_STATUSES.filter(statusAction => {
        const canDisable = status === "active" && statusAction === "Disable";
        const canEnable = status !== "active" && statusAction === "Enable";

        return canDisable || canEnable;
      }).map(status => {
        return {
          name: status,
          label: status,
          actionType: "action_type_button_click",
          actionValue: status,
        };
      });
    },
    submitDisableUser(userId) {
      useOrganizationStore().disableUser(userId);
    },
    submitEnableUser(userId) {
      useOrganizationStore().enableUser(userId);
    },
    submitChangeRole(userId, newRoleId, roleId) {
      useOrganizationStore().changeUserRole(
        userId,
        useOrganizationStore().getOrgData.org_id,
        roleId,
        newRoleId,
      );
    },
    submitChangeStatus(userId, newStatus) {
      if (newStatus === USER_CHANGE_STATUS_VALUE_DISABLE) {
        this.submitDisableUser(userId);
      } else if (newStatus === USER_CHANGE_STATUS_VALUE_ENABLE) {
        this.submitEnableUser(userId);
      }
    },
  },
};
</script>

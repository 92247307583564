<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_DEPLOYMENT_VERSION_HISTORY"
    title="Deployment Versions"
    :breadcrumbs="breadcrumbs"
    page-name="Versions"
    align="left"
    width="full"
  >
    <iColumn :width="700" gap="large">
      <PublicationVersionCard
        v-for="(version, index) in mappedPublicationVersions"
        :key="index"
        :version="version.version"
        :memo="version.memo"
        :date-created="version.date_created"
        :publication-version-id="version.publication_version_id"
        :staging="true"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";

import PageWrapper from "@/components/PageWrapper";
import PublicationVersionCard from "@/components/PublicationVersionCard";
import { getRelativeTimeString } from "@bloglovin/vue-component-library";
import {
  PAGE_PUBLICATION_DEPLOYMENT_VERSION_HISTORY,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationDeploymentStagingVersions",
  components: {
    PageWrapper,
    PublicationVersionCard,
  },
  props: {
    deployments: {
      type: Array,
      required: true,
    },
    publicationVersions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_DEPLOYMENT_VERSION_HISTORY,
      SECTION_PUBLICATIONS,
      isModalOpen: false,
      runAction: () => {},
      selectedVersion: 0,
      openDropdownIndex: -1,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    mappedPublicationVersions() {
      return this.publicationVersions.map(version => {
        // eslint-disable-next-line no-unused-vars
        let { configuration, ...mappedVersion } = version;
        return mappedVersion;
      });
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/deployment`, label: "Deployment" },
      ];
    },
    isDropdownOpen() {
      return index => this.openDropdownIndex === index;
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["rollbackPublicationVersion"]),
    getRelativeTimeString,
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    confirmAction() {
      this.runAction();
      this.closeModal();
    },
    rejectAction() {
      this.selectedVersion = 0;
      this.closeModal();
    },

    openRollbackConfirmation(version) {
      this.selectedVersion = version;
      this.openModal();
      this.runAction = this.confirmRollback;
    },
  },
};
</script>

import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useGeneralStore } from "@/stores/general-store";

export const useDigitalProductsStore = defineStore("digital-products-store", {
  state: () => ({
    digitalProducts: [],
    isLoadingDigitalProducts: false,
    isCreatingDigitalProduct: false,
    lastDigitalProductId: 0,
    limit: 20,
  }),
  getters: {
    hasMoreDigitalProducts() {
      return this.lastDigitalProductId !== -1;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    publicationId() {
      return usePublicationStore().publicationId;
    },
  },
  actions: {
    fetchDigitalProducts() {
      if (!this.isLoadingDigitalProducts) {
        this.isLoadingDigitalProducts = true;
        $axios.get(`/digital-products/org/${this.orgId}?last_id=${this.lastDigitalProductId}&limit=${this.limit}`)
            .then(response => {
              this.digitalProducts = this.digitalProducts.concat(response.data.digitalProductData);
              if (response.data.digitalProductData.length === 0) {
                this.lastDigitalProductId = -1;
                return;
              }
              this.lastDigitalProductId = response.data.digitalProducts.at(-1).digital_product_id;
              this.isLoadingDigitalProducts = false;
            });
      }
    },
    createDigitalProduct(name, description, digitalProductUrl, price, currencyCode, startDateAvailable, endDateAvailable, files ) {
      if (!this.isCreatingDigitalProduct) {
        this.isCreatingDigitalProduct = true;
        let formData = new FormData();
        let digitalProductData = {
          name: name,
          description: description,
          digital_product_url: digitalProductUrl,
          price: price,
          currencyCode: currencyCode,
          startDateAvailable: startDateAvailable,
          endDateAvailable: endDateAvailable,
          file: files,
        };
        Object.keys(digitalProductData).forEach(key => {
          formData.append(key, digitalProductData[key]);
        });
        $axios.post(`/digital-products/publication/${this.publicationId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.isCreatingDigitalProduct = false;
          window.location.replace(`/monetization/org/${this.orgId}/digital-products`);
        });
      }
    },
  },
});

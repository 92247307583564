// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card[data-v-16eb0831] {
  overflow: visible;
}
.deleted[data-v-16eb0831] {
  background-color: #bdbdbd;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/ReplyWidget.vue","webpack://./ReplyWidget.vue"],"names":[],"mappings":"AAEA;EACE,iBAAA;ACDF;ADGA;EACE,yBAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.card {\n  overflow: visible;\n}\n.deleted {\n  background-color: #bdbdbd;\n}\n",".card {\n  overflow: visible;\n}\n\n.deleted {\n  background-color: #bdbdbd;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <iColumn>
    <iRow v-if="!isOwningOrg">
      <iButton @click="openApplyToCollaborateModel">
        Apply to collaborate
      </iButton>
    </iRow>
    <iModal
      v-if="applyToCollaborateModalState"
      title="Apply to Collaborate"
      :width="600"
      @close="closeApplyToCollaborateModalAndReset"
    >
      <template #body>
        <iColumn gap="large">
          <iSelect
            v-model="applyToCollaborateFormData.role"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
          />
          <iTextArea
            v-model="applyToCollaborateFormData.message"
            label="Application Message"
            placeholder="Enter your message here..."
            :rows="5"
            width="fill"
          />
          <iText v-if="applyToCollaborateError" variant="error">
            {{ applyToCollaborateError }}
          </iText>
        </iColumn>
      </template>
      <template #footer>
        <iRow align="between" gap="standard" width="fill">
          <iColumn width="hug">
            <iButton variant="secondary" @click="closeApplyToCollaborateModalAndReset">
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              variant="primary"
              loading-spinner-position="inside"
              :is-loading="applyToCollaborateLoadingState"
              :disabled="isDisabled"
              @click="submitApplication"
            >
              Submit Application
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";

export default {
  name: "ApplyToCollaborate",
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ROLE_OPTIONS,
    };
  },
  computed: {
    ...mapState(useUserStore, ["getUserData"]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCollaborationStore, [
      "applyToCollaborateLoadingState",
      "applyToCollaborateModalState",
      "applyToCollaborateFormData",
      "errors",
    ]),
    isOwningOrg() {
      return this.getOrgData.org_id === this.orgId;
    },
    applyToCollaborateError() {
      return this.errors.applyToCollaborateError;
    },
    isFormValid() {
      return this.applyToCollaborateFormData.role && this.applyToCollaborateFormData.message;
    },
    isDisabled() {
      return !this.isFormValid || this.applyToCollaborateLoadingState;
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "closeApplyToCollaborateModalAndReset",
      "applyToCollaborate",
      "openApplyToCollaborateModel",
    ]),
    async submitApplication() {
      await this.applyToCollaborate(
        this.publicationId,
        this.getOrgData.org_id,
        this.getUserData.email_data.email_address,
        this.getUserData.first_name + " " + this.getUserData.last_name,
        this.applyToCollaborateFormData.role.value,
        this.orgId,
      );
    },
  },
};
</script>

const preventTabCloseListener = function (e) {
  var message = "You have unsaved changes on this page. If you leave your changes will be lost.";
  var event = e || window.event;
  event.preventDefault();
  // For IE and Firefox
  if (event) {
    event.returnValue = message;
  }
  // For Safari
  return message;
};

export const preventTabClose = () => {
  return window.addEventListener("beforeunload", preventTabCloseListener);
};
export const allowTabClose = () => {
  window.removeEventListener("beforeunload", preventTabCloseListener);
};

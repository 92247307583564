<template>
  <div>
    Publication Preview
  </div>
</template>

<script>

export default {
  name: "PublicationPreview",
  props: {},
};
</script>

<style scoped>
</style>

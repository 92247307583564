

import { MenuConstants } from "@bloglovin/vue-component-library";
import { HIDE_ACTION_STATUSES, STATUS_MAP } from "@/constants/collaborator-constants";

export function getFormattedStatus(status) {
  return STATUS_MAP[status] || "Unknown";
}

export function shouldShowActionButton(status, showActions) {
  return showActions && !HIDE_ACTION_STATUSES.includes(status);
}

export function getActionItems(isPublicationOwner, status) {
  if (isPublicationOwner) {
    if (status === "applied") {
      return [
        createActionItem("accept", "Accept"),
        createActionItem("deny", "Deny"),
      ];
    }
    return [createActionItem("revoke", "Revoke")];
  }
  if (status === "invited") {
    return [
      createActionItem("accept", "Accept"),
      createActionItem("deny", "Deny"),
    ];
  }
  if (status === "applied") {
    return [createActionItem("revoke", "Revoke")];
  }
  return [];
}

function createActionItem(name, label) {
  return {
    name,
    label,
    actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
    actionValue: name,
  };
}

<template>
  <PageWrapper :org-id="orgId" :active-section="SECTION_COMMUNITY" :active-page="getActiveTopic">
    <iColumn class="container">
      <iRow class="post-head">
        <iButton @click="joinTopic">
          Join
        </iButton>
        <iButton @click="leaveTopic">
          Leave
        </iButton>
        <a href="/post/create">
          <iButton>
            + Post
          </iButton>
        </a>
      </iRow>
    </iColumn>
    <!-- should have list of conversations here -->
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCommunityStore } from "@/stores/community-store";
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_COMMUNITY,
  PAGE_FASHION,
  PAGE_BEAUTY,
  PAGE_TRAVEL,
  PAGE_FOOD,
  PAGE_DIY,
} from "@/constants/nav-constants";

export default {
  name: "TopicFeed",
  components: {
    PageWrapper,
  },
  props: {
    topic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_FASHION,
      PAGE_BEAUTY,
      PAGE_FOOD,
      PAGE_TRAVEL,
      PAGE_DIY,
    };
  },
  computed: {
    ...mapState(useCommunityStore, ["getPosts"]),
    getActiveTopic() {
      switch (this.topic) {
        case "fashion":
          return this.PAGE_FASHION;
        case "beauty":
          return this.PAGE_BEAUTY;
        case "food":
          return this.PAGE_FOOD;
        case "travel":
          return this.PAGE_TRAVEL;
        default:
          return this.PAGE_FASHION;
      }
    },
  },
  async created() {
    this.fetchPosts(0);
    // this.fetchPosts(this.topic);
  },
  methods: {
    ...mapActions(useCommunityStore, {
      fetchPosts: "fetchPosts",
      submitLeaveTopic: "submitLeaveTopic",
      submitJoinTopic: "submitJoinTopic",
      submitCommentUpvote: "submitCommentUpvote",
      submitCommentDownvote: "submitCommentDownvote",
      submitCommentBookmark: "submitCommentBookmark",
    }),
    showSocialIcons() {
      this.showSocialsDropdown = true;
    },
    joinTopic() {
      this.submitJoinTopic(this.topic, this.getUserData);
    },
    leaveTopic() {
      this.submitJoinTopic(this.topic, this.getUserData);
    },
  },
};
</script>

<style scoped>
  .container {
    width: 800px;
    margin: 0 auto;
  }
</style>

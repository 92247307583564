<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS"
    title="Subscriptions"
    :breadcrumbs="breadcrumbs"
    page-name="Subscriptions"
    align="left"
    width="full"
  >
    <iColumn>
      <iParagraph>
        Lorem ipsum dolor sit amet consectetur adipiscing elit, leo mattis quisque augue cursus massa, porta pretium tellus felis phasellus velit.
      </iParagraph>
      <iToggleSwitch v-model="config.enabled" label="Allow users to subscribe" @change="saveSubscriptionsConfiguration" />

      <iColumn :disabled="!config.enabled">
        <iRow vertical-align="middle">
          <iSubHeading>
            Subscriber Only Area
          </iSubHeading>
          <iToggleSwitch v-model="subscriberAreaEnabled" size="small" />
        </iRow>
        <iParagraph>
          Lorem ipsum dolor sit amet consectetur adipiscing elit, leo mattis quisque augue cursus massa, porta pretium tellus felis phasellus velit.
        </iParagraph>
      </iColumn>
      <iSpace :height="1" />
      <iColumn :disabled="!config.enabled" class="subscriptions">
        <iRow vertical-align="middle">
          <iSubHeading>
            Plans
          </iSubHeading>
          <iColumn width="hug">
            <iButton
              v-if="!isSubscriptionFormOpen"
              :disabled="!config.enabled"
              variant="secondary"
              @click="openSubscriptionForm"
            >
              Add Plan
            </iButton>
          </iColumn>
        </iRow>
        <iParagraph>
          Lorem ipsum dolor sit amet consectetur adipiscing elit, leo mattis quisque augue cursus massa, porta pretium tellus felis phasellus velit.
        </iParagraph>
        <iColumn class="subscription-plans">
          <iRow gap="extraLarge">
            <iColumn
              v-for="(plan, index) in plans"
              :key="index"
              width="hug"
            >
              <iStyledCard
                :width="300"
                :title="plan.name"
                :subtitle="planSubtitle(plan)"
              >
                <template #title>
                  <iRow align="between" vertical-align="middle" wrap="nowrap">
                    <iSubHeading>{{ plan.name }}</iSubHeading>
                    <iToggleSwitch v-model="plan.enabled" size="small" @change="updateSubscription(plan.subscription_plan_id)" />
                  </iRow>
                </template>
              </iStyledCard>
            </iColumn>
          </iRow>
        </iColumn>
      </iColumn>
    </iColumn>
    <iModal
      width="hug"
      :visible="isSubscriptionFormOpen"
      title="New Subscription Plan"
      primary-action-button-label="Add Plan"
      @close="closeSubscriptionForm"
      @click:primary="addSubscriptionPlan"
      @click:secondary="closeSubscriptionForm"
    >
      <template #body>
        <iColumn width="fill">
          <iTextInput v-model="name" label="Name" />
          <iTextArea v-model="description" label="Description" />
          <iSelect
            id="billing-frequency"
            v-model="billing_frequency"
            label="Billing Frequency"
            :items="subscriptionTypes"
            value-field="name"
            text-field="name"
          />
          <iTextInput v-model="price" type="number" label="Price" />
          <!-- <iSelect
            id="currency-code"
            v-model="currency_code"
            label="Currency"
            :items="currencyCodes"
            value-field="name"
            text-field="symbol"
          /> -->
          <!-- <iTextInput v-model="exclusivity_window_days" type="number" label="Exclusivity window" /> -->
        </iColumn>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationSubscriptions",
  components: {
    PageWrapper,
  },
  props: {
    validBillingFrequencies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS,
      SECTION_PUBLICATIONS,
      config: {
        enabled: false,
      },
      isSubscriptionFormOpen: false,
      name: "",
      description: "",
      billing_frequency: { name: "weekly", id: "weekly" },
      price: 0,
      exclusivity_window_days: 0,
      currency_code: MAPPED_CURRENCY_CODES[0],
      subscriberAreaEnabled: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["configuration", "isLoading", "orgId", "publicationId", "subscriptionPlans"]),
    subscriptionTypes() {
      return this.validBillingFrequencies.map(key => ({ name: key, id: key }));
    },
    currencyCodes() {
      return MAPPED_CURRENCY_CODES;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
    plans() {
      return this.subscriptionPlans;
    },
  },
  created() {
    this.config = { ...this.config, ...this.configuration.subscriptions };
  },
  methods: {
    ...mapActions(usePublicationStore, ["saveConfiguration", "saveConfigurationValue", "createSubscription", "updatePublicationSubscription"]),
    planSubtitle(plan) {
      let string = "";
      if (plan.currency_code) {
        let currencyCode = this.getCurrencyCode(plan.currency_code);
        string += `${currencyCode.symbol}`;
      }
      if (plan.price) {
        string += `${plan.price} `;
      }
      if (plan.billing_frequency) {
        let billingFrequency = this.getBillingFrequency(plan.billing_frequency);
        string += ` ${billingFrequency.name}`;
      }
      return string;
    },
    getCurrencyCode(currencyCode) {
      return this.currencyCodes.find(code => code.key == currencyCode);
    },
    getBillingFrequency(billingFrequency) {
      return this.subscriptionTypes.find(frequency => frequency.id == billingFrequency);
    },
    openSubscriptionForm() {
      this.isSubscriptionFormOpen = true;
    },
    closeSubscriptionForm() {
      this.isSubscriptionFormOpen = false;
    },
    async addSubscriptionPlan() {
      this.createSubscription(
        this.name,
        this.description,
        this.billing_frequency.name,
        parseFloat(this.price),
        this.currency_code.key,
        5,
        parseInt(this.exclusivity_window_days),
        // eslint-disable-next-line @stylistic/js/comma-dangle
        false
      );
      this.closeSubscriptionForm();
    },
    updateSubscription(subscriptionPlanId) {
      let plan = this.plans.find(plan => plan.subscription_plan_id == subscriptionPlanId);
      this.updatePublicationSubscription(subscriptionPlanId, plan.name, plan.description, plan.enabled);
    },
    saveSubscriptionsConfiguration() {
      this.saveConfigurationValue("subscriptions.enabled", this.config.enabled);
    },
  },
};
</script>

<style scoped>
</style>

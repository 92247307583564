<template>
  <SplashWrapper
    :hide-buttons="true"
    :title="userFirstName ? `Welcome Back ${userFirstName}` : 'Join our waitlist'"
    :narrow-page="true"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iText variant="subtle">
          Enter your password
        </iText>
      </iRow>

      <iRow>
        <iColumn>
          <iTextInput
            v-model="userAuthEmail"
            label="Email address: "
            name="email"
            label-position="above"
            type="email"
            disabled
            width="fill"
            right-icon="pencil"
            @click-icon-right="gotToChangeEmail"
          />

          <iTextInput
            ref="password"
            v-model="password"
            :focus="true"
            label-position="above"
            label="Password: "
            width="fill"
            name="password"
            type="password"
            autocomplete
            @enter="submitLoginForm"
          />

          <iText v-if="loginErrorMessage" variant="error">
            {{ loginErrorMessage }}
          </iText>

          <iButton
            variant="primary"
            type="submit"
            width="fill"
            :disabled="hasInvalidPassword"
            :is-loading="isLoading"
            loading-spinner-position="inside"
            @click="submitLoginForm"
          >
            Sign in
          </iButton>
          <iButton
            variant="tertiary"
            type="button"
            width="fill"
            @click="goToForgotPassword"
          >
            Forgot your password?
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </SplashWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { FORGOT_PASSWORD_FORM } from "@/constants/login-flow-constants";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "PasswordForm",
  components: { SplashWrapper },
  data() {
    return {
      password: "",
    };
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "userFlowSteps",
      "loginErrorMessage",
    ]),
    ...mapState(useUserStore, [
      "userAuthEmail",
      "userFirstName",
      "isLoading",
    ]),
    hasInvalidPassword() {
      return this.password.length === 0;
    },
  },
  mounted() {
    this.loginErrorMessage = "";
  },
  methods: {
    ...mapActions(useUserStore, [
      "loginUser",
    ]),
    submitLoginForm() {
      if (this.hasInvalidPassword) {
        return;
      }
      this.loginUser(this.password);
    },
    goToForgotPassword() {
      this.userFlowSteps.push(FORGOT_PASSWORD_FORM);
    },
    gotToChangeEmail() {
      this.userFlowSteps.pop();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

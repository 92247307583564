import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";
import { affiliatePlatformConnectionStatus } from "@/enums/affiliate-platform-enums";

export const useAffiliatePlatformsStore = defineStore("affiliate-platforms-store", {
  state: () => ({
    affiliatePlatforms: [],
    loadingPlatforms: false,
  }),
  getters: {
    affiliateApiV1() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    hasConnectedAffiliatePlatform() {
      const neverConnectedAffiliatePlatforms = this.affiliatePlatforms.filter(affiliatePlatform => {
        return affiliatePlatform.status !== affiliatePlatformConnectionStatus.NEVER_CONNECTED;
      });
      return neverConnectedAffiliatePlatforms.length > 0;
    },
  },
  actions: {
    connect(affiliatePlatformName, credentials) {
      this.loadingPlatforms = true;
      $axios.post(`${this.affiliateApiV1}/affiliate-platform-account/connect`, {
        org_id: this.orgId,
        affiliate_platform_name: affiliatePlatformName,
        platform_credentials: credentials,
      }).then(response => {
        this.affiliatePlatforms = response.data.affiliate_platform_accounts;
      }).finally(() => {
        this.loadingPlatforms = false;
      });
    },
    disconnect(affiliatePlatformAccountId) {
      $axios.post(`${this.affiliateApiV1}/affiliate-platform-account/disconnect`, {
        org_id: this.orgId,
        affiliate_platform_account_id: parseInt(affiliatePlatformAccountId),
      }).then(response => {
        this.affiliatePlatforms = response.data.affiliate_platform_accounts;
      });
    },
  },
});

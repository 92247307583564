/* eslint-disable no-console */
import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const useTipaltiStore = defineStore("tipalti-store", {
  state: () => ({
    iframeSrc: "",
    baseParams: {
      idap: useGeneralStore().orgId,
      payer: "ISH",
      ts: Math.floor(Date.now() / 1000),
    },
  }),

  getters: {
    getIframeSrc: state => state.iframeSrc,
    paymentsV1ApiUrl() {
      return useGeneralStore().getLink(links.paymentsApiV1);
    },
  },

  actions: {
    async generateIframeSrc() {
      try {
        const response = await $axios.post(
          `${this.paymentsV1ApiUrl}/payment/get-hmac`,
          this.baseParams,
        );
        if (response.data && response.data.success) {
          this.iframeSrc = response.data.iframe_src;
        }
      } catch (error) {
        console.error("Error generating iframe source:", error);
      }
    },
  },
});

export const PUBLISHER_WEBSITE_DROPDOWN_LABELS = {
  active: "Activate",
  inactive: "Disconnect",
  edit: "Edit",
};

export const PUBLISHER_WEBSITE_DROPDOWN_KEYS = {
  active: "active",
  inactive: "inactive",
  edit: "edit",
};

export default {
  colors: {
    gray: {
      100: "#fbfafb",
      300: "#e3e3e3",
      400: "#FAF9FB",
      800: "#E7E5EB",
    },
    striking: {
      100: "#e5f46c",
      400: "#6eff5d",
      800: "#99a91b",
    },
  },
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container[data-v-0266ca71] {
  width: 800px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/community/TopicFeed.vue","webpack://./TopicFeed.vue"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,cAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .container {\n    width: 800px;\n    margin: 0 auto;\n  }\n",".container {\n  width: 800px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

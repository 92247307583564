import { defineStore } from "pinia";

export const useCommunityStore = defineStore("community-store", {
  state: () => ({
    isLoading: false,
  }),
  getters: {
  },
  actions: {
  },
});

<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_LINK_GENERATOR"
    title="Create Link"
    :breadcrumbs="breadcrumbs"
    page-name="Link Generator"
    align="left"
    width="fixed"
  >
    <iTextInput
      v-model="landingUrl"
      name="landingUrl"
      placeholder="Product URL"
      label="Product URL"
      :required="false"
      type="text"
      @enter="submit"
    />
    <iButton
      type="submit"
      :disabled="loadingCreateAffiliateLink || !landingUrl"
      :is-loading="loadingCreateAffiliateLink"
      loading-text="This might take a minute"
      @click="submit"
    >
      Create Link
    </iButton>
    <template v-if="affiliateLinkError">
      <iText variant="subtle">
        {{ affiliateLinkError }}
      </iText>
    </template>
    <iCopyText
      v-if="createdAffiliateLink.short_link"
      :text="createdAffiliateLink.short_link"
      copy-message="Link Copied!"
      message-position="right"
    />
    <iRow v-if="createdAffiliateLink.affiliate_link_id" :width="500">
      <iSubHeading>
        Add to Collection
      </iSubHeading>
      <SelectAffiliateCollection :affiliate-link-id="createdAffiliateLink.affiliate_link_id" />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { mapActions, mapState } from "pinia";
import { SECTION_MONETIZATION, PAGE_LINK_GENERATOR } from "@/constants/nav-constants";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import SelectAffiliateCollection from "@/components/SelectAffiliateCollection.vue";

export default {
  name: "LinkGenerator",
  components: {
    PageWrapper,
    SelectAffiliateCollection,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINK_GENERATOR,
      landingUrl: "",
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "createdAffiliateLink",
      "affiliateLinkError",
      "loadingCreateAffiliateLink",
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, [
      "createLink",
    ]),
    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.createdAffiliateLink.short_link);
    },
    submit() {
      if (!this.landingUrl) {
        return;
      }
      this.createLink(this.landingUrl);
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_LINKS_COLLECTIONS"
    title="Affiliate Link Collections"
    page-name="Collections"
    :breadcrumbs="breadcrumbs"
    action-button-text="Create New Collection"
    @click:action="openCreateCollectionModal"
  >
    <iRow vertical-align="top" gap="extraLarge">
      <AffiliateLinkCollectionCard
        v-for="collection, index in collections"
        :key="index"
        :link-collection-id="collection.affiliate_link_collection_id"
        :name="collection.name"
        :category="collection.category"
        :description="collection.description"
        :cover-image="collection.cover_image"
        :link-count="collection.link_count"
        :visibility="collection.visibility"
        :clicks="collection.link_data.clicks"
        :sales="collection.link_data.sales"
        :pending-earnings="collection.link_data.pending_earnings"
        :locked-earnings="collection.link_data.locked_earnings"
        :conversions="collection.link_data.conversions"
        @edit-collection="openEditCollectionModal"
      />
    </iRow>
  </PageWrapper>
  <!-- The v-if is here specifically to load the editingCollection change when we trigger an edit -->
  <CollectionDetailsModal
    v-if="showCollectionModal"
    :open="showCollectionModal"
    :affiliate-link-collection="editingCollection"
    @close="closeCollectionModal"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import AffiliateLinkCollectionCard from "@/components/AffiliateLinkCollectionCard";
import { SECTION_MONETIZATION, PAGE_LINKS_COLLECTIONS } from "@/constants/nav-constants";
import CollectionDetailsModal from "@/components/CollectionDetailsModal";

export default {
  name: "LinkCollections",
  components: {
    PageWrapper,
    AffiliateLinkCollectionCard,
    CollectionDetailsModal,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINKS_COLLECTIONS,
      showCollectionModal: false,
      editingCollection: {},
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "collections",
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
        { url: `/monetization/org/${this.orgId}/links`, label: "My Links" },
      ];
    },
  },
  created() {
    this.fetchMyOrgLinkCollections();
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, ["fetchMyOrgLinkCollections"]),
    closeCollectionModal() {
      this.editingCollection = {};
      this.showCollectionModal = false;
    },
    openCreateCollectionModal() {
      this.editingCollection = {};
      this.showCollectionModal = true;
    },
    openEditCollectionModal(collection) {
      this.editingCollection = collection;
      this.showCollectionModal = true;
    },
  },
};
</script>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bubble-menu[data-v-1deeb185] {
  display: flex;
  gap: 4px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #E7E5EB;
  border-radius: 8px;
}
.bubble-menu button[data-v-1deeb185] {
  min-width: 32px;
  background-color: unset;
  border: none;
  border-radius: 4px;
}
.bubble-menu button[data-v-1deeb185]:hover {
  cursor: pointer;
  background-color: #E7E5EB;
}
.bubble-menu button.is-active[data-v-1deeb185] {
  background-color: #E7E5EB;
  font-weight: 700;
}
.bubble-menu button.is-active[data-v-1deeb185]:hover {
  background-color: #FAF9FB;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/post-editor/SelectionBubble.vue","webpack://./SelectionBubble.vue"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,QAAA;EAEA,YAAA;EACA,yBAAA;EAEA,yBAAA;EACA,kBAAA;ACHF;ADKE;EACE,eAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;ACHJ;ADKI;EACE,eAAA;EACA,yBAAA;ACHN;ADMI;EACE,yBAAA;EACA,gBAAA;ACJN;ADMM;EACE,yBAAA;ACJR","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.bubble-menu {\n  display: flex;\n  gap: 4px;\n\n  padding: 8px;\n  background-color: #ffffff;\n\n  border: 1px solid #E7E5EB;\n  border-radius: 8px;\n\n  button {\n    min-width: 32px;\n    background-color: unset;\n    border: none;\n    border-radius: 4px;\n\n    &:hover {\n      cursor: pointer;\n      background-color: #E7E5EB;\n    }\n\n    &.is-active {\n      background-color: #E7E5EB;\n      font-weight: 700;\n\n      &:hover {\n        background-color: #FAF9FB;\n      }\n    }\n  }\n}\n",".bubble-menu {\n  display: flex;\n  gap: 4px;\n  padding: 8px;\n  background-color: #ffffff;\n  border: 1px solid #E7E5EB;\n  border-radius: 8px;\n}\n.bubble-menu button {\n  min-width: 32px;\n  background-color: unset;\n  border: none;\n  border-radius: 4px;\n}\n.bubble-menu button:hover {\n  cursor: pointer;\n  background-color: #E7E5EB;\n}\n.bubble-menu button.is-active {\n  background-color: #E7E5EB;\n  font-weight: 700;\n}\n.bubble-menu button.is-active:hover {\n  background-color: #FAF9FB;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

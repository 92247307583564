<template>
  <iColumn width="hug" vertical-align="middle">
    <iHeading>
      Create Conversation
    </iHeading>
    <iTextInput
      v-model="subject"
      name="subject"
      label="Subject "
      label-position="above"
    />
    <iTextInput
      v-model="message"
      name="message"
      label="Conversation Content "
      label-position="above"
    />
    <iButton :disabled="!hasValidConversation" @click="submitNewConversation">
      Save Conversation
    </iButton>
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCommunityStore } from "@/stores/community-store";
import { useGeneralStore } from "@/stores/general-store";
import {
  SECTION_COMMUNITY,
  PAGE_CREATE_POST,
} from "@/constants/nav-constants";

export default {
  name: "CreateConversation",
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_CREATE_POST,
      subject: "",
      message: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["getOrgId", "getUserId"]),
    hasValidConversation() {
      return this.subject.length > 0 && this.message.length > 0;
    },
  },
  methods: {
    ...mapActions(useCommunityStore, {
      createConversation: "createConversation",
    }),
    submitNewConversation() {
      const conversationData = {
        context: "discussion",
        subject: this.subject,
        message: this.message,
      };
      this.createConversation(conversationData);
    },
  },
};
</script>

  <style scoped>
  </style>

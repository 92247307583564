<template>
  <PageWrapper
    :active-page="$options.name"
    active-section=""
    title="My Favorite Brands"
    sub-title="A wishlist of who you want to partner with"
    :breadcrumbs="[{url: '/splash', label: 'Home'}]"
    page-name="Brands"
    align="center"
    width="fixed"
  >
    <iColumn>
      <iRow>
        <iParagraph>
          Inspiration.co helps you partner with the brands you're passionate about, enabling you to make genuine recommendations to your fans,
          and earn money while helping them discover amazing new things.
        </iParagraph>
        <!-- eslint-disable-->
        <iParagraph>
          Tell us some of your top choices, and we will work to secure partnership opportunities. When your application to join the inspiration network is approved, you can start promoting and earning money immediately.
        </iParagraph>
        <!-- eslint-enable-->
      </iRow>

      <iRow v-if="storedBrands.length > 0" align="right">
        <iButton @click="modalOpen = true">
          Add a Brand
        </iButton>
        <iDataTable
          :items="storedBrands"
          :fields="brandFields"
          primary-key="brand_survey_response_id"
          show-empty-state
          hide-actions
          empty-string="No brands."
        >
          <template #td(brand_name)="slotProps">
            <iRow vertical-align="middle">
              <iText>{{ slotProps.data.brand_name }}</iText>
            </iRow>
          </template>
          <template #td(reason)="slotProps">
            <iRow vertical-align="middle">
              <iText>{{ slotProps.data.reason }}</iText>
            </iRow>
          </template>
          <template #td(actions)="slotProps">
            <iButton
              variant="secondary"
              size="small"
              @click="removeBrand(slotProps.data.brand_survey_response_id)"
            >
              remove
            </iButton>
          </template>
        </iDataTable>
      </iRow>

      <iModal
        :visible="modalOpen"
        width="hug"
        title="Add a Brand"
        @close="modalOpen = false"
      >
        <template #body>
          <iRow>
            <iTextInput
              v-model="brandSurveyBrandName"
              :focus="true"
              label="Brand Name"
              placeholder="A Company I like"
            />
          </iRow>
          <iRow>
            <!-- eslint-disable-next-line -->
            <iTextArea v-model="brandSurveyReason" label="What do you like about this brand?" placeholder="I use their products every day, and want to tell my fans how much they help improve my day.If i can earn commissions from those recommendations that would be great!" />
          </iRow>
        </template>
        <template #footer>
          <iRow align="right">
            <iButton
              :disabled="incompleteForm"
              :is-loading="isLoading"
              @click="() => {submitBrandSurvey(); modalOpen = false}"
            >
              Save
            </iButton>
          </iRow>
        </template>
      </iModal>

      <template v-if="storedBrands.length == 0">
        <iRow>
          <iSubHeading>Add a Brand</iSubHeading>
        </iRow>
        <iRow>
          <iColumn gap="extraLarge">
            <iRow>
              <iTextInput
                v-model="brandSurveyBrandName"
                :focus="true"
                label="Brand Name"
                placeholder="A Company I like"
              />
            </iRow>
            <iRow bottom-padding="standard">
              <!-- eslint-disable-next-line -->
              <iTextArea v-model="brandSurveyReason" label="What do you like about this brand?" placeholder="I use their products every day, and want to tell my fans how much they help improve my day.If i can earn commissions from those recommendations that would be great!" />
            </iRow>
            <iRow>
              <iButton
                :disabled="incompleteForm"
                :is-loading="isLoading"
                @click="submitBrandSurvey"
              >
                Save
              </iButton>
            </iRow>
          </iColumn>
        </iRow>
      </template>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { mapActions, mapWritableState } from "pinia";
import { useWaitlistStore } from "@/stores/waitlist-store";

export default {
  name: "WaitlistBrandSurvey",
  components: {
    PageWrapper,
  },
  props: {
    brands: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalOpen: false,
      brandFields: [
        { key: "brand_name", label: "Brand", width: "20%" },
        { key: "reason", label: "Notes", width: "80%" },
        { key: "actions", label: "", width: "140px" },
      ],
    };
  },
  computed: {
    ...mapWritableState(useWaitlistStore, ["brandSurveyBrandName", "brandSurveyReason", "isLoading"]),
    ...mapWritableState(useWaitlistStore, { storedBrands: "brands" }),
    incompleteForm() {
      if (this.brandSurveyBrandName && this.brandSurveyReason) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.storedBrands = this.brands;
  },
  methods: {
    ...mapActions(useWaitlistStore, ["submitBrandSurvey", "removeBrand"]),
  },
};
</script>

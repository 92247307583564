<template>
  <iRow gap="extraLarge" width="hug">
    <iRow>
      <iSpace :height="16" :width="100" background-color="striking" />
    </iRow>
    <iRow>
      <iHeading class="splash-column-heading" font-size="displayVerySmall">
        {{ heading }}
      </iHeading>
    </iRow>
    <iRow width="fill">
      <iParagraph class="splash-column-text" variant="subtle">
        <slot />
      </iParagraph>
    </iRow>
  </iRow>
</template>

<script>
export default {
  name: "SplashTextColumn",
  props: {
    heading: {
      type: String,
      required: true,
    },
  },
  styleGuide: () => ({
    headerFontWeight: { "font.weight": "dark" },
    boldTextFontWeight: { "font.weight": "dark" },
    textFontColor: { "color.font": "informational" },
    desktopFontSize: { "size.font": "extraLarge" },
    tabletFontSize: { "size.font": "large" },
    mobileFontSize: { "size.font": "standard" },
  }),
};
</script>

<style scoped lang="scss">
.splash-column-heading {
  font-weight: v-bind("$getStyles.headerFontWeight");
  @include phone {
    padding: 0 10px;
  }
}
.splash-column-text {
  color: v-bind("$getStyles.textFontColor");
  @include phone {
    padding: 0 10px;
  }
}
:deep(strong) {
  font-weight: v-bind("$getStyles.headerFontWeight");
}
:deep(.i-paragraph) {
  font-size: v-bind("$getStyles.desktopFontSize");

  @include tablet {
    font-size: v-bind("$getStyles.tabletFontSize");
  }

  @include phone {
    font-size: v-bind("$getStyles.mobileFontSize");
  }
}
</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form[data-v-e0286824] {
  margin: 0 auto;
  padding: 0 10px 10px 10px;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
.auth-form div[data-v-e0286824] {
  width: 600px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/Auth.vue","webpack://./Auth.vue"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,yBAAA;EAEA,gBAAA;EACA,uBAAA;EACA,MAAA;EACA,UAAA;ACFF;ADGE;EACE,YAAA;EACA,cAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.auth-form {\n  margin: 0 auto;\n  padding: 0 10px 10px 10px;\n\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n  div {\n    width: 600px;\n    margin: 0 auto;\n  }\n}\n",".auth-form {\n  margin: 0 auto;\n  padding: 0 10px 10px 10px;\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n}\n.auth-form div {\n  width: 600px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

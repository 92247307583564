<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_NAVIGATION_CONFIG"
    title="Primary Navigation Menu"
    :breadcrumbs="breadcrumbs"
    page-name="Navigation"
    align="left"
    width="full"
  >
    <iColumn :width="600" align="right">
      <iRow width="hug">
        <iButton variant="secondary" @click="addMenuItem">
          Add Nav Item
        </iButton>
      </iRow>
      <iRow>
        <iSortable v-if="config.menu_items" v-model="config.menu_items">
          <template #draggable-item="{menuItem, index}">
            <iRow
              align="between"
              class="menu-item-config"
              width="hug"
              gap="large"
            >
              <iColumn width="hug">
                <iText>{{ menuItem.label }}</iText>
              </iColumn>
              <iColumn width="hug">
                <iText>{{ menuItem[menuItem.type.name] }}</iText>
              </iColumn>
              <iColumn v-if="menuItem.name !== 'site-home'" width="hug">
                <iDropdown :show-dropdown="isDropdownOpen(index)" position="left" @close="openDropdownIndex=-1">
                  <template #trigger>
                    <iIcon icon="dots-vertical" @click="openDropdownIndex = index" />
                  </template>
                  <template #overlay>
                    <iColumn>
                      <iButton variant="tertiary" @click="editMenuItem(index)">
                        Edit
                      </iButton>
                      <iButton v-if="menuItem.enabled" variant="tertiary" @click="menuItem.enabled=false">
                        Disable
                      </iButton>
                      <iButton v-else variant="tertiary" @click="menuItem.enabled = true">
                        Enable
                      </iButton>
                      <iButton variant="tertiary" @click="removeMenuItem(index)">
                        Remove
                      </iButton>
                    </iColumn>
                  </template>
                </iDropdown>
              </iColumn>
            </iRow>
          </template>
        </iSortable>
      </iRow>
    </iColumn>
    <iButton :disabled="!hasUpdates" :is-loading="isLoading" @click="savePublicationConfig">
      Save
    </iButton>
    <iModal
      :visible="isEditModalOpen"
      title="Add Nav Menu Item"
      primary-action-button-label="Save"
      width="hug"
      @close="closeEditModal"
    >
      <template #body>
        <iColumn>
          <iRow>
            <iTextInput v-model="selectedMenuItem.label" placeholder="Display Text" />
          </iRow>
          <iRow v-if="selectedMenuItem.type.name == STATIC_MENU_ITEM_TYPE.name">
            <iTextInput v-model="selectedMenuItem.static" label="static link" />
            <iText variant="subtle">
              Enter a url for this page, you will be able to edit that page later
            </iText>
          </iRow>
          <iRow v-else-if="selectedMenuItem.type.name == HASHTAG_MENU_ITEM_TYPE.name">
            <iTextInput v-model="selectedMenuItem.hashtag" label="hashtag" />
            <iText variant="subtle">
              This landing page will show all posts tagged with the given hashtag
            </iText>
          </iRow>
        </iColumn>
      </template>
      <template #footer>
        <iRow align="right">
          <iButton variant="secondary" @click="closeEditModal">
            Cancel
          </iButton>
          <iButton variant="primary" @click="saveEditMenuItem">
            Add To Navigation
          </iButton>
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_NAVIGATION_CONFIG, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { preventTabClose, allowTabClose } from "@/helpers/prevent-tab-close.js";
import PageWrapper from "@/components/PageWrapper";
import _ from "lodash";
const MenuOrientations = [
  "horizontal",
  "vertical",
];
const STATIC_MENU_ITEM_TYPE = {
  name: "static",
  text: "static page",
};
const HASHTAG_MENU_ITEM_TYPE = {
  name: "hashtag",
  text: "a list of posts",
};

export default {
  name: "PublicationNavigationConfig",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_NAVIGATION_CONFIG,
      SECTION_PUBLICATIONS,
      menuItemModalOpen: false,
      isEditModalOpen: false,
      selectedMenuItemIndex: 0,
      initialUpdate: true,
      updated: false,
      selectedMenuItem: {},
      openDropdownIndex: -1,
      STATIC_MENU_ITEM_TYPE,
      HASHTAG_MENU_ITEM_TYPE,
      config: {
        menuName: "Primary Navigation",
        menuOrientation: MenuOrientations[0],
        menuItems: [],
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId", "publication", "configuration", "isLoading"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    isDropdownOpen() {
      return index => {
        if (this.openDropdownIndex === index) {
          return true;
        }
        return false;
      };
    },
    actionValue() {
      return this.getActionValue;
    },
    menuItemTypes() {
      return [
        STATIC_MENU_ITEM_TYPE,
        HASHTAG_MENU_ITEM_TYPE,
      ];
    },
    menuOrientations() {
      return MenuOrientations.map(key => ({ name: key }));
    },
    menuItems() {
      let baseUri = `/my-publication/${this.publicationId}/staging`;
      return this.config.menu_items.filter(item => item.enabled).map(item => {
        return { ...item, actionValue: baseUri + (item.type === STATIC_MENU_ITEM_TYPE ? item.actionValue : this.hashtagLink(item.actionValue)) };
      });
    },
    hasUpdates() {
      return this.updated;
    },
  },
  styleGuide: () => ({
    menuItemPaddingTop: { "size.spacing": "small" },
    menuItemPaddingBottom: { "size.spacing": "small" },
    menuItemPaddingLeft: { "size.spacing": "extraLarge" },
    menuItemPaddingRight: { "size.spacing": "small" },
  }),
  watch: {
    "config.menu_items": {
      handler() {
        if (this.initialUpdate) {
          this.initialUpdate = false;
        } else {
          this.updated = true;
          this.preventTabClose("You have unsaved changes to your Primary Navigation. If you leave your changes will be lost.");
        }
      },
      deep: true,
    },
  },
  created() {
    let primaryNav = this.configuration.navigation;
    this.config = { ...this.config, ...primaryNav };
    this.config.menu_items = this.config.menu_items.map(menuItem => {
      let hashtag = "my-tagged-posts";
      let staticUrl = "/enter-page-url-here";
      let type = this.getMenuItemTypeObject(menuItem.type);
      menuItem[type.name] = this.parseActionValue(menuItem);
      return {
        hashtag,
        static: staticUrl,
        ...menuItem,
        type,
      };
    });
  },
  methods: {
    preventTabClose,
    allowTabClose,
    ...mapActions(usePublicationStore, ["saveConfiguration"]),
    getMenuItemTypeObject(type) {
      return this.menuItemTypes.find(menuItemType => menuItemType.name === type.name || menuItemType.name == type);
    },
    parseActionValue(menuItem) {
      let actionValue = menuItem.actionValue;
      let type = menuItem.type.name || menuItem.type;
      if (type !== HASHTAG_MENU_ITEM_TYPE.name) {
        return actionValue;
      } else {
        if (actionValue.includes("/hashtag/")) {
          let result = actionValue.replace("/hashtag/", "");
          return result;
        }
        return actionValue;
      }
    },
    tagLink(hashtag) {
      if (hashtag.includes("/hashtag/")) {
        return hashtag;
      }
      hashtag = hashtag.replace(/[^a-zA-Z0-9_]/g, "");
      return `/hashtag/${hashtag.toLowerCase()}`;
    },
    async savePublicationConfig() {
      let config = _.cloneDeep(this.config);
      let menuItems = config.menu_items.map(menuItem => {
        let actionValue = this.getActionValue(menuItem);
        if (menuItem.type.name == HASHTAG_MENU_ITEM_TYPE.name) {
          actionValue = this.tagLink(actionValue);
        }
        let name = menuItem.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "_");
        return {
          name: name,
          enabled: menuItem.enabled,
          label: menuItem.label,
          type: menuItem.type.name,
          actionValue,
        };
      });
      config.menu_items = menuItems;
      this.allowTabClose();
      this.saveConfiguration("navigation", config);
    },
    getActionValue(menuItem) {
      return menuItem[menuItem.type.name];
    },
    editMenuItem(menuItem) {
      this.selectedMenuItemIndex = menuItem;
      this.selectedMenuItem = _.cloneDeep(this.config.menu_items[this.selectedMenuItemIndex]);
      this.isEditModalOpen = true;
    },
    closeEditModal() {
      this.isEditModalOpen = false;
      this.selectedMenuItemIndex = -1;
      this.selectedMenuItem = {};
      this.config.menu_items = this.config.menu_items.filter(item => !item.draft);
    },
    addMenuItem() {
      this.config.menu_items.push({
        name: "",
        label: `Item ${this.config.menu_items.length + 1}`,
        static: "/enter-page-url-here",
        hashtag: "my-tagged-posts",
        type: HASHTAG_MENU_ITEM_TYPE,
        enabled: true,
        draft: true,
      });
      this.editMenuItem(this.config.menu_items.length - 1);
    },
    removeMenuItem(index) {
      this.config.menu_items.splice(index, 1);
      this.openDropdownIndex = -1;
    },
    saveEditMenuItem() {
      let actionValue = this.getActionValue(this.selectedMenuItem);
      if (this.selectedMenuItem.type.name == HASHTAG_MENU_ITEM_TYPE.name) {
        let result = this.tagLink(actionValue);
        this.selectedMenuItem.actionValue = result;
        this.selectedMenuItem.hashtag = this.parseActionValue(this.selectedMenuItem);
        delete this.selectedMenuItem.draft;
      }
      this.selectedMenuItem.name = this.selectedMenuItem.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "_");
      this.config.menu_items[this.selectedMenuItemIndex] = this.selectedMenuItem;
      this.closeEditModal();
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.button-container){
  width: fit-content!important;
}
</style>

<template>
  <iDropdown :show-dropdown="showMenuOptions" position="left" @close="hideMenu">
    <template #trigger>
      <iRow vertical-align="middle" @click="showMenu">
        <iIcon icon="ellipsis" :is-loading="isLoading" />
      </iRow>
    </template>
    <template #overlay>
      <iColumn gap="small">
        <iButton v-if="showEditButton" variant="tertiary" @click="turnOnIsEditing">
          Edit
        </iButton>
        <iButton v-if="!isAuthor" variant="tertiary" @click="showReportsMenu">
          Report
        </iButton>
        <iButton v-if="showBlockButton" variant="tertiary" @click="blockComment">
          Block
        </iButton>
        <iButton v-if="settings.allowsSharing && !isSharing" variant="tertiary" @click="showSocialShareButtons">
          <iIcon icon="arrow-curved-right" />
        </iButton>
        <iButton v-if="showDeleteButton" variant="tertiary" @click="deleteComment">
          Delete
        </iButton>
        <iButton v-if="showUndeleteButton" variant="tertiary" @click="unDeleteComment">
          Undelete
        </iButton>
      </iColumn>
      <iModal
        :visible="isShowingReportingOptions"
        title="Report This Message"
        :show-footer="false"
        @close="closeReportsModal"
        @blur="closeReportsModal"
      >
        <template #body>
          <iColumn type="none" :ordered="true">
            <iRow
              v-for="(offense, index) in OFFENSE_OPTIONS"
              :key="index"
            >
              <iButton
                variant="secondary"
                @click="report(offense.value)"
              >
                {{ offense.name }}
              </iButton>
            </iRow>
          </iColumn>
        </template>
      </iModal>
    </template>
  </iDropdown>
</template>

<script>
import { mapWritableState } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { OFFENSE_OPTIONS } from "@/constants/conversations-constants";

export default {
  name: "ConversationMenu",
  props: {
    messageId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    isModerator: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    isAuthor: {
      type: Boolean,
      required: true,
    },
    moderationEnabled: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "delete",
    "undelete",
    "block",
    "approve",
    "report",
    "edit",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      showMenuOptions: false,
      isShowingReportingOptions: false,
    };
  },
  computed: {
    ...mapWritableState(useConversationStore, ["loadingMessages"]),
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationEnabled && this.moderationStatus === "approved";
    },
    showDeleteButton() {
      return this.isAuthor && this.status != "inactive";
    },
    showUndeleteButton() {
      return this.isAuthor && this.status === "inactive";
    },
    showEditButton() {
      return this.settings.allowsEditing && this.isAuthor && this.status === "active";
    },
  },
  methods: {
    turnOnIsEditing() {
      this.hideMenu();
      if (!this.isAuthor) {
        return;
      }
      this.$emit("edit");
    },
    showMenu() {
      this.showMenuOptions = true;
    },
    hideMenu() {
      this.showMenuOptions = false;
    },
    showReportsMenu() {
      this.isShowingReportingOptions = true;
    },

    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.hideMenu();
      this.$emit("undelete");
    },
    report(reason) {
      this.isShowingReportingOptions = false;
      this.hideMenu();
      this.$emit("report", reason);
    },
    closeReportsModal() {
      this.isShowingReportingOptions = false;
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block", this.OFFENSE_OPTIONS[0].value);
    },
    showSocialShareButtons() {
      this.isSharing = true;
      this.hideMenu();
    },
  },
};
</script>

<style scoped lang="scss"></style>

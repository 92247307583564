<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    :page-name="publication.name"
    align="left"
    sub-title=""
    width="full"
  >
    <ProfileWrapper
      :logo-image="publicationLogo"
      :splash-image="publicationSplashImage"
      :title="publication.name"
    >
      <template #details>
        <iColumn gap="extraSmall">
          <iSpace :height="1" />
          <iLink>{{ domainUrl }}</iLink>
          <iText>{{ "Status: " + mappedPublicationStatus }}</iText>
        </iColumn>
      </template>
      <iStyledCard>
        <template #description>
          <iColumn gap="extraLarge">
            <iRow>
              <ApplyToCollaborate
                :org-id="publication.org_id"
                :publication-id="publication.publication_id"
                show-explanation-text
                show-title
              />
            </iRow>
            <iColumn v-if="collaborators.length" gap="standard">
              <iSubHeading font-weight="dark">
                Collaborators
              </iSubHeading>
              <iRow gap="large" wrap="wrap">
                <PublicationCollaboratorCard
                  v-for="collaborator in collaborators"
                  :key="collaborator.applicant_id"
                  :collaborator="formatCollaborator(collaborator)"
                />
              </iRow>
            </iColumn>
          </iColumn>
        </template>
      </iStyledCard>
    </ProfileWrapper>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";

export default {
  name: "PublicationProfile",
  components: {
    ProfileWrapper,
    PublicationCollaboratorCard,
    ApplyToCollaborate,
    PageWrapper,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      PAGE_COMMUNITY_COMMUNITY,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publication",
      "publicationSplashImage",
      "publicationLogo",
    ]),
    domainUrl() {
      if (this.publication.domain) {
        if (this.publication.domain.startsWith("http", 0)) {
          return this.publication.domain;
        }
        return "https://" + this.publication.domain;
      }
      return this.internalDomain;
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useCollaborationStore, [
      "collaborators",
    ]),
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Under Construction";
        default:
          return "Offline";
      }
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "openApplyToCollaborateModel",
    ]),
    formatCollaborator(collaborator) {
      return {
        id: collaborator.applicant_id,
        name: collaborator.name,
        role: collaborator.role || "",
        status: collaborator.status,
        email: collaborator.email,
        avatar: collaborator.avatar || "",
        orgId: collaborator.org_id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

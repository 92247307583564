<template>
  <div @click="dropHammer">
    <div class="coming-soon-text">
      Coming Soon
    </div>
    <img
      :class="[{'animated': animateHammer, 'rotate-bottom-left': animateHammer}, 'hammer-image']"
      src="/images/hammer-color.svg"
      alt="hammer"
    >
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  data() {
    return {
      animateHammer: false,
    };
  },
  methods: {
    dropHammer() {
      if (this.animateHammer) {
        document.getAnimations().forEach(animation => {
          animation.cancel();
          animation.play();
        });
      }
      this.animateHammer = true;
    },
  },
};
</script>

<style scoped lang="scss">
.coming-soon-text {
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.hammer-image {
  width: 25%;
  margin: auto;
  justify-content: center;
  display: flex;
}

.animated {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes rotate-bottom-left {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
  }
  50% {
    transform-origin: left bottom;
    transform: rotate(45deg);
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
  }
}
//eslint-disable vue-scoped-css/no-unused-selector
@-webkit-keyframes rotate-bottom-left {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
  }
  50% {
    transform-origin: left bottom;
    transform: rotate(45deg);
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
  }
}

.rotate-bottom-left {
  -webkit-animation-name: rotate-bottom-left;
  animation-name: rotate-bottom-left;
}
</style>

<template>
  <PageWrapper :active-page="PAGE_WITHDRAWAL_HISTORY" :active-section="SECTION_DIGITAL_WALLET">
    <Tipalti />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { PAGE_WITHDRAWAL_HISTORY, SECTION_DIGITAL_WALLET } from "@/constants/nav-constants";
import Tipalti from "@/components/Tipalti";

export default {
  name: "WithdrawalHistory",
  components: {
    Tipalti,
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_DIGITAL_WALLET,
      PAGE_WITHDRAWAL_HISTORY,
    };
  },
};
</script>

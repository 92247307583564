<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_DISPLAY_ADS">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_DISPLAY_ADS } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "DisplayAds",
  components: {
    ComingSoon,
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_DISPLAY_ADS,
    };
  },
};
</script>

<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_BROWSER_PLUGIN">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_BROWSER_PLUGIN } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "BrowserPlugins",
  components: {
    ComingSoon,
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_BROWSER_PLUGIN,
    };
  },
};
</script>

<template>
  <iStyledCard
    class="card"
    :width="280"
    :height="260"
    :description-length="150"
    pointer-cursor-on-hover
    :focused="focused"
    single-column-metrics
    :metrics="metrics"
    @click="$emit('select')"
  >
    <template #title>
      <iSubHeading>{{ title }}</iSubHeading>
    </template>
    <template #description>
      <iText font-size="small">
        {{ description }}
      </iText>
      <iSpace :height="1" />
    </template>
  </iStyledCard>
</template>

<script>
export default {
  name: "PublicationSettingsCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
      validator: value => {
        return value.every(feature => typeof feature.name === "string" &&
            typeof feature.enabled === "boolean");
      },
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  computed: {
    metrics() {
      return this.features.map(feature => ({
        name: feature.name,
        icon: feature.enabled ? "check-circle" : "close",
        iconVariant: feature.enabled ? "branded" : "error",
      }));
    },
  },
};
</script>

<style scoped>
.card {
  padding: 8px;
}
</style>

<template>
  <ConversationHeader
    :author-name="`${userData.first_name} ${userData.last_name}`"
    :author-avatar="userData.avatar_url"
    :show-date-sent="false"
    :author-id="userData.user_id"
    :moderation-enabled="moderationEnabled"
    :is-moderator="isModerator"
  />
  <iTextInput
    v-model="draftMessage"
    name="comment"
  />
  <iButton loading-spinner-position="inside" :is-loading="isMessageLoading && isMessageLoading.newMessage" @click="addComment">
    Send Message
  </iButton>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useConversationStore } from "@/stores/conversation-store";
import ConversationHeader from "@/components/conversations/ConversationHeader";

export default {
  name: "NewCommentMessage",
  components: {
    ConversationHeader,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(useGeneralStore, ["userData"]),
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["moderationEnabled", "isModerator", "isMessageLoading"]),
  },
  methods: {
    ...mapActions(useConversationStore, ["sendMessage"]),
    addComment() {
      this.sendMessage(this.conversationId, this.context, this.entityId);
      this.draftMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <iRow v-if="userLayoutChoices.length > 0" align="right">
    <iSelect v-model="layout" :items="iSelectLayoutChoices" return-value />
  </iRow>
  <iInfiniteScroll
    :has-more="smartFeedStore.hasMore"
    :is-loading="smartFeedStore.isLoading"
    height="fill"
    :scroll-threshold="500"
    @load-more="smartFeedStore.getSmartFeed()"
  >
    <component :is="wrapperComponent" v-bind="wrapperComponentProps">
      <SmartFeedItem
        v-for="feedItem in smartFeedStore.feed"
        :key="`${feedItem.type}-${feedItem.id}`"
        :entity-type="feedItem.type"
        :entity-id="feedItem.id"
        :layout="layout"
        :fields="smartFeedStore.tableFields"
        :show-unknown-entities-as-placeholder="showUnknownEntitiesAsPlaceholder"
      />
    </component>
  </iInfiniteScroll>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import SmartFeedItem from "@/components/smart-feed/SmartFeedItem";
import SmartFeedTable from "@/components/smart-feed/SmartFeedTable";
import { BaseSmartFeedEngine } from "@bloglovin/vue-framework";

export default {
  name: "SmartFeed",
  components: { SmartFeedTable, SmartFeedItem },
  props: {
    piniaStore: {
      type: Function,
      required: true,
    },
    userLayoutChoices: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultLayout: {
      type: Enum,
      options: [...BaseSmartFeedEngine.VALID_LAYOUT_TYPES],
      required: true,
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      layout: null,
      smartFeedStore: null,
    };
  },
  computed: {
    iSelectLayoutChoices() {
      return this.userLayoutChoices.map(userLayoutChoice => {
        return {
          id: userLayoutChoice,
          name: userLayoutChoice,
        };
      });
    },
    wrapperComponent() {
      if (this.layout === BaseSmartFeedEngine.LAYOUT_TABLE) {
        return SmartFeedTable;
      }
      return "iRow";
    },
    wrapperComponentProps() {
      if (this.layout === BaseSmartFeedEngine.LAYOUT_TABLE) {
        return { fields: this.smartFeedStore.tableFields };
      }
      return {};
    },
  },
  created() {
    this.smartFeedStore = this.piniaStore(this.$pinia);
    if (this.smartFeedStore.feed.length === 0) {
      this.smartFeedStore.getSmartFeed();
    }
    this.layout = this.defaultLayout;
  },
};
</script>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEMES"
    title="Create Theme"
  >
    <iColumn>
      <ThemeConfiguration v-model="innerTheme" :palette="palette" @has-errors="formHasErrors = $event" />

      <iSpace :height="10" />
      <iText v-if="formHasErrors" variant="error">
        Please correct the errors above before saving.
      </iText>
      <iRow>
        <iColumn width="hug">
          <iButton
            :is-loading="isLoadingPostTheme"
            loading-spinner-position="inside"
            :disabled="formHasErrors"
            variant="primary"
            @click="createTheme(innerTheme)"
          >
            Create
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iLink :href="`/publication/org/${orgId}/themes`">
            <iButton variant="secondary">
              Cancel
            </iButton>
          </iLink>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_PUBLICATIONS, PAGE_THEMES } from "@/constants/nav-constants";
import ThemeConfiguration from "@/components/ThemeConfiguration";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";

export default {
  name: "ThemesCreate",
  components: { ThemeConfiguration, PageWrapper },
  props: {
    theme: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEMES,
      innerTheme: this.theme,
      formHasErrors: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapState(useThemesStore, ["isLoadingPostTheme"]),
  },
  methods: {
    ...mapActions(useThemesStore, [
      "createTheme",
    ]),
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <iRow vertical-align="middle" align="between">
    <iColumn width="hug">
      <iRow width="hug">
        <iLink :href="authorProfile">
          <AvatarImage :src="isAuthor? userData.avatar_url : authorAvatar" size="35px" />
        </iLink>
        <iLink :href="authorProfile">
          <iText v-if="isAuthor">
            {{ userData.first_name }}
          </iText>
          <iText v-else>
            {{ authorName }}
          </iText>
        </iLink>
        <template v-if="date > 0">
          &bull;
          <iText>{{ relativeDateCreated }}</iText>
        </template>
      </iRow>
    </iColumn>
    <iColumn width="hug">
      <ConversationCollaboratorMenu
        v-if="isModerator"
        :moderation-enabled="moderationEnabled"
        :is-moderator="isModerator"
        :is-author="isAuthor"
      />
    </iColumn>
  </iRow>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import { getRelativeTimeString } from "@bloglovin/vue-component-library";
import ConversationCollaboratorMenu from "@/components/conversations/ConversationCollaboratorMenu";
import AvatarImage from "@/components/AvatarImage";
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";

export default {
  name: "ConversationHeader",
  components: {
    AvatarImage,
    ConversationCollaboratorMenu,
  },
  props: {
    date: {
      type: Number,
      required: false,
      default: 0,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorAvatar: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    dateEdited: {
      type: Number,
      required: false,
      default: Date.now(),
    },
    authorId: {
      type: Number,
      required: true,
    },
    authorProfile: {
      type: String,
      required: false,
      default: "",
    },
    moderationEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isModerator: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTimestampCreated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useGeneralStore, ["userId", "userData"]),
    relativeDateCreated() {
      let days = Number(getRelativeTimeString(this.date).split("days ago", 1)[0]);
      if (days > 7) {
        return dayjs.unix(this.date).format("DD MMMM YYYY");
      }
      return getRelativeTimeString(this.date);
    },
    isAuthor() {
      return this.userId == this.authorId;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      reportUser: "reportUser",
      blockUser: "blockUser",
    }),
    showMenuOptions() {
      this.showMenuOptions = false;
    },
    report(reason) {
      this.isShowingReportingOptions = false;
      this.reportUser(this.authorId, reason);
    },
    blockUser() {
      if (!this.isModerator) {
        return;
      }
      this.blockUser(this.authorId);
    },
  },
};
</script>

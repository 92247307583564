<template>
  <iColumn class="email-form" align="center">
    <SplashWrapper
      :hide-buttons="true"
      title="Forgot Password?"
    />

    <iRow :width="400">
      <iColumn gap="extraLarge">
        <iRow>
          <iText variant="subtle">
            Start by entering your email address
          </iText>
        </iRow>

        <iRow>
          <iColumn gap="large">
            <iTextInput
              ref="email"
              v-model="userAuthEmail"
              placeholder="hello@inspiration.co"
              name="email"
              width="fill"
              type="email"
            />

            <iButton
              v-if="!emailSent"
              variant="primary"
              :disabled="!hasValidUserAuthEmail"
              width="fill"
              @click="submitForgotPasswordForm"
            >
              Submit
            </iButton>
            <iButton
              variant="secondary"
              width="fill"
              @click="returnToPasswordForm"
            >
              Back
            </iButton>

            <iText v-if="emailSent">
              {{ confirm }}
            </iText>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
  </iColumn>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { SEND_EMAIL_TIMER_DURATION } from "@/constants/timer-constants";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "ForgotPasswordForm",
  components: { SplashWrapper },
  data() {
    return {
      resendTimer: 0,
      emailSent: false,
      emailError: null,
      resendEmailInterval: null,
    };
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "userAuthEmail",
      "userFlowSteps",
    ]),
    ...mapState(useUserStore, [
      "hasValidUserAuthEmail",
    ]),
    confirm: function () {
      if (this.emailSent) {
        return `An email has been sent to ${this.userAuthEmail}.` +
          `If you have not received the email you may click resend in ${this.resendTimer}(s)`;
      } else {
        return null;
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.resendEmailInterval);
  },
  // mounted() {
  //   if (this.$refs.email.focus) {
  //     this.$refs.email.focus();
  //   }
  // },
  methods: {
    ...mapActions(useUserStore, [
      "forgotPassword",
    ]),
    async submitForgotPasswordForm() {
      if (!this.hasValidUserAuthEmail) {
        return;
      }
      this.resetTimer();
      await this.forgotPassword(this.userAuthEmail);
      this.emailSent = true;
    },
    resetTimer() {
      this.resendTimer = SEND_EMAIL_TIMER_DURATION;
      this.resendEmailInterval = setInterval(this.timerTick, 1000);
    },
    timerTick() {
      this.resendTimer--;
      if (this.resendTimer <= 0) {
        this.resendTimer = 0;
        this.emailSent = false;
      }
    },
    returnToPasswordForm() {
      this.userFlowSteps.pop();
    },
  },
};
</script>

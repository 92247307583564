<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USER_ENGAGEMENT"
    title="Engagement"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Engagement"
    align="center"
    width="full"
  >
    <iText v-if="!isSignupAndLoginEnabled" variant="subtle">
      Login and Signup Must be enabled to access these features
    </iText>

    <iParagraph>
      You can control how users interact with your content. Toggle the settings
      below to enable or disable the features you want
    </iParagraph>

    <iColumn>
      <iRow vertical-align="middle">
        <iSubHeading>Liking posts</iSubHeading>
        <iToggleSwitch
          v-model="config.post.allows_likes"
          label=""
          :disabled="!isSignupAndLoginEnabled"
          @change="savePublicationConfig('post.allows_likes', $event)"
        />
      </iRow>
      <iText> Allow users to like your posts </iText>
    </iColumn>

    <iColumn>
      <iRow vertical-align="middle">
        <iSubHeading>Voting on posts</iSubHeading>
        <iToggleSwitch
          v-model="config.post.allows_upvote_and_downvote"
          label=""
          :disabled="!isSignupAndLoginEnabled"
          @change="
            savePublicationConfig('post.allows_upvote_and_downvote', $event)
          "
        />
      </iRow>
      <iText> Allow users to vote on your posts </iText>
    </iColumn>

    <iColumn>
      <iRow vertical-align="middle">
        <iSubHeading>Commenting on posts</iSubHeading>
        <iToggleSwitch
          v-model="config.comments.enabled"
          label=""
          :disabled="!isSignupAndLoginEnabled"
          @change="savePublicationConfig('comments.enabled', $event)"
        />
      </iRow>
      <iText>
        Your publications' posts can have comments, and those can have different
        interactions. Choose which ones of the interactions you want your fans
        and collaborators to use on the posts.
      </iText>
      <iRow vertical-align="middle">
        <iCheckbox
          v-model="config.comments.allows_likes"
          label="Allow users to like comments"
          :disabled="!isSignupAndLoginEnabled || !config.comments.enabled"
          @change="savePublicationConfig('comments.allows_likes', $event)"
        />
      </iRow>
      <iRow vertical-align="middle">
        <iCheckbox
          v-model="config.comments.allows_upvote_and_downvote"
          label="Allow users to vote on comments"
          :disabled="!isSignupAndLoginEnabled || !config.comments.enabled"
          @change="
            savePublicationConfig('comments.allows_upvote_and_downvote', $event)
          "
        />
      </iRow>
      <iRow vertical-align="middle">
        <iCheckbox
          v-model="config.comments.allows_replies"
          label="Allow users to reply to comments"
          :disabled="!isSignupAndLoginEnabled || !config.comments.enabled"
          @change="savePublicationConfig('comments.allows_replies', $event)"
        />
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import {
  PAGE_PUBLICATION_USER_ENGAGEMENT,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { PUBLICATION_COMMENTS_DEFAULT_CONFIG } from "@/constants/publication-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { merge } from "lodash";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationEngagement",
  components: { PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USER_ENGAGEMENT,
      SECTION_PUBLICATIONS,
      PUBLICATION_COMMENTS_DEFAULT_CONFIG,
      config: {
        post: {
          allows_likes: false,
          allows_upvote_and_downvote: false,
        },
        comments: {
          enabled: false,
          allows_likes: false,
          allows_upvote_and_downvote: false,
          allows_replies: false,
        },
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
      "publicationId",
      "configuration",
      "isLoadingConfig",
      "isSignupAndLoginEnabled",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  created() {
    const engagementConfig = this.configuration.engagement || {};
    this.config = merge({}, this.config, engagementConfig);
  },
  methods: {
    ...mapActions(usePublicationStore, {
      saveConfigurationValue: "saveConfigurationValue",
    }),
    async savePublicationConfig(key, value) {
      const configKey = `engagement.${key}`;
      await this.saveConfigurationValue(configKey, value);
    },
  },
};
</script>

<style scoped lang="scss"></style>

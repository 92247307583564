<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    align="left"
    page-name="Profile"
    sub-title=""
    width="fixed"
  >
    <ProfileWrapper
      :logo-image="orgProfileData.logo_url"
      :splash-image="orgProfileData.splash_url"
      :title="orgProfileData.name"
    >
      <template #details>
        <iText variant="subtle">
          {{ orgProfileData.org_description || "No description." }}
        </iText>
      </template>
      <iColumn gap="large">
        <iColumn v-if="hasAdvertiserCapability || hasPublicationCapability" gap="small">
          <iSubHeading font-size="extraLarge" variant="standard">
            Connect with {{ hasPublicationCapability ? "Creator" : "Advertiser" }}
          </iSubHeading>

          <iText v-if="hasExistingConversation">
            Continue your conversation with
            <iText variant="branded">
              {{ orgProfileData.name }}
            </iText>
            .
          </iText>
          <iText v-else>
            Start a conversation with
            <iText variant="branded">
              {{ orgProfileData.name }}
            </iText>
            .
          </iText>

          <iButton variant="secondary">
            Message
          </iButton>
        </iColumn>
        <iColumn v-if="hasPublicationCapability" gap="small">
          <iText>
            Invite
            <iText variant="branded">
              {{ orgProfileData.name }}
            </iText>
            to collaborate with you and build a publication together.
          </iText>

          <iButton variant="secondary">
            Invite
          </iButton>
        </iColumn>
        <iColumn v-if="hasAdvertiserCapability && orgProfileData.domains.length" gap="small">
          <iSubHeading font-size="extraLarge" variant="standard">
            Advertiser Websites
          </iSubHeading>
          <iText>
            Visit these websites to find products you may want to recommend to your followers.
          </iText>
          <iColumn gap="extraSmall">
            <iLink
              v-for="(domain, index) in orgProfileData.domains"
              :key="index"
              :href="formatDomain(domain.domain)"
              target="_blank"
            >
              {{ domain.domain }}
            </iLink>
          </iColumn>
        </iColumn>
        <iColumn gap="small">
          <iColumn>
            <iRow v-if="hasPublicationCapability" vertical-align="middle">
              <iImageContainer :height="50" src="/images/influencer-icon.png" />
              <iText>Creator</iText>
            </iRow>
            <iRow v-if="hasAdvertiserCapability" vertical-align="middle">
              <iImageContainer :height="50" src="/images/advertiser-icon.png" />
              <iText>Advertiser</iText>
            </iRow>
          </iColumn>
        </iColumn>
        <OrgTeam v-if="activeUsers.length > 0" :org-id="orgId" />
        <iDataTable
          v-if="isAdvertiser"
          :fields="fields"
          :has-more="false"
          :items="advertiserDetails.domains"
          primary-key="advertiser_org_id"
        >
          <template #td(domain)="{data}">
            <iLink :href="getNonRelativeDomain(data.domain)" target="_blank">
              {{ data.domain }}
            </iLink>
          </template>
          <template #td(rate)="{data}">
            {{ getPayoutCellString(data.rate) }}
          </template>
        </iDataTable>
        <iColumn v-if="orgProfilePublications.length > 0" gap="none">
          <iSubHeading>
            Publications
          </iSubHeading>
          <iDataTable
            :fields="publicationFields"
            :has-more="false"
            :items="orgProfilePublications"
            primary-key="publication_id"
          >
            <template #td(domain)="slotProps">
              <iLink :href="getNonRelativeDomain(slotProps.data.domain)" target="_blank">
                {{ slotProps.data.domain }}
              </iLink>
            </template>
            <template #td(actions)="slotProps">
              <ApplyToCollaborate
                :org-id="orgId"
                :publication-id="slotProps.data.publication_id"
                apply-button-text="Apply To Collaborate"
              />
            </template>
          </iDataTable>
        </iColumn>
        <InviteToPublication :org-id="orgId" :org-name="orgProfileData.name" on-platform />
      </iColumn>
    </ProfileWrapper>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { usePartnershipStore } from "@/stores/partnership-store";
import { usePublicationStore } from "@/stores/publication-store";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import OrgTeam from "@/components/directory/OrgTeam.vue";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";

export default {
  name: "OrgProfilePage",
  components: {
    ProfileWrapper,
    ApplyToCollaborate,
    OrgTeam,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_COMMUNITY,
      fields: [
        {
          key: "domain",
          label: "Domains",
        },
        {
          key: "rate",
          label: "Rate",
        },
      ],
      publicationFields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "domain",
          label: "Domain",
        },
        {
          key: "status",
          label: "Status",
        },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useOrganizationStore, [
      "loadingOrgDetails",
      "advertiserDetails",
      "loadingAdvertiserDetails",
      "orgProfileData",
      "hasPublicationCapability",
      "hasAdvertiserCapability",
      "activeUsers",
    ]),
    ...mapState(usePartnershipStore, [
      "advertiserDetails",
      "loadingAdvertiserDetails",
    ]),
    ...mapState(usePublicationStore, [
      "orgProfilePublications",
    ]),
    isAdvertiser() {
      return this.advertiserDetails.domains > 0;
    },
    activeServices() {
      return Object.values(this.orgProfileData.services_offered).filter(service => service.status === "active");
    },
    hasExistingConversation() {
      return false;
    },
  },
  created() {
    this.fetchAdvertiserDetails(this.orgId);
  },
  methods: {
    ...mapActions(usePartnershipStore, [
      "fetchAdvertiserDetails",
    ]),
    ...mapActions(useCollaborationStore, [
      "openInviteToPublicationModel",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    formatDomain(domain) {
      if (domain.startsWith("http", 0)) {
        return domain;
      }
      return `https://${domain}`;
    },
    getPayoutCellString(data) {
      if (data.payout_type === "percentage") {
        if (data.payout_value_min === data.payout_value_max) {
          return `${data.payout_value_max}%`;
        } else {
          return `${data.payout_value_min}-${data.payout_value_max}%`;
        }
      }

      if (data.payout_type === "amount") {
        if (data.payout_value_min === data.payout_value_max) {
          return `$${data.payout_value_max}`;
        } else {
          return `$${data.payout_value_min}-${data.payout_value_max}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_SUBSCRIPTIONS">
    <iHeading>
      Subscriptions
    </iHeading>
    <iRow>
      <iColumn v-if="getFirstPublication">
        <iHeading text="Publication: " />
        <iLink :href="`${getFirstPublication.urlSlug}`">
          {{ getFirstPublication.name }}
        </iLink>
      </iColumn>
      <iColumn v-else-if="hasMultiplePublications">
        <iHeading text="Publication: " />
        <iSelect
          v-model="publicationId"
          :items="publications"
          value-field="publication_id"
          text-field="name"
          @update:model-value="selectedPublication"
        />
      </iColumn>
      <iColumn v-if="hasSinglePublication">
        <iHeading>
          Publication Data:
        </iHeading>
        <iDataTable :items="[publication]" />
      </iColumn>
    </iRow>
    <iColumn width="fill">
      <iInfiniteScroll
        :height-offset="210"
        :has-more="hasMoreSubscriptions"
        :is-loading="isLoadingSubscriptions"
        @load-more="loadMore"
      >
        <iRow v-for="(subscription, index) in subscriptions" :key="index" width="fill">
          <SubscriptionCard :subscription="subscription" :org-id="orgId" />
        </iRow>
      </iInfiniteScroll>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import SubscriptionCard from "@/components/SubscriptionCard";
import { useSubscriptionStore } from "@/stores/subscription-store";
import { usePublicationStore } from "@/stores/publication-store";
import { SECTION_MONETIZATION, PAGE_SUBSCRIPTIONS } from "@/constants/nav-constants";

export default {
  name: "Subscriptions",
  components: {
    PageWrapper,
    SubscriptionCard,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_SUBSCRIPTIONS,
    };
  },
  computed: {
    ...mapState(useSubscriptionStore, [
      "subscriptions",
      "hasMoreSubscriptions",
      "isLoadingSubscriptions",
    ]),
    ...mapState(usePublicationStore, [
      "publications",
      "publication",
    ]),
    hasMultiplePublications() {
      return this.publications?.length > 1;
    },
    hasSinglePublication() {
      return this.publication && this.publication.publication_id;
    },
    getFirstPublication() {
      if (this.publications?.length === 1) {
        return { urlSlug: this.publications[0].url_slug, name: this.publications[0].name };
      }
      return null;
    },
  },
  created() {
    this.fetchPublications();
    this.fetchSubscriptions();
  },
  methods: {
    ...mapActions(useSubscriptionStore, [
      "fetchSubscriptions",
    ]),
    ...mapActions(usePublicationStore, [
      "fetchPublications",
      "fetchPublication",
    ]),
    selectedPublication() {
      this.fetchPublication(this.publicationId);
    },
    loadMore() {
      this.fetchSubscriptions();
    },
  },
};
</script>

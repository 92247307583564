<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_TEMPLATES">
    <iHeading>
      Publication Templates
    </iHeading>
    <iColumn>
      <iRow vertical-align="middle">
        <iCard @click="viewDetails({name: 'Current Configuration', configuration:currentConfig})">
          Current Configuration
        </iCard>
        <template v-if="selectedConfig">
          <iIcon icon="caret-right" />
          <iCard @click="viewDetails({name: `Selected - ${selectedConfig.name}`, configuration: selectedConfig.configuration})">
            Selected - {{ selectedConfig.name }}
          </iCard>
        </template>
      </iRow>
      <iButton @click="savePublicationConfig">
        Save
      </iButton>
      <iRow vertical-align="top">
        <iCard
          v-for="template, index in PUBLICATION_TEMPLATES"
          :key="index"
          class="template-card"
          @click="selectTemplate(template)"
        >
          <iColumn
            align="center"
            vertical-align="middle"
            :width="200"
            :height="150"
          >
            {{ template.name }}
            <iButton @click.stop="viewDetails(template)">
              View Details
            </iButton>
          </iColumn>
        </iCard>
      </iRow>
    </iColumn>
    <TemplateDetails
      v-if="viewConfig.configuration"
      :visible="isDetailsOpen"
      :configuration="viewConfig.configuration"
      :title="viewConfig.name"
      @close-details="closeConfigDetails"
    />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_TEMPLATES, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { PUBLICATION_TEMPLATES } from "@/constants/template-config-constants";

import PageWrapper from "@/components/PageWrapper";
import TemplateDetails from "@/components/TemplateDetails.vue";

export default {
  name: "PublicationTemplates",
  components: {
    PageWrapper,
    TemplateDetails,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_TEMPLATES,
      PUBLICATION_TEMPLATES,
      selectedConfig: {},
      viewConfig: {},
      isDetailsOpen: false,
    };
  },
  styleGuide: () => ({
    templateCardPadding: { "size.spacing": "large" },
  }),
  computed: {
    ...mapState(usePublicationStore, ["configuration"]),
    currentConfig() {
      return this.configuration;
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["saveConfigurationFromTemplate"]),
    async savePublicationConfig() {
      let config = this.selectedConfig.configuration;
      this.saveConfigurationFromTemplate(config);
    },
    viewDetails(template) {
      this.viewConfig = template;
      this.openConfigDetails();
    },
    openConfigDetails() {
      this.isDetailsOpen = true;
    },
    closeConfigDetails() {
      this.isDetailsOpen = false;
    },
    selectTemplate(template) {
      this.selectedConfig = template;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-card{
  padding: v-bind("$getStyles.templateCardPadding");
}
</style>

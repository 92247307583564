// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar[data-v-9c1095b8] {
  border-radius: 100%;
  height: var(--9c1095b8-size);
  width: var(--9c1095b8-size);
  border: var(--9c1095b8-borderStyle);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/AvatarImage.vue","webpack://./AvatarImage.vue"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,4BAAA;EACA,2BAAA;EACA,mCAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.avatar {\n  border-radius: 100%;\n  height: v-bind(size);\n  width: v-bind(size);\n  border: v-bind(borderStyle);\n}\n",".avatar {\n  border-radius: 100%;\n  height: v-bind(size);\n  width: v-bind(size);\n  border: v-bind(borderStyle);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-card[data-v-3453ffb6] {
  padding: var(--3453ffb6-\\\$getStyles\\.templateCardPadding);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/PublicationTemplates.vue","webpack://./PublicationTemplates.vue"],"names":[],"mappings":"AAEA;EACE,yDAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.template-card{\n  padding: v-bind(\"$getStyles.templateCardPadding\");\n}\n",".template-card {\n  padding: v-bind(\"$getStyles.templateCardPadding\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

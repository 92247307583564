// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form[data-v-23611925] {
  margin: 0 auto;
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/Invited.vue","webpack://./Invited.vue"],"names":[],"mappings":"AAEE;EACE,cAAA;EACA,eAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .auth-form {\n    margin: 0 auto;\n    padding: 0 10px;\n  }\n",".auth-form {\n  margin: 0 auto;\n  padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <iInfiniteScroll
    :has-more="hasMoreMessages"
    :is-loading="loadingMessages"
    :scroll-threshold="0"
    class="chatroom"
    scroll-direction="top"
    @load-more="loadMore"
  >
    <iSpace height="fill" />
    <iColumn class="chatroom-intro">
      <iSubHeading>
        #Chatroom
      </iSubHeading>
      <iRow gap="extraSmall">
        <iText variant="subtle">
          This chatroom was created automatically on {{ dateCreated }} for
        </iText>
        <iLink :href="internalDomain">
          @{{ publication.name }}.
        </iLink>
      </iRow>
      <iParagraph variant="subtle">
        This area is for internal discussion only.
      </iParagraph>
    </iColumn>
    <iParagraph v-if="!invertedMessages.length">
      No messages yet...
    </iParagraph>
    <iRow
      v-for="(message, index) in invertedMessages"
      v-show="invertedMessages.length"
      :key="index"
      width="fill"
    >
      <ActiveCommentWidget
        :author-avatar="message.avatarUrl"
        :author-id="message.userId"
        :author-name="message.authorName"
        :conversation-id="message.conversationId"
        :date-sent="message.timestampCreated"
        :date-updated="message.timestampUpdated"
        :message="message.message"
        :message-id="message.messageId"
        :moderation-score="message.moderationScore"
        :moderation-status="message.moderationStatus"
        :num-approved-replies="message.numApprovedReplies"
        :num-likes="message.numLikes"
        :num-reports="message.numReports"
        :replies="message.replies ? message.replies : []"
        :status="message.status"
        :user-down-voted="message.userDownVoted"
        :user-liked="message.userLiked"
        :user-up-voted="message.userUpVoted"
        :vote-score="message.voteScore"
      />
    </iRow>
    <iSpace :height="4" />
  </iInfiniteScroll>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import ActiveCommentWidget from "@/components/conversations/ActiveCommentWidget";
import { usePublicationStore } from "@/stores/publication-store";
import dayjs from "dayjs";

export default {
  name: "ChatroomThread",
  components: {
    ActiveCommentWidget,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "comment",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      newComment: "",
      currentSortingOption: "by_date_sent",
    };
  },
  computed: {
    ...mapState(useConversationStore, ["messages", "sortStrategy", "loadingMessages", "hasMoreMessages"]),
    ...mapState(usePublicationStore, ["publication", "publicationId"]),
    invertedMessages() {
      return this.messages.slice().reverse();
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    dateCreated() {
      return dayjs.unix(this.publication.date_created).format("MMMM D, YYYY");
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchMore: "fetchPublicationChatroom",
      deleteComment: "deleteComment",
      submitComment: "submitComment",
    }),
    loadMore() {
      this.fetchMore(this.entityId);
    },
  },
  styleGuide: () => ({
    borderBottomWidth: { "size.border": "thin" },
    borderBottomColor: { "color.border": "standard" },
  }),
};
</script>

<style lang="scss" scoped>
.chatroom {
  width: 100%;
}

:deep(.infinite-scroll) {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}

:deep(.v3-infinite-loading) {
  height: unset;
}

.chatroom-intro {
  border-bottom: v-bind('$getStyles.borderBottomWidth') solid v-bind('$getStyles.borderBottomColor');
}
</style>

/* eslint-disable */
function generateColorVariants(hex) {
  // Convert hex to RGB
  function hexToRgb(hex) {
    hex = hex.replace(/^#/, "");
    if (hex.length === 3) {
      hex = hex.split("").map(h => h + h).join("");
    }
    const num = parseInt(hex, 16);
    return [
      num >> 16,
      (num >> 8) & 0xff,
      num & 0xff
    ];
  }

  // Convert RGB to HSL
  function rgbToHsl([r, g, b]) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s;
    let l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [h * 360, s * 100, l * 100];
  }

  // Convert HSL to RGB
  function hslToRgb(h, s, l) {
    s /= 100;
    l /= 100;
    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = n => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
    return [
      Math.round(f(0) * 255),
      Math.round(f(8) * 255),
      Math.round(f(4) * 255)
    ];
  }

  // Convert RGB to hex
  function rgbToHex([r, g, b]) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
  }

  // Generate variants by adjusting the lightness
  function generateVariants(hsl) {
    const variants = {};
    const [h, s, l] = hsl;

    // Define the steps for lighter and darker shades
    const lightnessAdjustments = {
      100: l + 40,
      200: l + 30,
      300: l + 20,
      400: l, // Original color
      500: l - 10,
      600: l - 20,
      700: l - 30,
      800: l - 40,
      900: l - 50,
    };

    // Ensure lightness stays within the range 0-100
    Object.keys(lightnessAdjustments).forEach(key => {
      let lightness = Math.max(0, Math.min(100, lightnessAdjustments[key]));
      variants[key] = rgbToHex(hslToRgb(h, s, lightness));
    });

    return variants;
  }

  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb);
  const variants = generateVariants(hsl);
  variants[400] = hex.toUpperCase(); // Ensure 400 is the original color

  return variants;
}

export { generateColorVariants };
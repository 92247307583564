<template>
  <SplashWrapper
    :hide-buttons="true"
    :title="`Join ${orgName}`"
  >
    <iRow class="auth-form" align="center">
      <iLoadingSpinner v-if="isLoading" />
      <iColumn v-else class="sign-up-form" align="center">
        <iRow :width="400">
          <iColumn>
            <iForm :questions="[]" @submit.prevent="submitSignUp">
              <iRow>
                <iColumn gap="extraLarge">
                  <iRow>
                    <iHeading font-size="extraExtraLarge">
                      Join {{ orgName }}
                    </iHeading>
                  </iRow>

                  <iSpace :height="10" />

                  <iRow>
                    <iText variant="subtle">
                      Some text about joining an organization. ipsum dolor sit amet, consectetur adipiscing elit.
                    </iText>
                  </iRow>

                  <iTextInput
                    ref="firstname"
                    v-model="firstName"
                    placeholder="First name*"
                    name="lastname"
                    type="text"
                  />
                  <iTextInput
                    ref="lastname"
                    v-model="lastName"
                    placeholder="Last name*"
                    name="lastname"
                    type="text"
                  />
                  <iTextInput
                    v-model="password"
                    placeholder="Password*"
                    name="password"
                    type="password"
                  />

                  <iCheckbox
                    v-model="confirmAge"
                    required
                    label="I confirm that I am 18 years or older"
                    name="confirm-age"
                    :disabled="!validPassword"
                  />
                  <iCheckbox
                    v-model="acceptedTerms"
                    required
                    label="I agree to the terms and conditions and privacy policy"
                    name="terms"
                    :disabled="!confirmAge"
                  />

                  <PasswordComplexity
                    v-if="password"
                    :password="password"
                    @update:password="validatePasswordComplexity"
                  />

                  <iText v-if="hasError" :style-overrides="{fontColor: 'error'}">
                    {{ signUpError }}
                  </iText>
                  <iRow>
                    <iButton
                      type="submit"
                      variant="secondary"
                      width="fill"
                      :disabled="!isValidForm"
                      @click="submitAcceptInvitation"
                    >
                      Sign Up
                    </iButton>
                  </iRow>
                </iColumn>
              </iRow>
            </iForm>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
  </SplashWrapper>
</template>

<script>
import SplashWrapper from "@/components/splash/SplashWrapper";
import PasswordComplexity from "@/components/PasswordComplexity";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";

export default {
  name: "Invited",
  components: {
    PasswordComplexity, SplashWrapper,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    orgName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      acceptedTerms: false,
      confirmAge: false,
      firstName: "",
      lastName: "",
      password: "",
      validPassword: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "signupErrorMessage",
      "isLoading",
    ]),
    isValidForm() {
      return this.firstName && this.lastName && this.acceptedTerms && this.confirmAge && this.validPassword;
    },
    hasError() {
      return this.signupErrorMessage !== "";
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "acceptInvitation",
    ]),
    submitAcceptInvitation() {
      if (!this.isValidForm) {
        return;
      }
      this.acceptInvitation(this.userId, this.token, this.firstName, this.lastName, this.password, this.orgId);
    },
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
  },
};
</script>


<style lang="scss" scoped>
  .auth-form {
    margin: 0 auto;
    padding: 0 10px;
  }
</style>

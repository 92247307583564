<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_POSTS"
    title="Your Posts"
    :breadcrumbs="breadcrumbs"
    page-name="Posts"
    align="left"
    width="full"
    :action-button-href="`/publication/${publicationId}/posts/create`"
    :action-button-text="'Create Post'"
  >
    <iDataTable
      :items="posts"
      :fields="fields"
      primary-key="post_id"
      show-empty-state
      empty-string="No Posts."
      :has-more="hasMorePosts"
      hide-actions
      :is-loading="isLoadingPosts"
      @load-more="fetchMorePosts"
      @row-click="handleRowClick"
    >
      <template #td(post_title)="{data}">
        <iLink v-if="data.post_title" :href="`/publication/${data.publication_id}/post/${data.post_id}?view_type=view`">
          {{ data.post_title }}
        </iLink>
        <iText v-else variant="subtle">
          No Title
        </iText>
      </template>
      <template #td(status)="{data}">
        {{ data.status?.toUpperCase() }}
      </template>
      <template #td(text)="{data}">
        <iParagraph v-if="data.text">
          {{ truncate(data.text) }}
        </iParagraph>
        <iText v-else variant="subtle">
          No Content
        </iText>
      </template>
      <template #td(exclusive)="{data}">
        <iIcon
          disable-hover
          :icon="!!parseInt(data.exclusive) ? 'check-circle' : 'close'"
          :variant="!!parseInt(data.exclusive) ? 'branded' : 'error'"
        />
      </template>
      <template #td(thumbnail)="{data}">
        <iColumn>
          <iImageContainer v-if="data.thumbnail" :src="data.thumbnail" :height="100" />
          <iText v-else variant="subtle">
            No Thumbnail
          </iText>
        </iColumn>
      </template>
      <template #td(timestamp_created)="{data}">
        {{ formatUnixTo(data.timestamp_created) }}
      </template>
      <template #td(actions)="{data}">
        <iDropdownMenu
          :items="actionItems(data)"
          :show-dropdown="dropdownOpenFor === data.post_id"
          position="left"
          @close="closeDropdown"
          @click="this[$event](data)"
        >
          <template #trigger>
            <iIcon
              icon="dots-vertical"
              @click="openDropdown($event, data)"
            />
          </template>
        </iDropdownMenu>
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";

import {
  PAGE_PUBLICATION_POSTS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import dateUtilsMixin from "&/mixins/date-utils-mixin";
import { MenuConstants } from "@bloglovin/vue-component-library";
import { postStatus } from "@/enums/post-enums";

export default {
  name: "ViewPublicationPostList",
  components: { PageWrapper },
  mixins: [dateUtilsMixin],
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      postExclusivityLoading: null,
      dropdownOpenFor: null,
    };
  },
  computed: {
    ...mapWritableState(usePublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
      "publicationId",
      "isLoadingPosts",
      "hasMorePosts",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    fields() {
      return [
        { key: "post_title", label: "Title" },
        { key: "thumbnail", label: "Thumbnail" },
        { key: "status", label: "Status" },
        { key: "text", label: "Content" },
        { key: "exclusive", label: "Exclusive" },
        { key: "post_comment_count", label: "Comments" },
        { key: "timestamp_created", label: "Created At" },
        { key: "actions", label: "" },
      ];
    },
  },
  methods: {
    ...mapActions(usePublicationStore, [
      "fetchMorePosts",
      "updatePublicationPost",
    ]),
    actionItems(data) {
      const items = [];

      items.push({
        name: "view",
        label: "View",
        actionType: MenuConstants.ACTION_TYPE_LINK_IN_SAME_WINDOW,
        actionValue: `/publication/${data.publication_id}/post/${data.post_id}?view_type=view`,
      });

      items.push({
        name: "edit",
        label: "Edit",
        actionType: MenuConstants.ACTION_TYPE_LINK_IN_SAME_WINDOW,
        actionValue: `/publication/${data.publication_id}/post/${data.post_id}?view_type=edit`,
      });

      if (data.status === postStatus.ACTIVE) {
        items.push({
          name: "unpublish",
          label: "Unpublish",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "unpublishPost",
        });
      } else {
        items.push({
          name: "publish",
          label: "Publish",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "publishPost",
        });
      }

      return items;
    },
    publishPost(data) {
      this.updatePublicationPost(data.post_id, data.post_title, data.html, postStatus.ACTIVE, () => {
        this.updatePost(data.post_id, { ...data, status: postStatus.ACTIVE });
        this.closeDropdown();
      });
    },
    updatePost(postId, data) {
      this.posts = this.posts.map(p => {
        if (p.post_id === postId) {
          return data;
        }
        return p;
      });
    },
    unpublishPost(data) {
      this.updatePublicationPost(data.post_id, data.post_title, data.html, postStatus.INACTIVE, () => {
        this.updatePost(data.post_id, { ...data, status: postStatus.INACTIVE });
        this.closeDropdown();
      });
    },
    handleRowClick(data) {
      const item = this.posts.find(p => p.post_id === data);
      window.location.href = `/publication/${item.publication_id}/post/${item.post_id}?view_type=view`;
    },
    openDropdown(event, data) {
      event.stopPropagation();
      this.dropdownOpenFor = data.post_id;
    },
    closeDropdown() {
      this.dropdownOpenFor = null;
    },
    truncate(text) {
      return text.length > 80 ? text.substring(0, 80) + "..." : text;
    },
  },
};
</script>

<style scoped  lang="scss">
.heading {
  margin-bottom: 0;
}

.posts{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:2rem;
}
</style>

<template>
  <iColumn :width="width">
    <ConversationHeader
      :date="dateSent"
      :author-name="getAuthorName"
      :author-avatar="authorAvatar"
      :author-id="authorId"
      :moderation-enabled="moderationEnabled"
      :is-moderator="isModerator"
    />
    <MessageFlags
      v-if="isAuthor"
      :status="status"
      :moderation-status="moderationStatus"
      :timestamp-updated="dateUpdated"
    />
    <iRow v-if="isEditing || isEditSaving">
      <iColumn>
        <iTextInput
          v-model="commentEdit"
          name="editComment"
        />
        <iRow align="right" vertical-align="middle">
          <iColumn width="hug">
            <iButton variant="tertiary" @click="cancelEditing">
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton :is-loading="isEditSaving" loading-spinner-position="inside" @click="saveEdit">
              Save
            </iButton>
          </iColumn>
        </iRow>
      </iColumn>
    </iRow>
    <template v-else>
      <iRow>
        <iColumn>
          <iText>
            {{ message }}
          </iText>
        </iColumn>
      </iRow>
      <iText v-if="showAuthorPendingMessage" variant="subtle">
        Not visible, pending moderation.
      </iText>
      <iText v-if="showAuthorBlockedMessage" variant="subtle">
        This message has been blocked.
      </iText>
      <iText v-if="isMessageLoading[messageId]?.error" variant="subtle">
        {{ isMessageLoading[messageId].error }}
      </iText>
      <iRow v-if="isModerator && moderationEnabled" align="between">
        <iText variant="accent">
          Report Count: {{ numReports }}
        </iText>
        <iText variant="accent">
          moderation score: {{ moderationScore }}
        </iText>
      </iRow>
      <ConversationFooter
        :current-message="message"
        :author-id="authorId"
        :message-id="messageId"
        :vote-score="voteScore"
        :num-replies="numApprovedReplies"
        :num-likes="numLikes"
        :moderation-status="moderationStatus"
        :status="status"
        :user-liked="userLiked"
        :user-up-voted="userUpVoted"
        :user-down-voted="userDownVoted"
        @start-editing="turnOnIsEditing"
        @report="report"
        @reply="turnOnIsReplying"
        @approve="approve"
        @escalate="escalate"
        @block="block"
        @delete="deleteComment"
        @undelete="unDeleteComment"
        @like="like"
        @unlike="unlike"
        @upvote="upVote"
        @revokeupvote="revokeUpVote"
        @downvote="downVote"
        @revokedownvote="revokeDownVote"
      />
      <iRow v-if="isReplying">
        <iSpace :width="100" />
        <ReplyWidget
          :message="message"
          :message-id="messageId"
          :conversation-id="conversationId"
          :replies="replies"
        />
      </iRow>
    </template>
    <iRow>
      <iSpace :height="40" />
    </iRow>
  </iColumn>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "@/stores/conversation-store";
import { useGeneralStore } from "@/stores/general-store";
import ConversationFooter from "@/components/conversations/ConversationFooter";
import ConversationHeader from "@/components/conversations/ConversationHeader";
import MessageFlags from "@/components/conversations/MessageFlags";
import ReplyWidget from "@/components/conversations/ReplyWidget";
import { Enum } from "@bloglovin/vue-component-library";
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";

export default {
  name: "ActiveCommentWidget",
  components: {
    ConversationHeader,
    ConversationFooter,
    MessageFlags,
    ReplyWidget,
  },
  props: {
    width: {
      type: [Enum, Number],
      required: false,
      default: 500,
      options: ["fill", "hug"],
    },
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    dateSent: {
      type: Number,
      required: false,
      default: Date.now(),
    },
    numApprovedReplies: {
      type: Number,
      required: false,
      default: 0,
    },
    numLikes: {
      type: Number,
      required: false,
      default: 0,
    },
    voteScore: {
      type: Number,
      required: false,
      default: 0,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorAvatar: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    authorId: {
      type: Number,
      required: true,
    },
    allowsEditing: {
      type: Boolean,
      required: false,
      default: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    numReports: {
      type: Number,
      required: true,
    },
    moderationScore: {
      type: Number,
      required: true,
    },
    dateUpdated: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
    userLiked: {
      type: Boolean,
      required: true,
    },
    userUpVoted: {
      type: Boolean,
      required: true,
    },
    userDownVoted: {
      type: Boolean,
      required: true,
    },
  },
  styleGuide: () => ({
    borderColor: { "color.background": "light" },
  }),
  data() {
    return {
      isEditing: false,
      isReplying: false,
      commentEdit: "",
      newCommentReply: "",
      currentOffense: null,
      showMenuOptions: false,
      showReviewOptions: false,
      showReportOptions: false,
      likedComment: false,
      isSharing: false,
      bookmarkedComment: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "userData",
      "userAvatar",
    ]),
    ...mapState(useConversationStore, [
      "isModerator",
      "moderationEnabled",
      "isMessageLoading",
    ]),
    isEditSaving() {
      if (!this.isMessageLoading[this.messageId]) {
        return false;
      }
      if (this.isMessageLoading[this.messageId].edit) {
        return true;
      }
      return false;
    },
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    getAuthorName() {
      if (this.isAuthor) {
        return this.userData.first_name;
      }
      return this.authorName;
    },
    showAuthorPendingMessage() {
      return this.isAuthor && this.moderationStatus === "unknown";
    },
    showAuthorBlockedMessage() {
      return this.isAuthor && this.moderationStatus == "blocked";
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchMore: "fetchMore",
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      unLikeMessage: "unLikeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      upVoteMessage: "upVoteMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      downVoteMessage: "downVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
      fetchComments: "fetchComments",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      fetchRepliesToMessage: "fetchRepliesToMessage",
    }),
    turnOnIsEditing() {
      this.commentEdit = this.message;
      this.isEditing = true;
    },
    turnOnIsReplying() {
      this.isReplying = !this.isReplying;
    },
    saveEdit() {
      this.isEditing = false;
      this.editMessage(this.messageId, this.commentEdit);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment() {
      this.showMenuOptions = false;
      this.deleteMessage(this.messageId);
    },
    unDeleteComment() {
      this.showMenuOptions = false;
      this.undeleteMessage(this.messageId);
    },
    report(reason) {
      this.showReportOptions = false;
      this.reportMessage(this.messageId, reason);
    },
    reply(newMessage) {
      this.replyToMessage(this.messageId, newMessage);
    },
    like() {
      this.likeMessage(this.messageId);
    },
    unlike() {
      this.unLikeMessage(this.messageId);
    },
    upVote() {
      this.upVoteMessage(this.messageId);
    },
    revokeUpVote() {
      this.revokeUpVoteMessage(this.messageId);
    },
    downVote() {
      this.downVoteMessage(this.messageId);
    },
    revokeDownVote() {
      this.revokeDownVoteMessage(this.messageId);
    },
    block(reason) {
      this.blockMessage(this.messageId, reason);
    },
    approve() {
      this.approveMessage(this.messageId);
    },
    escalate() {
      this.escalateMessage(this.messageId);
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <PageWrapper :active-section="SECTION_COMMUNITY" :active-page="PAGE_MARKETPLACE">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import ComingSoon from "@/components/ComingSoon";
import { SECTION_COMMUNITY, PAGE_MARKETPLACE } from "@/constants/nav-constants";

export default {
  name: "Marketplace",
  components: { ComingSoon, PageWrapper },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_MARKETPLACE,
    };
  },
};
</script>

<style scoped lang="scss">

</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-accounts[data-v-5cedae0e] .i-card-header {
  background-color: #5E26FF;
}
.survey[data-v-5cedae0e] .i-card-header {
  background-color: #2663FF;
}
.friends[data-v-5cedae0e] .i-card-header {
  background-color: #C301C7;
}
.contact-us[data-v-5cedae0e] .i-card-header {
  background-color: #1DC701;
}
.contact-us[data-v-5cedae0e] svg {
  width: 22px;
}
.brands[data-v-5cedae0e] .i-card-header {
  background-color: #FCBD1C;
}
.brands[data-v-5cedae0e] svg {
  width: 26px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/waitlist/Waitlist.vue","webpack://./Waitlist.vue"],"names":[],"mappings":"AAGE;EACE,yBAAA;ACFJ;ADOE;EACE,yBAAA;ACJJ;ADSE;EACE,yBAAA;ACNJ;ADWE;EACE,yBAAA;ACRJ;ADUE;EACE,WAAA;ACRJ;ADaE;EACE,yBAAA;ACVJ;ADYE;EACE,WAAA;ACVJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.social-accounts {\n  :deep(.i-card-header) {\n    background-color: #5E26FF;\n  }\n}\n\n.survey {\n  :deep(.i-card-header) {\n    background-color: #2663FF;\n  }\n}\n\n.friends {\n  :deep(.i-card-header) {\n    background-color: #C301C7;\n  }\n}\n\n.contact-us {\n  :deep(.i-card-header) {\n    background-color: #1DC701;\n  }\n  :deep(svg){\n    width: 22px;\n  }\n}\n\n.brands {\n  :deep(.i-card-header) {\n    background-color: #FCBD1C;\n  }\n  :deep(svg){\n    width: 26px;\n  }\n}\n",".social-accounts :deep(.i-card-header) {\n  background-color: #5E26FF;\n}\n\n.survey :deep(.i-card-header) {\n  background-color: #2663FF;\n}\n\n.friends :deep(.i-card-header) {\n  background-color: #C301C7;\n}\n\n.contact-us :deep(.i-card-header) {\n  background-color: #1DC701;\n}\n.contact-us :deep(svg) {\n  width: 22px;\n}\n\n.brands :deep(.i-card-header) {\n  background-color: #FCBD1C;\n}\n.brands :deep(svg) {\n  width: 26px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

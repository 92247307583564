<template>
  <iCard
    :width="200"
    :style-overrides="{borderColor: 'dark', borderWidth: 'thin',}"
  >
    <template #header>
      <iRow class="card-header" vertical-align="middle" gap="small">
        <iIcon :icon="socialNetwork.toLowerCase()" />
        <iTitle>{{ socialNetwork }}</iTitle>
      </iRow>
    </template>
    <template #body>
      <iColumn>
        <iButton width="fill" variant="secondary" @click="$emit('connect')">
          Connect
        </iButton>
      </iColumn>
    </template>
  </iCard>
</template>

<script>
import { socialNetworkConnectionStatus } from "@/enums/social-network-enums";

export default {
  name: "SocialNetworkCard",
  props: {
    socialNetworkId: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    socialNetwork: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: socialNetworkConnectionStatus.NEVER_CONNECTED,
    },
  },
  emits: ["connect"],
  data() {
    return {
      socialPlatformConnectionStatus: socialNetworkConnectionStatus,
    };
  },
  styleGuide: () => ({
    connectionContainerBorderColor: { "color.border": "disabled" },
  }),
  computed: {
    socialNetworkStatus() {
      const connectedStatuses = [socialNetworkConnectionStatus.CONNECTED, socialNetworkConnectionStatus.MANUAL_CONNECTION];

      return connectedStatuses.includes(this.status) ? "Connected" : "Not Connected";
    },
    connectionStatusColor() {
      let color = "subtle";
      switch (this.status) {
        case socialNetworkConnectionStatus.CONNECTED:
        case socialNetworkConnectionStatus.MANUAL_CONNECTION:
          color = "success";
          break;
        case socialNetworkConnectionStatus.DISCONNECTED:
          color = "error";
          break;
        case socialNetworkConnectionStatus.NEVER_CONNECTED:
          color = "subtle";
          break;
      }

      return this.$getStyleValuePropertiesFromTheme("color.font")[color];
    },
  },
};
</script>

<style scoped lang="scss">
.connection {
  &-container {
    padding: 5px 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: v-bind('$getStyles.connectionContainerBorderColor');
  }

  &-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: v-bind(connectionStatusColor);
  }
}
</style>

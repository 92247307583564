// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper[data-v-f9088334] .primary-content {
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/PublicationChatRoom.vue","webpack://./PublicationChatRoom.vue"],"names":[],"mappings":"AAGE;EACE,kBAAA;ACFJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.page-wrapper {\n  :deep(.primary-content) {\n    overflow-y: scroll;\n  }\n}\n",".page-wrapper :deep(.primary-content) {\n  overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_DOMAIN_CONFIGURATION"
    title="Domain Configuration"
    :breadcrumbs="breadcrumbs"
    page-name="Domain Configuration"
    align="left"
    width="full"
  >
    <iColumn>
      <iTextEdit
        :value="publication.domain"
        label="Domain"
        :width="400"
        :is-loading="loadingUpdateDomain"
        variant="locked"
        @submit="submitUpdatedDomain"
      />
      <iText v-if="errorUpdateDomain" variant="error">
        {{ errorUpdateDomain }}
      </iText>
    </iColumn>
  </PageWrapper>
</template>

<script>

import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_DOMAIN_CONFIGURATION,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
export default {
  name: "PublicationDomainConfiguration",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_DOMAIN_CONFIGURATION,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["loadingUpdateDomain", "errorUpdateDomain", "publication", "orgId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["saveDomain"]),
    submitUpdatedDomain(domain) {
      this.saveDomain(domain);
    },
  },
};
</script>

<style scoped>
</style>

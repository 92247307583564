// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection-container[data-v-79afd9ae] {
  padding: 5px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--79afd9ae-\\\$getStyles\\.connectionContainerBorderColor);
}
.connection-status[data-v-79afd9ae] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--79afd9ae-connectionStatusColor);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/social-networks/SocialNetworkCard.vue","webpack://./SocialNetworkCard.vue"],"names":[],"mappings":"AAGE;EACE,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yEAAA;ACFJ;ADKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uDAAA;ACHJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.connection {\n  &-container {\n    padding: 5px 10px;\n    border-style: solid;\n    border-width: 1px;\n    border-radius: 8px;\n    border-color: v-bind('$getStyles.connectionContainerBorderColor');\n  }\n\n  &-status {\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: v-bind(connectionStatusColor);\n  }\n}\n",".connection-container {\n  padding: 5px 10px;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 8px;\n  border-color: v-bind(\"$getStyles.connectionContainerBorderColor\");\n}\n.connection-status {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: v-bind(connectionStatusColor);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <PageWrapper
    :org-id="orgId"
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_AFFILIATE_PLATFORM_SETTINGS"
    title="Affiliate Networks"
    page-name="Social Networks"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iColumn>
          <iRow>
            <iSubHeading>My Connections</iSubHeading>
          </iRow>

          <iRow>
            <iDataTable
              :items="connectedAffiliatePlatforms"
              :fields="affiliatePlatformsTableFields"
              primary-key="publisher_affiliate_platform_account_id"
              show-empty-state
              hide-actions
              :has-more="false"
              empty-string="No connections found."
              :is-loading="loadingPlatforms"
            >
              <template #td(display_name)="slotProps">
                <iRow vertical-align="middle">
                  <iIcon :icon="slotProps.data.display_name.toLowerCase()" />
                  <iText>{{ slotProps.data.display_name }}</iText>
                </iRow>
              </template>
              <template #td(status)="slotProps">
                <iRow vertical-align="middle">
                  <div
                    :class="{
                      dot: true,
                      '--color-success': [affiliatePlatformConnectionStatus.CONNECTED].includes(slotProps.data.status),
                      '--color-error': [
                        affiliatePlatformConnectionStatus.BROKEN_CONNECTION,
                        affiliatePlatformConnectionStatus.DISCONNECTED,
                        affiliatePlatformConnectionStatus.NEVER_CONNECTED
                      ].includes(slotProps.data.status),
                    }"
                  />
                  <iText>{{ getConnectionStatus(slotProps.data.status) }}</iText>
                </iRow>
              </template>
              <template #td(actions)="{data}">
                <iButton
                  v-if="isErrorStatus(data.status)"
                  variant="secondary"
                  size="small"
                  @click="currentAffiliatePlatform = data.affiliate_platform"
                >
                  Reconnect
                </iButton>
                <iButton
                  v-else
                  variant="secondary"
                  size="small"
                  @click="disconnectAffiliatePlatform(data.publisher_affiliate_platform_account_id)"
                >
                  Disconnect
                </iButton>
              </template>
            </iDataTable>
          </iRow>
        </iColumn>
      </iRow>

      <iRow v-if="affiliatePlatformCards.length > 0">
        <iColumn>
          <iRow>
            <iSubHeading>Add Connections</iSubHeading>
          </iRow>

          <iRow>
            <SocialNetworkCard
              v-for="affiliatePlatform in affiliatePlatformCards.slice(0, 4)"
              :key="affiliatePlatform['publisher_affiliate_platform_account_id']"
              :social-network="affiliatePlatform['display_name']"
              :social-network-id="affiliatePlatform['publisher_affiliate_platform_account_id']"
              account-name=""
              :status="affiliatePlatform['status']"
              @connect="openModal(affiliatePlatform['affiliate_platform'])"
            />
            <template
              v-if="viewAllAffiliatePlatforms"
            >
              <SocialNetworkCard
                v-for="affiliatePlatform in affiliatePlatformCards.slice(4)"
                :key="affiliatePlatform['publisher_affiliate_platform_account_id']"
                :social-network="affiliatePlatform['display_name']"
                :social-network-id="affiliatePlatform['publisher_affiliate_platform_account_id']"
                account-name=""
                :status="affiliatePlatform['status']"
                @connect="openModal(affiliatePlatform['affiliate_platform'])"
              />
            </template>
            <iButton
              v-if="affiliatePlatformCards.length > 4"
              variant="tertiary"
              @click="toggleViewAllAffiliatePlatforms"
            >
              {{ viewAllAffiliatePlatforms ? "View less" : "View all" }}
            </iButton>
          </iRow>
        </iColumn>
      </iRow>

      <template
        v-for="platform in affiliatePlatforms"
        :key="platform.affiliate_platform"
      >
        <PlatformDetailsModal
          v-if="currentAffiliatePlatform === platform.affiliate_platform"
          :platform="platform.affiliate_platform"
          @close="closeAndResetAddConnectionModal"
          @submit="connectAffiliatePlatform"
        />
      </template>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_SETTINGS,
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
} from "@/constants/nav-constants";
import SocialNetworkCard from "@/components/social-networks/SocialNetworkCard.vue";
import { affiliatePlatformConnectionStatus } from "@/enums/affiliate-platform-enums";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useAffiliatePlatformsStore } from "@/stores/affiliate-platforms-store";
import PlatformDetailsModal from "@/components/affiliate-platform/PlatformDetailsModal.vue";

export default {
  name: "AffiliatePlatforms",
  components: {
    PlatformDetailsModal,
    PageWrapper,
    SocialNetworkCard,
  },
  props: {},
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      affiliatePlatformsTableFields: [
        { key: "display_name", label: "Affiliate Platform", width: "40%" },
        { key: "status", label: "Status", width: "20%" },
        { key: "actions", label: "", width: "140px" },
      ],
      affiliatePlatformConnectionStatus,
      viewAllAffiliatePlatforms: false,
      isAddAffiliatePlatformModalVisible: false,
      currentAffiliatePlatform: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
      "settingsBreadcrumbs",
    ]),
    ...mapState(useAffiliatePlatformsStore, [
      "affiliatePlatforms",
      "loadingPlatforms",
    ]),
    affiliatePlatformCards() {
      let affiliatePlatformCards = this.affiliatePlatforms.reduce((acc, affiliatePlatformDetails) => {
        const containsAffiliatePlatform = acc.some(network => network.affiliate_platform === affiliatePlatformDetails.affiliate_platform);
        if (!containsAffiliatePlatform && affiliatePlatformDetails.status === affiliatePlatformConnectionStatus.NEVER_CONNECTED) {
          acc.push(affiliatePlatformDetails);
        }

        return acc;
      }, []);

      affiliatePlatformCards = affiliatePlatformCards.sort((a, b) => a.affiliate_platform.localeCompare(b.affiliate_platform));

      return affiliatePlatformCards;
    },
    connectedAffiliatePlatforms() {
      return this.affiliatePlatforms.filter(affiliatePlatform => affiliatePlatform.status !== affiliatePlatformConnectionStatus.NEVER_CONNECTED);
    },
  },
  methods: {
    ...mapActions(useAffiliatePlatformsStore, ["connect", "disconnect"]),
    getConnectionStatus(status) {
      switch (status) {
        case affiliatePlatformConnectionStatus.CONNECTED:
          return "Connected";
        case affiliatePlatformConnectionStatus.BROKEN_CONNECTION:
          return "Broken Connection";
        case affiliatePlatformConnectionStatus.DISCONNECTED:
        case affiliatePlatformConnectionStatus.NEVER_CONNECTED:
          return "Disconnected";
        default:
          return "Unknown";
      }
    },
    isErrorStatus(status) {
      return status !== affiliatePlatformConnectionStatus.CONNECTED;
    },
    toggleViewAllAffiliatePlatforms() {
      this.viewAllAffiliatePlatforms = !this.viewAllAffiliatePlatforms;
    },
    openModal(affiliatePlatform) {
      this.currentAffiliatePlatform = affiliatePlatform;
    },
    closeAndResetAddConnectionModal() {
      this.currentAffiliatePlatform = "";
    },
    connectAffiliatePlatform(details) {
      this.connect(this.currentAffiliatePlatform, details);
      this.closeAndResetAddConnectionModal();
    },
    disconnectAffiliatePlatform(affiliatePlatformAccountId) {
      this.disconnect(affiliatePlatformAccountId);
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.--color {
    &-success {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["success"]');
    }

    &-warning {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["accent"]');
    }

    &-error {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["error"]');
    }
  }
}
</style>

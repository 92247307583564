<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_MODERATION">
    <iHeading font-size="huge">
      Publication Moderation
    </iHeading>
    <iColumn v-if="config.allowModeraters">
      <iHeading> Assigned Moderators </iHeading>
      <!-- display list of users to set as a moderator -->
    </iColumn>
    <iButton @click="savePublicationConfig">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { usePublicationStore } from "@/stores/publication-store";
import {
  PAGE_PUBLICATION_MODERATION,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
export default {
  name: "PublicationModeration",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_MODERATION,
      SECTION_PUBLICATIONS,
      config: {
        allowModeraters: true,
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["configuration"]),
  },
  created() {
    this.config = { ...this.config, ...this.configuration.moderation };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("moderation", this.config);
    },
  },
};
</script>

<style scoped>
</style>

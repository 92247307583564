<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS"
    title="Your Digital Products"
    :breadcrumbs="breadcrumbs"
    page-name="Digital Products"
    align="left"
    width="full"
  >
    <iToggleSwitch v-model="config.enabled" label="Activate Digital Products" />
    <iButton @click="savePublicationConfig">
      Save
    </iButton>
    <iLink variant="button" :href="`/publication/${publicationId}/digital-products/create`">
      New Digital Product
    </iLink>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationDigitalProducts",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS,
      config: {
        enabled: false,
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId", "configuration"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/features`, label: "Features" },
      ];
    },
  },
  created() {
    this.config = { ...this.config, ...this.configuration.digital_products };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("digital_products", this.config);
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <PageWrapper :active-section="SECTION_HOME" :active-page="PAGE_HOME">
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_HOME, PAGE_HOME } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "Home",
  components: {
    ComingSoon,
    PageWrapper,
  },
  data() {
    return {
      SECTION_HOME,
      PAGE_HOME,
    };
  },
};
</script>

<style scoped>
</style>

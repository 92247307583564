<template>
  <iRow
    :class="{
      'splash-column-container': true,
      'invert-on-wrap': invertOnWrap,
    }"
    wrap="nowrap"
    :vertical-align="verticalAlign"
  >
    <iColumn class="left" width="fill">
      <slot name="left" />
    </iColumn>
    <iColumn class="right" width="fill" align="left">
      <slot name="right" />
    </iColumn>
  </iRow>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";

export default {
  name: "SplashColumnContainer",
  props: {
    verticalAlign: {
      type: Enum,
      required: false,
      default: "middle",
      options: ["top", "middle", "bottom"],
    },
    width: {
      type: [Number, String],
      required: false,
      default: "fill",
    },
    invertOnWrap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  styleGuide: () => ({
    gap: { "size.spacing": "gigantic" },
    gapMobile: { "size.spacing": "large" },
    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },
  }),
  computed: {
    myWidth() {
      if (this.width === "fill") {
        return "100%";
      }
      if (this.width === "hug") {
        return "fit-content";
      }

      return `${this.width}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.splash-column-container {
  max-width: v-bind(myWidth);

  padding-top: v-bind('$getStyles.paddingTop');
  padding-bottom: v-bind("$getStyles.paddingBottom");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");

  .left, .right {
    min-width: 350px;
  }
  gap:v-bind("$getStyles.gap");
  @include phone {
    gap:v-bind("$getStyles.gapMobile");
    flex-wrap: wrap;
    &.invert-on-wrap {
      flex-direction: column-reverse;
    }

    .left, .right {
      min-width: unset;
      align-items: center;
    }
  }
}
</style>

<template>
  <iRow>
    <iSubHeading>Settings</iSubHeading>
  </iRow>
  <iRow class="side-nav">
    <iMenu
      orientation="vertical"
      :active-item="activeItem"
      :items="filteredSettingsNav"
    />
  </iRow>
</template>

<script>
import { SIDE_NAV_SETTINGS } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { useGeneralStore } from "@/stores/general-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { mapState } from "pinia";
import { filterMenuItems } from "@/helpers/capabilities-helper";

export default {
  name: "SettingsSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, ["userId", "orgId"]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    parsedSettingsNav() {
      const filteredNavItems = filterMenuItems(SIDE_NAV_SETTINGS);
      return parseUrlVars(filteredNavItems, {
        "{user_id}": this.userId,
        "{org_id}": this.orgId,
      });
    },
    filteredSettingsNav() {
      return this.parsedSettingsNav.filter(item => {
        if (item.name === "page_collaborator_invitations_settings") {
          return this.getOrgData.waitlist_status === "approved";
        }
        return true;
      });
    },
  },
};
</script>

<template>
  <iStyledCard
    :title="org.name"
    :default-href="`/community/org/${org.org_id}/profile`"
    :splash-image="org.splash_url || DEFAULT_USER_AVATAR"
    :subtitle="`Joined at: ${formattedCreatedDate}`"
    :splash-image-max-height="200"
    :width="215"
    :splash-image-min-height="200"
    :description="org.org_description || 'No description available'"
  />
</template>

<script>
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";

export default {
  name: "OrgCard",
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DEFAULT_USER_AVATAR,
    };
  },
  computed: {
    formattedCreatedDate() {
      return this.formatDate(this.org.date_created);
    },
  },
  methods: {
    formatDate(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
};
</script>

<template>
  <SmartFeedTableRow :entity="entity" :fields="fields">
    <template #td(postTitle)>
      <iLink v-if="entity.postTitle" :href="`/stories/editor?post_id=${entity.postId}`">
        {{ entity.postTitle }}
      </iLink>
      <iText v-else variant="subtle">
        No Title
      </iText>
    </template>
    <template #td(status)>
      {{ entity.status?.toUpperCase() }}
    </template>
    <template #td(text)>
      <iParagraph v-if="entity.text">
        {{ truncate(entity.text) }}
      </iParagraph>
      <iText v-else variant="subtle">
        No Content
      </iText>
    </template>
    <template #td(exclusive)>
      <iIcon
          disable-hover
          :icon="entity.exclusive ? 'check-circle' : 'close'"
          :variant="entity.exclusive ? 'branded' : 'error'"
      />
    </template>
    <template #td(thumbnail)>
      <iColumn>
        <iImageContainer v-if="entity.thumbnail" :src="entity.thumbnail" :height="100" />
        <iText v-else variant="subtle">
          No Thumbnail
        </iText>
      </iColumn>
    </template>
    <template #td(timestampCreated)>
      {{ dateFromUnixTimestamp(entity.timestampCreated).toLocaleString() }}
    </template>
  </SmartFeedTableRow>
</template>
<script>

import SmartFeedTableRow from "@/components/smart-feed/SmartFeedTableRow";
import {dateFromUnixTimestamp} from "@bloglovin/vue-framework";

export default {
  name: "MyPostTableRow",
  components: { SmartFeedTableRow },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  methods: {
    dateFromUnixTimestamp,
    truncate(text) {
      return text.length > 80 ? text.substring(0, 80) + "..." : text;
    },
  },
};
</script>


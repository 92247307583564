<template>
  <PageWrapper
    :active-page="PAGE_INVITES"
    :active-section="SECTION_COMMUNITY"
    title="Refer a Friend"
    sub-title="Invite friends and earn rewards"
    :breadcrumbs="breadcrumbs"
    page-name="Refer a Friend"
    align="left"
    width="fixed"
  >
    <iColumn gap="large">
      <iRow>
        <iParagraph>
          Share your unique referral link with friends and invite them to join our platform.
          When they sign up using your link, you'll earn points towards exciting rewards!
        </iParagraph>
        <iParagraph>
          The more friends you invite, the more points you accumulate. Keep track of your
          referrals and see your progress towards unlocking exclusive benefits.
        </iParagraph>
      </iRow>
      <iRow>
        <iCopyText
          :text="formattedReferralLink"
          copy-message="Link Copied!"
          message-position="right"
        />
      </iRow>
      <iRow>
        <iCard :width="500">
          <template #body>
            <iColumn gap="standard">
              <iSubHeading>Your Referral Stats</iSubHeading>
              <iRow align="evenly">
                <iColumn align="center">
                  <iTitle>{{ referralCount }}</iTitle>
                  <iText>Friends Referred</iText>
                </iColumn>
                <iColumn align="center">
                  <iTitle>{{ pointsEarned }}</iTitle>
                  <iText>Points Earned</iText>
                </iColumn>
              </iRow>
              <iRow v-if="hasNavigatorShare">
                <iButton variant="primary" width="fill" @click="shareReferralLink">
                  Share Your Referral Link
                </iButton>
              </iRow>
            </iColumn>
          </template>
        </iCard>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_INVITES, SECTION_COMMUNITY } from "@/constants/nav-constants";

export default {
  name: "PlatformInvite",
  components: {
    PageWrapper,
  },
  props: {
    referralLink: {
      type: String,
      required: true,
    },
    referralCount: {
      type: Number,
      required: true,
    },
    pointsEarned: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_INVITES,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    formattedReferralLink() {
      return this.referralLink.replace(/\\/g, "");
    },
    hasNavigatorShare() {
      return navigator.share;
    },
  },
  methods: {
    copyReferralLink() {
      navigator.clipboard.writeText(this.formattedReferralLink);
    },
    shareReferralLink() {
      navigator.share({
        title: "Join me on our platform!",
        text: "I've been using this great platform. Join me and let's connect!",
        url: this.formattedReferralLink,
      });
    },
  },
};
</script>

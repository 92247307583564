<template>
  <iModal
    v-if="visible"
    title="Add a web site domain"
    :width="600"
    @close="closeModal"
  >
    <template #body>
      <iColumn gap="large" overflow="scroll">
        <iTextInput
          v-model="domain"
          label="Website Domain"
          width="fill"
          focus
        />
        <iCheckbox
          v-model="ignoreQueryParams"
          label="Ignore Query Params"
        />
      </iColumn>
    </template>
    <template #footer>
      <iRow width="fill" align="right">
        <iColumn width="hug">
          <iButton
            :disabled="!isFormValid"
            variant="primary"
            @click="submit"
          >
            {{ currentWebsiteIsEmpty ? "Add" : "Save" }}
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>

<script>
export default {
  name: "AddPublisherWebsiteModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentWebsite: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["close", "submit", "update"],
  data() {
    return {
      domain: "",
      ignoreQueryParams: false,
    };
  },
  computed: {
    currentWebsiteIsEmpty() {
      return !this.currentWebsite || Object.keys(this.currentWebsite).length === 0;
    },
    isFormValid() {
      return this.domain.length > 0;
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.domain = this.currentWebsite?.website_domain || "";
        this.ignoreQueryParams = this.currentWebsite?.ignore_query_params === "1";
      }
    },
  },
  methods: {
    closeModal() {
      this.domain = "";
      this.ignoreQueryParams = false;
      this.$emit("close");
    },
    submit() {
      const ignoreQueryParams = this.ignoreQueryParams ? 1 : 0;

      if (this.currentWebsite) {
        const websiteId = this.currentWebsite.publisher_website_id;
        const claimStatus = this.currentWebsite.claim_status;
        const status = this.currentWebsite.status;
        this.$emit("update", websiteId, this.domain, ignoreQueryParams, claimStatus, status);
      } else {
        this.$emit("submit", this.domain, ignoreQueryParams);
      }

      this.closeModal();
    },
  },
};
</script>

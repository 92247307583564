<template>
  <iLink :href="`/monetization/org/${orgId}/subscription/${subscription.subscription_plan_id}/details`">
    <iColumn gap="5" class="subscription-card-content">
      <iColumn>
        <iText>
          {{ subscription.subscription_plan_id }}
        </iText>
        <iHeading class="subscription-card-title">
          subscription.name
        </iHeading>
        <iText>
          Price: {{ subscription.price }} {{ subscription.billingFrequency }}
        </iText>
        <iText>
          Active Subscribers: {{ subscription.subscribers }}
        </iText>
        <iColumn>
          <iText>
            Monthly Recurring Revenue (MRR):
          </iText>
          <iText>
            {{ subscription.currency_code }} {{ subscription.monthly_recurring_revenue }}
          </iText>
        </iColumn>
        <iText>
          Status: {{ subscription.status }}
        </iText>
      </iColumn>
    </iColumn>
  </iLink>
</template>

<script>
export default {
  name: "SubscriptionCard",
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cardContentStyles: {
        marginLeft: "medium",
        marginRight: "medium",
        marginTop: "medium",
        marginDown: "medium",
      },
    };
  },
};

</script>

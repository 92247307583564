export const CURRENCY_CODES = [
  "USD",
];

export const MAPPED_CURRENCY_CODES = CURRENCY_CODES.map(key => {
  return {
    key,
    symbol: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: key,
    }).formatToParts(1).find(x => x.type === "currency").value,
  };
});

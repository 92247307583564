<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_SUBSCRIPTIONS">
    <iColumn>
      <iHeading>
        Subscription Billing History
      </iHeading>

      <iDataTable
        :items="subscriptionBillingHistory"
        :has-more="hasMoreSubscriptionBillingHistory"
        :is-loading="isLoadingSubscriptionBillingHistory"
        @load-more="loadSubscriptionHistory"
      />
      <iHeading>
        Subscriber List
      </iHeading>
      <iDataTable
        :has-more="hasMoreSubscribers"
        :is-loading="isLoadingSubscriptions"
        :items="subscribers"
        @load-more="loadSubscribers"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { mapActions, mapState } from "pinia";
import { useSubscriptionStore } from "@/stores/subscription-store";
import { SECTION_MONETIZATION, PAGE_SUBSCRIPTIONS } from "@/constants/nav-constants";

export default {
  name: "SubscriptionDetails",
  components: {
    PageWrapper,
  },
  props: {
    subscriptionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_SUBSCRIPTIONS,
    };
  },
  computed: {
    ...mapState(useSubscriptionStore, [
      "isLoadingSubscriptions",
      "isLoadingSubscriptionBillingHistory",
      "subscribers",
      "subscriptionBillingHistory",
      "hasMoreSubscriptionBillingHistory",
      "hasMoreSubscribers",
    ]),
  },
  created() {
    this.fetchSubscriptionBillingHistory(this.subscriptionId);
    this.fetchSubscribers(this.subscriptionId);
  },
  methods: {
    ...mapActions(useSubscriptionStore, [
      "fetchSubscriptionBillingHistory",
      "fetchSubscribers",
    ]),
    loadSubscriptionHistory() {
      this.fetchSubscriptionBillingHistory(this.subscriptionId);
    },
    loadSubscribers() {
      this.fetchSubscribers(this.subscriptionId);
    },
  },
};
</script>

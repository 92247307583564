<template>
  <iRow width="hug" vertical-align="middle">
    <iColumn width="hug">
      <iTextInput
        v-model="draftMessage"
        name="message"
        placeholder="Message"
        @enter="sendNewMessage"
      />
    </iColumn>
    <iColumn width="hug">
      <iButton
        :is-loading="newMessageIsLoading"
        loading-spinner-position="inside"
        @click="sendNewMessage"
      >
        <iIcon v-if="!newMessageIsLoading" icon="message" variant="secondary" />
      </iButton>
    </iColumn>
  </iRow>
</template>

<script>
import { useConversationStore } from "@/stores/conversation-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";

export default {
  name: "NewChatroomMessage",
  props: {
    userAvatarUrl: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["isMessageLoading"]),
    newMessageIsLoading() {
      return this.isMessageLoading.newMessage;
    },
  },
  methods: {
    ...mapActions(useConversationStore, [
      "sendMessage",
      "sendPublicationChatroomMessage",
    ]),
    sendNewMessage() {
      if (this.context === "publication_chatroom") {
        this.sendPublicationChatroomMessage(this.entityId);
      } else {
        this.sendMessage(this.conversationId, this.context, this.entityId);
      }
      this.draftMessage = "";

      // get element by id
      const element = document.getElementById("content");
      // scroll to bottom
      element.scrollTop = element.scrollHeight;
    },
  },
};
</script>

<style scoped lang="scss"></style>

export const links = Object.freeze({
  affiliateJs: "affiliateJs",
  affiliateApiV1: "affiliateApiV1",
  api: "api",
  coreAppApi: "coreAppApi",
  paymentsApiV1: "paymentsApiV1",
  usersApiV1: "usersApiV1",
  fanApiV1: "fanApiV1",
  contentApiV1: "contentApiV1",
  web: "web",
  admin: "adminApiV1",
});

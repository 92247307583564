<template>
  <PageWrapper :active-section="SECTION_COMMUNITY" :active-page="PAGE_COMMUNITY_COMMUNITY">
    <!-- add the search bar component -->
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import ComingSoon from "@/components/ComingSoon.vue";
import { SECTION_COMMUNITY, PAGE_COMMUNITY_COMMUNITY } from "@/constants/nav-constants";

export default {
  name: "Community",
  components: {
    PageWrapper,
    ComingSoon,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_COMMUNITY,
    };
  },
};
</script>

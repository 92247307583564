<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USERS"
    title="My Users"
    sub-title="My Users"
    :breadcrumbs="breadcrumbs"
    page-name="My Users"
    align="center"
    width="full"
  />
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import {
  PAGE_PUBLICATION_USERS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationUsers",
  components: { PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USERS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publication", "posts", "isLoading", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
};
</script>

<style scoped lang="scss">

</style>

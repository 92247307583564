<template>
  <PageWrapper
      :active-section="SECTION_STORIES"
      :active-page="PAGE_STORIES"
  >
    <iRow wrap="nowrap">
      <iColumn width="hug">
        <iHeading>Stories</iHeading>
      </iColumn>
      <iColumn>
        <iButton @click="createPost" :is-loading="createPostIsLoading">Create Story</iButton>
      </iColumn>
    </iRow>
    <SmartFeed
        :pinia-store="useOrgPostFeedStore"
        default-layout="table"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_STORIES, PAGE_STORIES } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";
import SmartFeed from "@/components/smart-feed/SmartFeed";
import {useOrgPostFeedStore} from "@/stores/smart-feed/feed/org-post-feed-store";
import {mapActions, mapState} from "pinia";
import {usePublicationStore} from "@/stores/publication-store";

export default {
  name: "Stories",
  components: {
    SmartFeed,
    ComingSoon,
    PageWrapper,
  },
  data() {
    return {
      SECTION_STORIES,
      PAGE_STORIES,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["createPostIsLoading"])
  },
  methods: {
    ...mapActions(usePublicationStore, ["createPost"]),
    useOrgPostFeedStore,
  },
};
</script>

<style scoped>
</style>

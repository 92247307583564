<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_INSPIRATION_NETWORK"
    title="Inspiration Network"
    :breadcrumbs="breadcrumbs"
    page-name="Inspiration Network"
    align="left"
    width="fixed"
  >
    <iDataTable
      :items="inspirationNetworkPartnerships"
      :fields="fields"
      primary-key="partnership_id"
      hide-actions
      height="fill"
      :has-more="hasMoreInspirationNetworkPartnerships"
      :is-loading="loadingInspirationNetworkPartnerships"
      :scroll-threshold="400"
      @load-more="loadMore"
    >
      <template #td(advertiser_name)="{data}">
        <iRow width="hug" height="fill">
          <iLink :href="`/community/org/${data.advertiser_org_id}/profile`">
            {{ data.advertiser_name }}
          </iLink>
        </iRow>
      </template>
      <template #td(domains)="{data}">
        <iColumn>
          <iRow v-for="domain in validDomains(data.domains)" :key="domain.advertiser_domain_id">
            <iLink :href="getNonRelativeDomain(domain.domain)" target="_blank">
              {{ domain.domain }}
            </iLink>
          </iRow>
        </iColumn>
      </template>
      <template #td(payout)="{data}">
        {{ getPayoutCellString(data) }}
      </template>
    </iDataTable>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePartnershipStore } from "@/stores/partnership-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_INSPIRATION_NETWORK,
  SECTION_MONETIZATION,
} from "@/constants/nav-constants";

export default {
  name: "InspirationNetwork",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_INSPIRATION_NETWORK,
      SECTION_MONETIZATION,
      fields: [
        {
          key: "advertiser_name",
          label: "Name",
        },
        {
          key: "payout",
          label: "Payout",
        },
        {
          key: "domains",
          label: "Domains",
        },
      ],
    };
  },
  computed: {
    ...mapState(usePartnershipStore, [
      "inspirationNetworkPartnerships",
      "loadingInspirationNetworkPartnerships",
      "hasMoreInspirationNetworkPartnerships",
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  created() {
    this.fetchInspirationNetworkPartnerships();
  },
  methods: {
    ...mapActions(usePartnershipStore, [
      "fetchInspirationNetworkPartnerships",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    loadMore() {
      this.fetchInspirationNetworkPartnerships();
    },
    validDomains(domains) {
      return domains.filter(domain => this.assertValidDomainName(domain.domain));
    },
    assertValidDomainName(domain) {
      // regex for simple valid domain name
      let pattern = /^(([\w-]+)\.)+(\w+)$/;
      let match = pattern.test(domain);
      return match;
    },
    getPayoutCellString(data) {
      if (data.payout_type === "percentage") {
        if (data.payout_value_min === data.payout_value_max) {
          return `${data.payout_value_max}%`;
        } else {
          return `${data.payout_value_min}-${data.payout_value_max}%`;
        }
      }

      if (data.payout_type === "amount") {
        if (data.payout_value_min === data.payout_value_max) {
          return `$${data.payout_value_max}`;
        } else {
          return `$${data.payout_value_min}-${data.payout_value_max}`;
        }
      }
    },
  },
};
</script>

<template>
  <div class="color-box" :style="`background-color: ${color}`" />
</template>

<script>
export default {
  name: "ColorBlock",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container[data-v-12dacaad] {
  margin: 20px auto 0 auto;
  padding: 0 10px;
  max-width: 1280px;
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(193, 192, 192, 0.5);
}
.tracking-link-input[data-v-12dacaad] {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  height: 35px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/monetization/JavascriptTag.vue","webpack://./JavascriptTag.vue"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;EACA,gDAAA;ACDF;ADIA;EACE,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.container {\n  margin: 20px auto 0 auto;\n  padding: 0 10px;\n  max-width: 1280px;\n  background: #fff;\n  border: 1px solid lightgrey;\n  border-radius: 8px;\n  box-shadow: 0 2px 3px 0 rgba(193, 192, 192, 0.5);\n}\n\n.tracking-link-input {\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 20px;\n  height: 35px;\n}\n\n",".container {\n  margin: 20px auto 0 auto;\n  padding: 0 10px;\n  max-width: 1280px;\n  background: #fff;\n  border: 1px solid lightgrey;\n  border-radius: 8px;\n  box-shadow: 0 2px 3px 0 rgba(193, 192, 192, 0.5);\n}\n\n.tracking-link-input {\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 20px;\n  height: 35px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <ConversationHeader
    :author-name="userData.first_name"
    :author-avatar="userAvatar"
    :show-timestamp-created="false"
    :author-id="userData.user_id"
  >
    <template #content>
      <iTextInput
        v-model="replyMessage"
        width="fill"
        name="comment"
        @enter="addComment"
      />
      <iSpace :height="6" />
      <iButton :is-loading="isLoading" @click="addComment">
        Send Reply
      </iButton>
    </template>
  </ConversationHeader>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useConversationStore } from "@/stores/conversation-store";
import ConversationHeader from "@/components/conversations/ConversationHeader";

export default {
  name: "NewReply",
  components: {
    ConversationHeader,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      replyMessage: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userAvatar", "userData"]),
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["isLoading"]),
  },
  methods: {
    ...mapActions(useConversationStore, ["replyToMessage"]),
    addComment() {
      this.replyToMessage(this.entityId, this.replyMessage);
      this.replyMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="publication.name"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Overview"
    align="left"
    width="full"
  >
    <iRow vertical-align="middle" class="publication-header-wrapper">
      <iImageContainer
        :src="splashImage"
        :height="300"
        object-fit="cover"
        width="fill"
        class="splash-image"
      />
      <iRow vertical-align="middle" class="publication-header">
        <iImageContainer
          class="publication-header-logo"
          shape="circle"
          :width="120"
          :height="120"
          :src="publicationLogo"
        />
        <iColumn gap="none" width="hug">
          <iSpace :height="10" />
          <iSubHeading>{{ publication.name }}</iSubHeading>
          <iText>{{ totalUsers }} {{ totalUsers === 1 ? 'user' : 'users' }}</iText>
          <iText>{{ totalSubscribers }} {{ totalSubscribers === 1 ? 'subscriber' : 'subscribers' }}</iText>
        </iColumn>
      </iRow>
    </iRow>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { DEFAULT_PUBLICATION_IMAGE, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_DASHBOARD,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "ViewPublicationDetails",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_DASHBOARD,
      SECTION_PUBLICATIONS,
      DEFAULT_PUBLICATION_IMAGE,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publication", "publicationId", "orgId", "publicationSplashImage"]),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    totalUsers() {
      return this.publication.metrics.filter(metric => metric.id === "users")?.[0]?.value || 0;
    },
    totalSubscribers() {
      return this.publication.metrics.filter(metric => metric.id === "subscribers")?.[0]?.value || 0;
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_PUBLICATION_IMAGE;
      }
      return this.publication.publication_logo;
    },
    splashImage() {
      if (!this.publicationSplashImage) {
        return this.DEFAULT_SPLASH_IMAGE;
      }
      return this.publicationSplashImage;
    },
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
  }),
};
</script>

<style scoped lang="scss">
.publication-header-wrapper{
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}
</style>

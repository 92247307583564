// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-b9662cec] .infinite-scroll {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/conversations/ChatroomThread.vue","webpack://./ChatroomThread.vue"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  :deep(.infinite-scroll) {\n    display: flex;\n    overflow: scroll;\n    flex-direction: column;\n    justify-content: flex-end;\n  }\n",":deep(.infinite-scroll) {\n  display: flex;\n  overflow: scroll;\n  flex-direction: column;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <iLoadingSpinner v-if="loadingMessages" />
  <iColumn v-else :width="500">
    <iRow v-if="hasMessages" vertical-align="middle">
      <iText>Sort By: </iText>
      <iSelect
        id="sortingstrategy"
        v-model="sortStrategy"
        :items="sortingOptions"
        value-field="value"
        text-field="name"
        @update:model-value="updateSortStrategy"
      />
    </iRow>
    <iRow
      v-for="(message, index) in messages"
      :key="index"
    >
      <ActiveCommentWidget
        :replies="message.replies ?? []"
        :message-id="message.messageId"
        :conversation-id="message.conversationId"
        :author-id="message.userId"
        :author-avatar="message.avatarUrl"
        :message="message.message"
        :vote-score="message.voteScore"
        :date-sent="message.timestampCreated"
        :author-name="message.authorName"
        :moderation-status="message.moderationStatus"
        :num-approved-replies="message.numApprovedReplies"
        :num-likes="message.numLikes"
        :num-reports="message.numReports"
        :moderation-score="message.moderationScore"
        :status="message.status"
        :date-updated="message.timestampUpdated"
        :user-liked="message.userLiked"
        :user-up-voted="message.userUpVoted"
        :user-down-voted="message.userDownVoted"
      />
    </iRow>
  </iColumn>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { useConversationStore } from "@/stores/conversation-store";
import ActiveCommentWidget from "@/components/conversations/ActiveCommentWidget";

export default {
  name: "CommentThread",
  components: {
    ActiveCommentWidget,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "comment",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      newComment: "",
      sortingOptions: [
        { name: "by_date_sent", value: "by_date_sent" },
        { name: "by_score", value: "by_score" },
      ],
      currentSortingOption: "by_date_sent",
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userAvatar"]),
    ...mapState(useConversationStore, [
      "messages",
      "sortStrategy",
      "loadingMessages",
    ]),
    hasMessages () {
      return this.messages.length > 0;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchMore: "fetchMore",
      deleteComment: "deleteComment",
      submitComment: "submitComment",
      submitSorting: "submitSorting",
      submitEditComment: "submitEditComment",
    }),
    updateSortStrategy(sortingValue) {
      this.currentSortingOption = sortingValue;
      this.submitSorting(this.conversationId, this.context, this.entityId, sortingValue.value);
    },
    editComment(commentData) {
      this.submitEditComment(commentData);
    },
    loadMore() {
      this.fetchMore();
    },
  },
};
</script>

<style scoped lang="scss"></style>

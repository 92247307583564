<template>
  <iRow>
    <iSubHeading>Community</iSubHeading>
  </iRow>
  <iRow>
    <iMenu orientation="vertical" :active-item="activeItem" :items="parsedCommunityNav" />
  </iRow>
</template>

<script>
import { SIDE_NAV_COMMUNITY } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { filterMenuItems } from "@/helpers/capabilities-helper";
export default {
  name: "CommunitySideNav",
  props: {
    activeItem: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    parsedCommunityNav() {
      const filteredMenuItems = filterMenuItems(SIDE_NAV_COMMUNITY);
      return parseUrlVars(filteredMenuItems);
    },
  },
};
</script>

<style scoped lang="scss"></style>

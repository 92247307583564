<template>
  <iRow
    class="main-nav"
    align="between"
    vertical-align="middle"
    :height="80"
  >
    <iRow
      width="hug"
      height="fill"
      class="nav-item-padding"
      vertical-align="middle"
      gap="none"
    >
      <Logo />
      <iMenu
        v-if="orgData.waitlist_status === waitlistStatus.approved && !$vssMobile"
        orientation="horizontal"
        :items="parsedPrimaryNavData"
        :active-item="activeItem"
      />
    </iRow>
    <iRow
      class="nav-item-padding"
      width="hug"
      height="fill"
      vertical-align="middle"
    >
      <iLink v-if="showDigitalWalletBalance" :href="`/digital-wallet/org/${orgId}`">
        {{ digitalWalletBalanceString }}
      </iLink>
      <iDropdownMenu
        v-if="!$vssMobile"
        :items="parsedSecondaryNavData"
        :show-dropdown="showAvatarDropdown"
        position="right"
        @close="showAvatarDropdown = false"
      >
        <template #trigger>
          <iRow vertical-align="middle" @click="showAvatarDropdown = !showAvatarDropdown">
            <AvatarImage :src="userData.avatar_url" size="35px" />
            <iText v-if="$vssDesktop" class="user-name">
              {{ userData.first_name }} {{ userData.last_name }}
            </iText>
            <iIcon icon="caret-down" />
          </iRow>
        </template>
      </iDropdownMenu>
      <iDropdownMenu
        v-else
        :items="mobileMenu"
        :show-dropdown="showMobileDropdown"
        position="right"
        @close="showMobileDropdown = false"
      >
        <template #trigger>
          <iRow vertical-align="middle" @click="showMobileDropdown = !showMobileDropdown">
            <iIcon :icon="showMobileDropdown ? 'close' : 'hamburger'" />
          </iRow>
        </template>
      </iDropdownMenu>
    </iRow>
  </iRow>
</template>

<script>
import { MAIN_NAV_PRIMARY, MAIN_NAV_SECONDARY } from "@/constants/nav-constants";
import { useGeneralStore } from "@/stores/general-store";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { mapState } from "pinia";
import { usePaymentStore } from "@/stores/payments-store";
import Logo from "@/components/Logo";
import AvatarImage from "@/components/AvatarImage";
import { waitlistStatus } from "@/enums/waitlist-enum";
import { useWindowStore } from "@bloglovin/vue-component-library";
import { filterMenuItems } from "@/helpers/capabilities-helper";
export default {
  name: "MainNav",
  components: { AvatarImage, Logo },
  props: {
    activeItem: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showAvatarDropdown: false,
      showMobileDropdown: false,
    };
  },
  computed: {
    waitlistStatus() {
      return waitlistStatus;
    },
    ...mapState(useWindowStore, [
      "$vssMobile",
      "$vssDesktop",
    ]),
    ...mapState(useGeneralStore, [
      "orgData",
      "orgCapabilities",
      "orgId",
      "userData",
      "userId",
    ]),
    ...mapState(usePaymentStore, [
      "digitalWalletBalanceString",
      "showDigitalWalletBalance",
    ]),
    parsedPrimaryNavData() {
      const filteredNavItems = filterMenuItems(MAIN_NAV_PRIMARY);
      return parseUrlVars(filteredNavItems, { "{org_id}": this.orgId, "{user_id}": this.userId });
    },
    parsedSecondaryNavData() {
      const filteredNavItems = filterMenuItems(MAIN_NAV_SECONDARY);
      return parseUrlVars(filteredNavItems, { "{org_id}": this.orgId, "{user_id}": this.userId });
    },
    mobileMenu() {
      const menu = [];
      if (this.orgData.waitlist_status === this.waitlistStatus.approved) {
        menu.push(...this.parsedPrimaryNavData);
      }
      menu.push(...this.parsedSecondaryNavData);

      return menu;
    },
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  styleGuide: () => ({
    contentPaddingLeft: { "size.spacing": "standard" },
  }),
};
</script>

<style scoped lang="scss">
.main-nav {
  width: unset!important;
  flex-grow: 1;
}
.nav-item-padding {
  padding-inline: v-bind("$getStyles.contentPaddingLeft");
}
.user-name {
  @include phone {
    display: none;
  }
}
</style>

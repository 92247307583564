import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";
import { links } from "@/enums/link-enums";

export const usePublisherStore = defineStore("publisher-store", {
  state: () => ({
    publisher: {},
    publishers: [],
    affiliateLinks: [],
    websites: [],
    lastPublisherWebsiteId: 0,
    loadingWebsites: false,
    hasMoreWebsites: false,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
    affiliateJsUrl() {
      return useGeneralStore().getLink(links.affiliateJs);
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
  },
  actions: {
    addPublisherWebsite(domain, ignoreQueryParams = 0) {
      $axios.post(`${this.affiliateApiV1Url}/publisher-website`, JSON.stringify({
        website_domain: domain,
        ignore_query_params: ignoreQueryParams,
        publisher_org_id: this.orgId,
      }))
          .then(response => {
            this.websites.push(response.data.publisher_website);
          })
          .catch(error => {
            return { error, message: "could not create add publisher website" };
          });
    },
    updatePublisherWebsite(websiteId, domain, ignoreQueryParams, claimStatus, status) {
      $axios.post(`${this.affiliateApiV1Url}/publisher-website/${websiteId}/update`, JSON.stringify({
        website_domain: domain,
        ignore_query_params: ignoreQueryParams,
        claim_status: claimStatus,
        status,
      }))
          .then(() => {
            const index = this.websites.findIndex(website => website.publisher_website_id === websiteId);
            this.websites[index] = {
              ...this.websites[index],
              website_domain: domain,
              ignore_query_params: `${ignoreQueryParams}`,
              claim_status: claimStatus,
              status: status,
            };
          })
          .catch(error => {
            return { error, message: "could not update publisher website" };
          });
    },
    getPublisherWebsites() {
      if (this.loadingWebsites || this.hasMoreWebsites === "false") {
        return;
      }

      this.loadingWebsites = true;
      $axios.get(`${this.affiliateApiV1Url}/publisher-website/publisher-org/${this.orgId}`, {
        last_publisher_website_id: this.lastPublisherWebsiteId,
      })
          .then(response => {
            this.websites = response.data.websites;
            this.lastPublisherWebsiteId = response.data.lastPublisherWebsiteId;
            this.hasMoreWebsites = response.data.hasMore;
          })
          .finally(() => {
            this.loadingWebsites = false;
          });
    },
  },
});

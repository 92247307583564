<template>
  <component :is="smartFeedLayoutComponent" :entity="smartFeedEntity" />
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";

export default {
  name: "SmartFeedItem",
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: true,
    },
    layout: {
      type: Enum,
      required: true,
      options: [...CreatorSmartFeedEngine.VALID_LAYOUT_TYPES],
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    smartFeedLayoutComponent() {
      const creatorSmartFeedEngine = new CreatorSmartFeedEngine();
      return creatorSmartFeedEngine.getLayoutComponentForEntity(this.entityType, this.layout);
    },
    smartFeedEntity() {
      const creatorSmartFeedEngine = new CreatorSmartFeedEngine();
      return creatorSmartFeedEngine.getEntity(this.entityType, this.entityId);
    },
  },
};

</script>

<style scoped lang="scss">

</style>

<template>
  <PageWrapper title="Publication Post Settings" :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_FEATURE_POST_CREATION">
    <iToggleSwitch v-model="postCreationEnabled" label="Enable Post Creation" />
    <iSelect
      v-model="postCreationType"
      return-value
      :items="postTypes"
      label="Post Creation Type"
      value-field="value"
    />
    <iButton :is-loading="isLoadingConfig" @click="savePublicationConfig">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_FEATURE_POST_CREATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationPostCreation",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_FEATURE_POST_CREATION,
      config: {
        enabled: false,
        post_creation_type: "quick_post",
      },
      postTypes: [
        { name: "Quick Post", value: "quick_post" },
      ],
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "configuration",
      "isLoadingConfig",
    ]),
    postCreationType: {
      get() {
        return this.config.post_creation.post_creation_type;
      },
      set(value) {
        this.config = {
          ...this.config,
          post_creation: {
            ...this.config.post_creation,
            post_creation_type: value,
          },
        };
      },
    },
    postCreationEnabled: {
      get() {
        return this.config.post_creation.enabled;
      },
      set(value) {
        this.config = {
          ...this.config,
          post_creation: {
            ...this.config.post_creation,
            enabled: value,
          },
        };
      },
    },
  },
  created() {
    this.config = {
      ...this.configuration,
      post_creation: {
        ...this.config,
        ...this.configuration.post_creation || {},
      },
    };
  },
  methods: {
    ...mapActions(usePublicationStore, { saveConfiguration: "saveConfiguration" }),
    async savePublicationConfig() {
      this.saveConfiguration("post_creation", this.config.post_creation);
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <iColumn>
    <iSubHeading font-size="large">
      Theme Information
    </iSubHeading>
    <iText v-if="editMode" variant="subtle" font-size="verySmall">
      Please note: These fields are not part of version control and
      will be updated when staging or publishing.
    </iText>
    <iRow vertical-align="middle">
      <iTextInput
        v-model="themeName"
        :style-overrides="inputErrorStyle(`theme-name`)"
        class="size-input"
        :width="300"
        placeholder="Enter a name for the theme"
        label="Theme Name"
      />
    </iRow>
  </iColumn>
  <iSpace :height="10" />
  <iColumn v-for="(key, index) in themeKeys" :key="index">
    <iSubHeading font-size="extraExtraLarge">
      {{ camelToTitleCase(key) }}s
    </iSubHeading>
    <iColumn v-for="(subKey, subIndex) in Object.keys(theme[key])" :key="subIndex" class="w-full">
      <iColumn>
        <iSubHeading font-size="large">
          {{ camelToTitleCase(subKey) }}
        </iSubHeading>
        <iRow>
          <iColumn
            v-for="(sectionKey, sectionIndex) in Object.keys(theme[key][subKey])"
            :key="sectionIndex"
            class="input-container"
          >
            <iSelect
              v-model="theme[key][subKey][sectionKey]"
              class="size-input"
              return-value
              value-field="value"
              :label="camelToTitleCase(sectionKey)"
              :items="getPaletteOptions(key, subKey)"
              :width="300"
            />
          </iColumn>
        </iRow>
      </iColumn>
    </iColumn>
  </iColumn>
</template>

<script>
import { camelToTitleCase } from "@bloglovin/vue-framework";
import { THEME_PALETTE_OPTIONS, VISIBILITY_OPTIONS } from "@/constants/theme-config-constants";
import { mapState } from "pinia";
import { useThemesStore } from "@/stores/themes-store";

export default {
  name: "ThemeConfiguration",
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "has-errors"],
  data() {
    return {
      VISIBILITY_OPTIONS,
      theme: this.modelValue,
      errors: {},
    };
  },
  computed: {
    ...mapState(useThemesStore, [
      "themePalettes",
    ]),
    themeKeys() {
      const excludedKeys = ["name", "visibility", "theme_palette_id", "themePaletteId"];
      return Object.keys(this.theme).filter(key => !excludedKeys.includes(key));
    },
    paletteOptions() {
      const colors = [];
      const fonts = [];
      const sizes = [];

      Object.keys(this.palette.colors).forEach(colorKey => {
        Object.keys(this.palette.colors[colorKey]).forEach(shadeKey => {
          colors.push({
            name: `${camelToTitleCase(colorKey)} ${shadeKey}`,
            value: `Primitives.colors.${colorKey}[${shadeKey}]`,
          });
        });
      });

      Object.keys(this.palette.fonts).forEach(fontTypeKey => {
        Object.keys(this.palette.fonts[fontTypeKey]).forEach(option => {
          fonts.push({
            name: `${camelToTitleCase(fontTypeKey)}: ${camelToTitleCase(option)}`,
            value: `Primitives.fonts.${fontTypeKey}['${option}']`,
          });
        });
      });

      Object.keys(this.palette.sizes).forEach(sizeTypeKey => {
        Object.keys(this.palette.sizes[sizeTypeKey]).forEach(option => {
          let type = sizeTypeKey;
          if (sizeTypeKey === "variants") {
            type = "variant";
          }
          sizes.push({
            name: `${camelToTitleCase(type)}: ${camelToTitleCase(option)}`,
            value: `Primitives.sizes.${sizeTypeKey}['${option}']`,
          });
        });
      });

      return {
        color: colors,
        font: fonts,
        size: sizes,
      };
    },
    themeName: {
      get() {
        return this.theme.name;
      },
      set(value) {
        if (!value) {
          this.addError("theme-name", "Please enter a name for the theme");
        } else {
          this.removeError("theme-name");
        }
        this.theme.name = value;
      },
    },
    themeVisibility: {
      get() {
        return this.theme?.visibility;
      },
      set(value) {
        this.theme.visibility = value;
      },
    },
    mappedThemePalettes() {
      const mappedPalettes = Object.values(this.themePalettes).map(palette => {
        return {
          name: palette.name,
          value: palette.theme_palette_id.toString(),
        };
      });

      return [
        {
          name: "Default Palette",
          value: "0",
        },
        ...mappedPalettes,
      ];
    },
  },
  watch: {
    palette: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      deep: true,
    },
    errors: {
      handler(newValue) {
        this.$emit("has-errors", Object.keys(newValue).length > 0);
      },
      deep: true,
    },
  },
  methods: {
    camelToTitleCase,
    getPaletteOptions(key, subKey) {
      if (key === THEME_PALETTE_OPTIONS.COLOR) {
        return this.paletteOptions.color;
      } else if (key === THEME_PALETTE_OPTIONS.FONT) {
        const subKeyToLookup = camelToTitleCase(subKey);
        return this.paletteOptions.font.filter(font => font.name.includes(subKeyToLookup + ":"));
      } else if (key === THEME_PALETTE_OPTIONS.SIZE) {
        const subKeyToLookup = camelToTitleCase(subKey);
        if (subKeyToLookup === "Line Height") {
          return this.paletteOptions.size.filter(size => size.name.includes(subKeyToLookup));
        }
        return this.paletteOptions.size.filter(size => !size.name.includes("Line Height:"));
      }
    },
    inputErrorStyle(key) {
      if (this.errors[key]) {
        return { "inputBorderColor": "striking", "inputBorderFocusColor": "striking" };
      }
      return {};
    },
    addError(key, message) {
      this.errors = {
        ...this.errors,
        [key]: message,
      };
    },
    removeError(key) {
      this.errors = Object.keys(this.errors).reduce((acc, errorKey) => {
        if (errorKey !== key) {
          acc[errorKey] = this.errors[errorKey];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  flex-grow: 0;
  flex-basis: fit-content;
}

@include phone {
  .size-input {
    width: 100%;
    max-width: 100%;
    & :deep(.i-text-input) {
      width: 100%;
      max-width: 100%;
    }
  }
  .input-container {
    flex-grow: 1;
    flex-basis: auto;
    width: 100%;
  }
}
.input-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

:deep(.i-select-container > .label) {
  padding-bottom: 10px;
}
</style>
